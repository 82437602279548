import React, { useState } from 'react';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA } from '../../config';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { emailRegex } from '../../utils';
import TextField from 'src/components/TextField';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../config';

interface IData {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
});

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const [cognitoError, setCognitoError] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Axios.post(`${API_ENDPOINT_LAMBDA}/reset-password-email`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      history.push('/user/forgot-password/verification');
    } catch (error: any) {
      console.log('error', error);
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className="max-w-md mx-auto">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Forgot Password</title>
      </Helmet>
      <TyphoonBackdrop open={isSubmitting} />
      <h1 className="text-2xl font-semibold mb-6">
        <span className="text-typGreen">Typhoon</span> Forgot Password
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
        <div className="p-6 sm:p-10">
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="email"
                id="email"
                fullWidth
                variant="outlined"
                placeholder="Enter your email here !!"
                error={errors.email ? true : false}
              />
              {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
            </div>
            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}
            <button
              type="submit"
              className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
