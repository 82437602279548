import React, { useState } from 'react';
import { ITransactionLogData } from '../../../../types';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA, AppTitle } from '../../../../config';
import { useQuery } from 'react-query';
import { TyphoonBackdrop } from '../../../../components/Backdrop';
// import { useSelector } from 'react-redux';
// import { IReducer } from '../../../../stores/IndexReducer';
import { Helmet } from 'react-helmet';
import MovieAnalytics from '../../../analytics';

// const useStyles = makeStyles(() =>
//   createStyles({
//     header: {
//       backgroundColor: '#19499e0d',
//     },
//     table: {
//       minWidth: 320,
//     },
//   }),
// );

const fetchTransactions = async (type: ITransactionLogData['type']) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/transaction-log?paymentStatus=confirmed&type=${type}`);
  return res.data;
};

export const FilmmakerStatisticsList = () => {
  // const classes = useStyles();
  // const history = useHistory();

  // ? available values: 'event' | 'movie' | 'season'
  const [showType] = useState<'event' | 'movie' | 'season'>('event');
  // const userRole = useSelector(getUserRole);

  const transactions = useQuery<ITransactionLogData[], Error>(
    ['transactions', showType],
    () => fetchTransactions(showType),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  return (
    <div className="mb-12">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Statistics</title>
      </Helmet>
      <TyphoonBackdrop open={transactions.isFetching} />
      <div className="flex flex-wrap justify-between items-center mb-6">
        <h1 className="text-2xl font-medium text-typGreen">Statistics</h1>
      </div>
      <div className="py-8">
        <MovieAnalytics />
      </div>
      {/* <div className="mb-12">
        <h1 className="text-xl font-medium text-white capitalize mb-3">{showType}</h1>
        {!transactions.isFetching && !transactions.data && (
          <p className="border-b border-typGrey30 text-red-600 p-2">No transactions found.</p>
        )}
        {transactions.data && (
          <div className="bg-white rounded-md overflow-hidden">
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="search song table"
              >
                <TableHead className={classes.header}>
                  <TableRow>
                    <TableCell key="ref_id" align="left">
                      <p className="font-semibold capitalize tracking-wider">ID</p>
                    </TableCell>
                    <TableCell key="title" align="left">
                      <p className="font-semibold capitalize tracking-wider">Title</p>
                    </TableCell>
                    <TableCell key="email" align="left">
                      <p className="font-semibold capitalize tracking-wider">User Email</p>
                    </TableCell>
                    <TableCell key="price" align="left">
                      <p className="font-semibold capitalize tracking-wider">Price</p>
                    </TableCell>
                    <TableCell key="createdAt" align="left">
                      <p className="font-semibold capitalize tracking-wider">Created At</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.data ? (
                    transactions.data.map((log) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={log.id}>
                          <TableCell align="left">{log.ref_id}</TableCell>
                          <TableCell align="left">{log.title}</TableCell>
                          <TableCell align="left">{log.email}</TableCell>
                          <TableCell align="left">{log.price}</TableCell>
                          <TableCell align="left">
                            {moment(new Date(log.createdAt)).format('hh:mm a, Do MMM, YYYY')}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="left" colSpan={11}>
                        No transactions
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div> */}
    </div>
  );
};
