import React, { useState } from 'react';
import { TyphoonBackdrop } from '../Backdrop';
import { StepIndicator } from '../StepIndicator';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getAccessToken, LanguagesWithLocal, splitTitle } from '../../utils';
import Axios from 'axios';
import { config, API_ENDPOINT_LAMBDA } from '../../config';
import { TyphoonToaster } from '../Toastify';
import { Storage } from 'aws-amplify';
import { IconButton, MenuItem } from '@material-ui/core';
import { useQuery } from 'react-query';
import { IStandAloneMovie, IStandAloneMovieStep2, IUploadForms } from '../../types';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as Yup from 'yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SingleFileUploadDropzone from './SingleFileUploadDropzone';
import ReactHookFormSelect from '../ReactHookFormSelect';

export function checkImageType(files: any) {
  let valid = false;
  const _files = Array.from(files) as File[];
  if (_files && _files.length > 0) {
    valid = _files.every((file) => {
      const pictureType = file.name.toLowerCase().split('.').pop();
      if (pictureType && ['jpeg', 'jpg', 'png'].includes(pictureType)) {
        return true;
      } else {
        return false;
      }
    });
  }
  return valid;
}
export function checkSrtType(files: any) {
  let valid = false;
  const _files = Array.from(files) as File[];
  if (_files && _files.length > 0) {
    valid = _files.every((file) => {
      const pictureType = file.name.toLowerCase().split('.').pop();
      if (pictureType && ['srt'].includes(pictureType)) {
        return true;
      } else {
        return false;
      }
    });
  }
  return valid;
}
export const checkRequired = (files: any) => {
  return !(Object.keys(files).length === 0);
};

const validationSchema = Yup.object().shape({
  banner: Yup.mixed()
    // .test('required', 'Required', checkRequired)
    .test('type', 'We only support JPEG, JPG or PNG', checkImageType),
  thumbnail: Yup.mixed()
    // .test('required', 'Required', checkRequired)
    .test('type', 'We only support JPEG, JPG or PNG', checkImageType),
  subtitle: Yup.array().of(
    Yup.object({
      label: Yup.string().oneOf(
        LanguagesWithLocal.map((languageObject) => languageObject.language),
        'Please select language type',
      ),
      file: Yup.mixed()
        // .test('required', 'Required', checkRequired)
        .test('type', 'We only support SRT', checkSrtType),
    }),
  ),
});
interface IData {
  banner: File[];
  thumbnail: File[];
  subtitle: {
    label: string;
    file: File[];
  }[];
}

const fetchMovie = async (movieId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies/${movieId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export const UploadMovieStep2: React.FC<IUploadForms> = ({ stepsLength, currentStep, title, subpath }) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = new URLSearchParams(location.search);
  const movieId = queryObject.get('movieId');

  useQuery<IStandAloneMovie, Error>(['check-stand-alone', movieId], () => fetchMovie(movieId!), {
    refetchOnWindowFocus: false,
    enabled: !!movieId,
    retry: 1,
    onError: () => {
      TyphoonToaster('Invalid request!!', 'danger');
      history.push('/filmmaker/portal');
    },
  });

  const methods = useForm<IData>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      banner: [],
      thumbnail: [],
      subtitle: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'subtitle',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();

  const onSubmit = methods.handleSubmit(async (data) => {
    setIsSubmitting(true);
    if (data.banner.length > 0 && data.thumbnail.length > 0) {
      try {
        let values: IStandAloneMovieStep2 = {
          banner: '',
          thumbnail: '',
          subtitle: [],
        };

        // ? Upload banner image
        const bannerImageType = data.banner[0].name.split('.').pop();
        const banner = (await Storage.put(`${movieId}/banner-${Date.now()}.${bannerImageType}`, data.banner[0], {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
        })) as { key: string };
        values.banner = banner.key;

        // ? Upload thumbnail image
        const thumbnailImageType = data.thumbnail[0].name.split('.').pop();
        const thumbnail = (await Storage.put(
          `${movieId}/thumbnail-${Date.now()}.${thumbnailImageType}`,
          data.thumbnail[0],
          {
            bucket: config.S3.file.bucket,
            customPrefix: {
              public: '',
            },
          },
        )) as { key: string };
        values.thumbnail = thumbnail.key;

        // ? Upload subtitles
        if (data.subtitle.length > 0) {
          for await (const subtitleObject of data.subtitle) {
            let newSubtitleObject: {
              file: string;
              label: string;
            } = {
              label: subtitleObject.label,
              file: '',
            };

            const fileType = subtitleObject.file[0].name.split('.').pop();
            const subtitleFile = (await Storage.put(
              `${movieId}/subtitle-${Date.now()}.${fileType}`,
              subtitleObject.file[0],
              {
                bucket: config.S3.file.bucket,
                customPrefix: {
                  public: '',
                },
              },
            )) as { key: string };
            newSubtitleObject.file = subtitleFile.key;

            values.subtitle?.push(newSubtitleObject);
          }
        }

        // ? update step-2 values
        await Axios.put(`${API_ENDPOINT_LAMBDA}/movies/${movieId}`, values, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        });

        const path =
          subpath === 'stand-alone'
            ? `/filmmaker/upload/${subpath}/step3?movieId=${movieId}`
            : `/filmmaker/upload/${subpath}/step4?movieId=${movieId}`;

        history.push(path);

        setIsSubmitting(false);
      } catch (error) {
        console.log('error', error);
        setSubmitError('Something went wrong');
      }
    }
    setIsSubmitting(false);
  });

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-semibold text-center mb-6">
        <span className="text-typGreen">{splitTitle(title)[0]}</span> {splitTitle(title)[1]}
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
        <StepIndicator className="mb-8" stepsLength={stepsLength} currentStep={currentStep} />
        <TyphoonBackdrop open={isSubmitting} />
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="space-y-6">
              <div className="space-y-1">
                <SingleFileUploadDropzone name="banner" label="banner image" accept="image/jpeg, image/png" />
                <p className="text-gray-400 text-xs italic font-medium">
                  All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
                  credits. Please check the{' '}
                  <Link
                    to={'/filmmaker/news-and-weather'}
                    target="_blank"
                    className="inline-block underline font-semibold text-typGreen"
                  >
                    Typhoon User Guide
                  </Link>{' '}
                  .
                </p>
              </div>
              <div className="space-y-1">
                <SingleFileUploadDropzone name="thumbnail" label="thumbnail image" accept="image/jpeg, image/png" />
                <p className="text-gray-400 text-xs italic font-medium">
                  All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
                  credits. Please check the{' '}
                  <Link
                    to={'/filmmaker/news-and-weather'}
                    target="_blank"
                    className="inline-block underline font-semibold text-typGreen"
                  >
                    Typhoon User Guide
                  </Link>{' '}
                  .
                </p>
              </div>
              <div>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-typGreen text-sm font-bold capitalize">Subtitles</p>{' '}
                  <IconButton
                    color="secondary"
                    aria-label="add another subtitle"
                    onClick={() => append({ label: '', file: [] })}
                  >
                    <AddCircleOutlineTwoToneIcon />
                  </IconButton>
                </div>
                <div className="space-y-6">
                  {fields.map((item, index) => {
                    let fileError: any = methods.formState.errors['subtitle']?.[index]?.file;
                    return (
                      <div key={index}>
                        <div className="flex justify-between items-center">
                          <p className="text-typGreen text-sm font-semibold capitalize"># {index + 1}</p>
                          <IconButton
                            style={{ color: '#ef4444' }}
                            aria-label="remove subtitle"
                            onClick={() => remove(index)}
                          >
                            <HighlightOffIcon fontSize="small" style={{ color: '#ef4444' }} />
                          </IconButton>
                        </div>
                        <div key={item.id} className="space-y-1">
                          <div className="space-y-1">
                            <p className="text-typGreen text-xs font-semibold capitalize">language type</p>
                            <ReactHookFormSelect
                              name={`subtitle.${index}.label`}
                              control={methods.control}
                              error={!!methods.formState.errors['subtitle']?.[index]?.label}
                              variant="outlined"
                            >
                              <MenuItem value="" disabled>
                                Select Language Type
                              </MenuItem>
                              {LanguagesWithLocal.map((languageObject, i) => (
                                <MenuItem key={i} value={languageObject.language}>
                                  {languageObject.language}
                                </MenuItem>
                              ))}
                            </ReactHookFormSelect>
                            {methods.formState.errors['subtitle']?.[index]?.label && (
                              <p className="text-red-500 text-xs italic font-medium">
                                {methods.formState.errors.subtitle[index].label?.message}
                              </p>
                            )}
                          </div>
                          <div className="space-y-1">
                            <p className="text-typGreen text-xs font-semibold capitalize">SRT File</p>
                            <SingleFileUploadDropzone
                              name={`subtitle.${index}.file`}
                              accept=".srt"
                              preview={false}
                              errorMessage={fileError?.message}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
                isSubmitting ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
              }`}
            >
              Next
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
