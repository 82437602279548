import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import Browse from './pages/browse';
import NoMatch from './pages/NoMatch';
import UserPage from './pages/user';
import SearchPage from './pages/search';
import FilmmakerPage from './pages/filmMaker';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import { isAuthenticate, removeAccessToken, setAccessToken } from './utils';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { loginAction, logoutAction } from './stores/user/Actions';
import LiveStreamPage from './pages/theater';
import { Elements } from '@stripe/react-stripe-js';
import FilmmakerSubscription from './pages/filmmakerSubscription';
import { stripePromise } from './config';
import PrivacyPolicy from './pages/privacyPolicy';
import SupportPage from './pages/support';
import TermsAndConditions from './pages/termsAndConditions';
import ContactUs from './pages/contact';
import MoviePage from './pages/movies';
import SeasonPage from './pages/seasons';
import ScrollToTop from './components/ScrollToTop';
import LandingPage from './pages/landing';
import SingleBlog from './pages/blog/rss/SingleBlog';
import Blog from './pages/blog/rss';
import CategoryWiseContents from './pages/browse/CategoryWiseContents';
import AboutPage from './pages/about';
import { identify as addUserIdentityInHeap } from 'src/heap/heap-analytics';
// import IdleTimeOutContainer from './components/CTimeOutContainer';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const routeComponent: any = ({ location }: any) => {
    return isAuthenticate() ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: { pathname: location.pathname } },
        }}
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUser() {
      try {
        const res = await Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const role = res.signInUserSession.idToken.payload['cognito:groups'] || [];
        const token = res.signInUserSession.idToken.jwtToken;

        setAccessToken(token);
        dispatch(
          loginAction({
            username: res.username,
            email: res.attributes.email,
            email_verified: res.attributes.email_verified,
            family_name: res.attributes.family_name,
            given_name: res.attributes.given_name,
            sub: res.attributes.sub,
            role,
            authCode: res.attributes['custom:authCode'],
          }),
        );
        addUserIdentityInHeap({
          username: res.username,
          email: res.attributes.email,
          email_verified: res.attributes.email_verified,
          family_name: res.attributes.family_name,
          given_name: res.attributes.given_name,
          sub: res.attributes.sub,
          role,
        });
      } catch (error) {
        removeAccessToken();
        dispatch(logoutAction());
        await Auth.signOut({ global: true });
      }
    }
    getUser();
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      {/* <IdleTimeOutContainer /> */}
      <div className="relative flex flex-col min-h-screen antialiased">
        <Navbar />
        <div className="flex-grow mt-16 text-white bg-typBodyBlack">
          <Switch>
            {/* <Route exact path="/" render={() => (isAuthenticate() ? <Redirect to="/browse" /> : <LandingPage />)} /> */}
            <Route exact path="/" render={() => (isAuthenticate() ? <Redirect to="/browse" /> : <Redirect to="/browse" />)} />
            <Route exact path="/browse" component={Browse} />
            <Route exact path="/login" render={() => (isAuthenticate() ? <Redirect to="/user" /> : <LoginPage />)} />
            <Route
              exact
              path="/register"
              render={() => (isAuthenticate() ? <Redirect to="/user" /> : <RegisterPage />)}
            />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:id" component={SingleBlog} />

            {/* <Route exact path="/blog/rss" component={BlogRss} /> */}
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/support" component={SupportPage} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

            <Route exact path="/search">
              <SearchPage />
            </Route>
            <PrivateRoute exact path="/theater">
              <Elements stripe={stripePromise}>
                <LiveStreamPage />
              </Elements>
            </PrivateRoute>
            <Route path="/movies">
              <MoviePage />
            </Route>
            <Route path="/seasons">
              <SeasonPage />
            </Route>
            <Route path="/filmmaker/subscription">
              <FilmmakerSubscription />
            </Route>
            <PrivateRoute path="/filmmaker">
              <FilmmakerPage />
            </PrivateRoute>
            <Route path="/user" component={UserPage} />
            <Route path="/browse/movies/:categoryName" component={CategoryWiseContents} />
            <Route path="/browse/series/:categoryName" component={CategoryWiseContents} />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
