import { config } from 'src/config';
import { IUser } from 'src/stores/user/Reducer';
import { loadScript } from './preload';

const heapConfig = config.heapAnalytics;

const SCRIPT_URL = `https://cdn.heapanalytics.com/js/heap-${heapConfig && heapConfig.appId}.js`;

const isEnabled = heapConfig && heapConfig.appId;

const heapLoadConfig = {
  secureCookie: heapConfig.secureCookie,
};

const load = async () => {
  (window as any).heap = (window as any).heap || [];
  (window as any).heap.appid = heapConfig.appId;
  (window as any).heap.config = heapLoadConfig;
  await loadScript(SCRIPT_URL);
  return (window as any).heap;
};

const withHeap = async (func: any) => {
  // if not token or env are set, this integration is not loaded
  if (!isEnabled) {
    return;
  }

  await load().then(func, (e) => console.warn('failed to load heap script', e));
};

export const init = () =>
  withHeap((heap: any) => {
    if (heap.loaded) {
      return;
    }
    heap.load(heapConfig.appId, heapLoadConfig);
  });

export const identify = (userData: IUser) =>
  withHeap((heap: any) => {
    heap.identify(userData.sub);

    heap.addUserProperties(userData);
  });
