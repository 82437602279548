import { loadStripe } from '@stripe/stripe-js';

export const config = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION!,
    USER_POOL_ID: process.env.REACT_APP_TYPHOON_USER_POOL_ID!,
    APP_CLIENT_ID: process.env.REACT_APP_TYPHOON_APP_CLIENT_ID!,
    IDENTITY_POOL_ID: process.env.REACT_APP_TYPHOON_IDENTITY_POOL_ID!,
    IDENTITY_POOL_REGION: process.env.REACT_APP_AWS_REGION!,
  },
  S3: {
    file: {
      bucket: process.env.REACT_APP_S3_BUCKET_METADATA!, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION!, //OPTIONAL -  Amazon service region
    },
    video: {
      bucket: process.env.REACT_APP_S3_BUCKET_VIDEO!, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION!, //OPTIONAL -  Amazon service region
    },
    blog: {
      bucket: process.env.REACT_APP_S3_BUCKET_BLOG!, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION!, //OPTIONAL -  Amazon service region
    },
  },
  stripe: {
    PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
  },
  IAM: {
    firehose: {
      region: process.env.REACT_APP_AWS_REGION!,
      accessKeyId: process.env.REACT_APP_TYPHOON_FIREHOSE_ACCESS!,
      secretAccessKey: process.env.REACT_APP_TYPHOON_FIREHOSE_SECRET!,
    },
  },
  heapAnalytics: {
    appId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HEAP_APP_ID! : '3011743607',
    secureCookie: process.env.NODE_ENV === 'production',
  },
  theater: {
    sourceObject: {
      src: process.env.REACT_APP_CLOUDFRONT_THEATER_URL!,
      type: 'application/vnd.apple.mpegurl',
    },
  },
};

export const API_ENDPOINT_LAMBDA = process.env.REACT_APP_APIGATEWAY_ENDPOINT!;
export const s3FileUrl = process.env.REACT_APP_S3_CLOUDFRONT_ENDPOINT!;
export const s3ResourceUrl = process.env.REACT_APP_S3_RESOURCE_ENDPOINT!;
export const s3BlogUrl = process.env.REACT_APP_S3_BLOG_ENDPOINT!;

export const stripePromise = loadStripe(config.stripe.PUBLISHABLE_KEY);

export const GoogleAdClient = process.env.REACT_APP_GOOGLE_AD_CLIENT!;

export const AppTitle = 'Typhoon';
