import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import { useSelector } from 'react-redux';
import { s3ResourceUrl } from '../../../config';
import { getUser } from 'src/stores/user/Selectors';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& label.Mui-focused': {
        color: '#4b4b4b',
      },
      '& .MuiInputBase-root': {
        color: '#ffffff',
        backgroundColor: '#292929',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#4b4b4b',
        },
        '&:hover fieldset': {
          borderColor: '#4b4b4b',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#4b4b4b',
        },
      },
    },
  }),
);

export const UserProfilePage = () => {
  const classes = useStyles();
  const user = useSelector(getUser);

  // TODO: create BE API to update profile information
  return (
    <>
      {user && (
        <div className="">
          <h1 className="text-2xl font-semibold mb-6">Profile</h1>
          <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
            <div className="max-w-xl mx-auto">
              <div className="h-16 w-16 rounded-md overflow-hidden mb-5">
                <img src={`${s3ResourceUrl}/images/user-avatar.jpg`} alt="user" />
              </div>
              <form autoComplete="off">
                <div className="mb-5">
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.root}
                    placeholder="First name"
                    value={user.given_name}
                  />
                </div>
                <div className="mb-5">
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.root}
                    placeholder="Last name"
                    value={user.family_name}
                  />
                </div>
                <div className="mb-5">
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.root}
                    placeholder="Email"
                    value={user.email}
                  />
                </div>
                <button
                  // type="submit"
                  className="w-full font-bold text-black border border-typGreen bg-typGreen hover:scale-105 rounded-md transition transform ease-in-out duration-300 py-3 mt-5"
                >
                  SAVE
                </button>
                <button type="reset" className="w-full border border-typGrey10 rounded-md py-3 mt-5">
                  CANCEL
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
