import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { emailRegex, lowercaseRegex, uppercaseRegex, numericRegex, specialCharRegex, setAccessToken } from '../utils';
import { hideLoginModalAction } from '../stores/Global/Actions';
import { Auth } from 'aws-amplify';
import { loginAction } from '../stores/user/Actions';
import { TyphoonBackdrop } from './Backdrop';
import TextField from 'src/components/TextField';
import { identify as addUserIdentityInHeap } from 'src/heap/heap-analytics';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

interface IData {
  email: string;
  password: string;
}

interface props {}

export const LoginModalView: React.FC<props> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [cognitoError, setCognitoError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyLink, setShowVerifyLink] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const hideLoginModal = React.useCallback(() => dispatch(hideLoginModalAction()), [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await Auth.signIn(data.email, data.password);
      const role = res.signInUserSession.idToken.payload['cognito:groups'] || [];
      const token = res.signInUserSession.idToken.jwtToken;

      setAccessToken(token);
      dispatch(
        loginAction({
          username: res.username,
          email: res.attributes.email,
          email_verified: res.attributes.email_verified,
          family_name: res.attributes.family_name,
          given_name: res.attributes.given_name,
          sub: res.attributes.sub,
          role,
          authCode: res.attributes['custom:authCode'],
        }),
      );
      addUserIdentityInHeap({
        username: res.username,
        email: res.attributes.email,
        email_verified: res.attributes.email_verified,
        family_name: res.attributes.family_name,
        given_name: res.attributes.given_name,
        sub: res.attributes.sub,
        role,
      });

      hideLoginModal();
      history.replace(location.pathname);
    } catch (error: any) {
      setCognitoError(error.message ? error.message : error);
      if (error.code && error.code === 'UserNotConfirmedException') {
        setShowVerifyLink(true);
      }
    }
  });

  return (
    <div>
      <TyphoonBackdrop open={isSubmitting} />
      <div className="p-8">
        <div className="relative mt-4 mb-2">
          <div className="font-medium text-white text-lg pb-8">
            <p>Hey there! Welcome back.</p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="email"
                id="email"
                fullWidth
                variant="outlined"
                placeholder="Email"
                error={errors.email ? true : false}
              />
              {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                fullWidth
                variant="outlined"
                placeholder="Password"
                error={errors.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password ? (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.password.message}</p>
              ) : (
                <p className="text-gray-400 text-xs italic font-medium mt-1">
                  Password should contain lowercase, uppercase, number & special characters.
                </p>
              )}
            </div>
            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}
            {showVerifyLink && (
              <Link
                to="/user/verification"
                className="text-typGreen underline text-xs italic font-medium mt-1"
                onClick={hideLoginModal}
              >
                Verify your email here !!
              </Link>
            )}
            <button
              type="submit"
              className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              LOGIN
            </button>
          </form>
          <Link to="/user/forgot-password" className="text-typGreen font-medium text-xs mt-1" onClick={hideLoginModal}>
            Forgot password ?
          </Link>
        </div>
      </div>
      <div className="text-center p-8 bg-typHeaderBlack">
        <p className="text-white">
          Don’t have an account? Click here to{' '}
          <Link to="/register" className="text-typGreen" onClick={hideLoginModal}>
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};
