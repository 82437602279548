import React from 'react';

interface props {
  handleMonthChange: (e: string) => void;
  handleYearChange: (e: string) => void;
  month: string;
  year: string;
}

const MonthYearSelector = ({ handleMonthChange, handleYearChange, month, year }: props) => {
  return (
    <input
      type="month"
      value={year && month && `${year}-${month}`}
      onChange={(e) => {
        handleYearChange(e.target.value.split('-')[0]);
        handleMonthChange(e.target.value.split('-')[1]);
      }}
      className="p-2 text-white border rounded-lg outline-none border-typGreen focus:outline-none bg-typHeaderBlack"
    />
  );
};

export default MonthYearSelector;
