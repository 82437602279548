import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SeasonPurchaseForm from './BuyForm';
import SingleSeasonPage from './SingleSeasonPage';

const SeasonPage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:seasonId`}>
        <SingleSeasonPage />
      </Route>
      <Route exact path={`${path}/:seasonId/buy`}>
        <SeasonPurchaseForm />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}`} />}></Route>
    </Switch>
  );
};

export default SeasonPage;
