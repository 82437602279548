import React, { useState } from 'react';
import TextField from 'src/components/TextField';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { emailRegex } from '../../utils';
import { IContactUsData } from '../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { API_ENDPOINT_LAMBDA, AppTitle } from '../../config';
import Axios from 'axios';
import { TyphoonToaster } from '../../components/Toastify';
import { Helmet } from 'react-helmet';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  subject: Yup.string().trim().required('Required'),
  message: Yup.string().trim().required('Required'),
});

const ContactUs = () => {
  const [submitError, setSubmitError] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<IContactUsData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitError(undefined);
      await Axios.post(`${API_ENDPOINT_LAMBDA}/contact-us`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      TyphoonToaster('Thank you for your query. Our support team will contact with you soon.', 'success');
      reset();
    } catch (error) {
      console.log('error', error);
      setSubmitError('Something went wrong.');
    }
  });

  return (
    <div className="p-6 lg:px-10 xl:px-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Contact Us</title>
      </Helmet>
      <TyphoonBackdrop open={isSubmitting} />
      <div className="w-full sm:w-100 mx-auto">
        <h1 className="text-2xl font-semibold mb-6">
          <span className="text-typGreen">Contact</span> Us
        </h1>
        <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                name="name"
                id="name"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Name"
                error={errors.name ? true : false}
              />
              {errors.name && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.name.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                name="email"
                id="email"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Email"
                error={errors.email ? true : false}
              />
              {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                name="subject"
                id="subject"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Subject"
                error={errors.subject ? true : false}
              />
              {errors.subject && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.subject.message}</p>
              )}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                name="message"
                id="message"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Message"
                error={errors.message ? true : false}
                multiline
                minRows={4}
              />
              {errors.message && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.message.message}</p>
              )}
            </div>
            {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
            <button
              type="submit"
              className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
