import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import TyphoonAccordion from '../../components/Accordion';
import { AppTitle } from '../../config';

export default function SupportPage() {
  return (
    <div className="bg-typDarkBlue p-6 sm:py-12">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Support</title>
      </Helmet>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-semibold">Support</h1>
        <div className="space-y-1 mt-6 mb-10">
          <h2 className="text-xl font-semibold">Subscription Terms</h2>
          <div>
            <div className="flex items-start">
              <div className="w-2 h-2 rounded-full mt-2 mr-4 bg-typGreen"></div>
              <p>Subscription fee is non-refundable.</p>
            </div>
            <div className="flex items-start">
              <div className="w-2 h-2 rounded-full mt-2 mr-4 bg-typGreen"></div>
              <p>Subscription can be cancelled at any time.</p>
            </div>
            <div className="flex items-start">
              <div className="w-2 h-2 rounded-full mt-2 mr-4 bg-typGreen"></div>
              <p>Subscription payment is managed by Stripe.</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-800 rounded-md p-6 sm:p-10">
          <div className="flex flex-col space-y-5 mb-12">
            <div className="space-y-3">
              <TyphoonAccordion
                title="What is the minimum age to use Typhoon?
  "
                body="Typhoon On Demand has a minimum age requirement of 18 years old. To subscribe as a
                filmmaker and upload content, you must be at least 18 years old."
              />
              <TyphoonAccordion
                title="What are Typhoon's Terms of Service?"
                body={
                  <Link to={'/terms-and-conditions'} target="_blank" className="font-semibold text-typGreen">
                    <span className="text-white">They can be found here: </span>
                    https://terms-and-conditions
                  </Link>
                }
              />
              <TyphoonAccordion
                title="What is Typhoon's Privacy Policy?"
                body={
                  <Link to={'/privacy-policy'} target="_blank" className="font-semibold text-typGreen">
                    <span className="text-white">They can be found here: </span>
                    https://privacy-policy
                  </Link>
                }
              />
              <TyphoonAccordion
                title="Do I need to supply legal documents, such as copyright clearances, before content can be uploaded?"
                body="No, Typhoon's contract stipulates that you, the filmmaker, are responsible for producing any such documents should any of your content or it’s assets be challenged. (Typhoon Terms and Conditions)."
              />
              <TyphoonAccordion
                title="Can I edit my submission even after it’s been uploaded and made available?"
                body="Yes- From your filmmaker portal."
              />
              <TyphoonAccordion
                title="Do I keep 100% of my film rights?"
                body="Yes, you keep 100% of your rights to your content."
              />
              <TyphoonAccordion
                title="Is Typhoon exclusive?"
                body="Typhoon is nonexclusive. You can still work with film aggregators, sales agents and distributors; 
                even place your film on other platforms."
              />
              <TyphoonAccordion
                title="What formats does Typhoon accept?"
                body="Typhoon is beyond film. You can submit TV, docs, digital first, family… any format, genre, language. With Typhoon, you can monetize dormant assets and fill those distribution gaps."
              />

              <TyphoonAccordion
                title="What territories or countries will my film be available in?"
                body="We are currently serving the entire US, Canada, EU, UK and Asia Pacific. Typhoon does not stream in Russia."
              />
              <TyphoonAccordion
                title="Does Typhoon collect my personal data and share it?"
                body="We collect only your email address upon sign up and keep it private. We may collect data based on what a customer watches in order to make suggestions to related
                content."
              />
              <TyphoonAccordion
                title="When will my film be made available after it's uploaded?"
                body="Right now our turnaround is pretty quick. The approval process can take 1-3 days."
              />
              <TyphoonAccordion
                title="How do I find the Typhoon Inner Circle Facebook group?"
                body="Search on Facebook for 'Typhoon On Demand.' Click on 'groups' and ask to join."
              />
              <TyphoonAccordion
                title="Can I download the content and view it when I am not online?"
                body="Our content is streaming only. You must be logged into your account to view your content."
              />
            </div>
            <div className="text-center">
              <h1 className=" text-2xl font-semibold m-8">Have any more questions?</h1>
              <p className="text-center">
                We’d be happy to answer them! Please reach out to us at
                <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
                  {' '}
                  support@typhoonondemand.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
