import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M17 12a5 5 0 00-3-4v2l2 3a4 4 0 001-1zm2 0a7 7 0 01-1 3l2 1a9 9 0 001-4 9 9 0 00-7-9v2a7 7 0 015 7zM4 3L3 4l5 5H3v6h4l5 5v-7l4 5a7 7 0 01-2 1v2a9 9 0 004-2l2 2 1-1-9-9zm8 1l-2 2 2 2z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

export default SVG;
