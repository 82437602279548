import React, { useState } from 'react';
import { useMinuteAnalytics } from 'src/components/hooks/useMinuteWiseAnalytics';
import Top5MoviesLayout from './Top5MoviesLayout';

const MinuteWise = () => {
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const handleMonthChange = (e: string) => {
    setMonth(e);
  };

  const handleYearChange = (e: string) => {
    setYear(e);
  };

  const movies = useMinuteAnalytics({ month: month, year: year });

  return (
    <div>
      {movies.data && (
        <Top5MoviesLayout
          title="By time spent watching"
          year={year}
          month={month}
          handleYearChange={handleYearChange}
          handleMonthChange={handleMonthChange}
        >
          {movies.data}
        </Top5MoviesLayout>
      )}
      {movies.data && movies.data.length === 0 && <p>No data</p>}
      {movies.isLoading && (
        <div>
          <p>Calculating your most time spent watching movies...</p>
        </div>
      )}
    </div>
  );
};

export default MinuteWise;
