import React, { useEffect, useState } from 'react';
import { TyphoonBackdrop } from '../Backdrop';
import { StepIndicator } from '../StepIndicator';
import TyphoonIcon from '../icons';
import { useHistory, useLocation } from 'react-router-dom';
import { getAccessToken, splitTitle, truncateReverse } from '../../utils';
import Axios from 'axios';
import { TyphoonToaster } from '../Toastify';
import { config, API_ENDPOINT_LAMBDA } from '../../config';
import { Storage } from 'aws-amplify';
import { LinearProgress } from '@material-ui/core';
import { IStandAloneMovie, IStandAloneMovieStep3, IUploadForms } from '../../types';
import { useQuery } from 'react-query';

const fileExtType = ['mp4'];

const fetchMovie = async (movieId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies/${movieId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export const UploadMovieStep3: React.FC<IUploadForms> = ({ stepsLength, currentStep, title, subpath }) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = new URLSearchParams(location.search);
  const movieId = queryObject.get('movieId');

  const { status, data } = useQuery<IStandAloneMovie, Error>(
    ['check-stand-alone', movieId],
    () => fetchMovie(movieId!),
    {
      refetchOnWindowFocus: false,
      enabled: !!movieId,
    },
  );

  // loading
  const [isTrailerLoading, setIsTrailerLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // progress
  const [trailerProgress, setTrailerProgress] = useState(0);

  // hover on drop
  const [isTrailerHoverOnDrop, setTrailerHoverOnDrop] = useState(false);

  // file
  const [trailer, setTrailer] = useState<File>();

  // errors
  const [trailerError, setTrailerError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const handleChangeTrailerFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrailer(undefined);
    if (event.target.files) {
      const file = event.target.files[0];
      const extType = file.name.split('.').pop();
      if (file && extType) {
        if (!fileExtType.includes(extType.toLocaleLowerCase())) {
          setTrailerError('Only mp4 is allowed.');
          return;
        } else {
          setTrailer(file);
          setTrailerError(undefined);
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (trailer && data) {
      setTrailerProgress(0);
      try {
        let values: IStandAloneMovieStep3 = {};

        const trailerType = trailer.name.split('.').pop();
        setIsTrailerLoading(true);
        const trailerResponse = (await Storage.put(`${movieId}/trailer.${trailerType}`, trailer, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setTrailerProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        setIsTrailerLoading(false);
        if (trailerResponse) {
          setIsSubmitting(true);
          values.trailer = trailerResponse.key;
          await Axios.put(`${API_ENDPOINT_LAMBDA}/movies/${movieId}`, values, {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
              'Content-Type': 'application/json',
            },
          });

          const path =
            subpath === 'stand-alone'
              ? `/filmmaker/upload/${subpath}/step4?movieId=${movieId}`
              : `/filmmaker/upload/${subpath}/step5?movieId=${movieId}`;

          history.push(path);
        }
      } catch (error: any) {
        console.log(error.response);
        setSubmitError('Unable to upload!!');
      }
      setIsSubmitting(false);
    } else {
      const path =
        subpath === 'stand-alone'
          ? `/filmmaker/upload/${subpath}/step4?movieId=${movieId}`
          : `/filmmaker/upload/${subpath}/step5?movieId=${movieId}`;

      history.push(path);
    }
  };

  useEffect(() => {
    if (status === 'success' && !data) {
      TyphoonToaster('Invalid request!!', 'danger');
      history.push('/filmmaker/portal');
    }
  }, [history, status, data]);

  return (
    <div className="max-w-md mx-auto">
      <h1 className="mb-6 text-2xl font-semibold text-center">
        <span className="text-typGreen">{splitTitle(title)[0]}</span> {splitTitle(title)[1]}
      </h1>
      <div className="p-6 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
        <StepIndicator className="mb-8" stepsLength={stepsLength} currentStep={currentStep} />
        <TyphoonBackdrop open={isSubmitting} />
        <div className="mb-5">
          <div
            className={`border ${isTrailerHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
              trailerError ? 'border-red-500' : ''
            } bg-typBodyBlack rounded-md`}
            onDragEnter={() => {
              setTrailerHoverOnDrop(true);
            }}
            onDragLeave={() => {
              setTrailerHoverOnDrop(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setTrailerHoverOnDrop(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              setTrailerHoverOnDrop(false);
              setTrailer(undefined);
              const files = Array.from(e.dataTransfer.files);
              const extType = files[0].name.split('.').pop();
              if (files.length > 1) {
                setTrailerError('Drop only One file.');
              } else if (files.length === 1 && extType) {
                if (!fileExtType.includes(extType.toLocaleLowerCase())) {
                  setTrailerError('Only mp4 is allowed.');
                  return;
                } else {
                  setTrailer(files[0]);
                  setTrailerError(undefined);
                  return;
                }
              }
            }}
          >
            <input
              accept="video/*"
              className="hidden"
              id="browse-trailer-file-button"
              type="file"
              onChange={handleChangeTrailerFile}
            />
            <label htmlFor="browse-trailer-file-button">
              {trailer ? (
                <div className="flex items-center justify-start p-6 cursor-pointer">
                  <div>{truncateReverse(trailer.name, 30)}</div>
                </div>
              ) : (
                <div className="flex items-center justify-start p-6 space-x-6 cursor-pointer">
                  <TyphoonIcon name="upload" className="h-10 fill-current text-typGreen" />
                  <p className="text-sm text-typGreen">
                    Click here to upload <span className="text-base font-semibold">Trailer</span>
                  </p>
                </div>
              )}
            </label>
          </div>
          {isTrailerLoading && (
            <div className="flex items-center mt-1">
              <div className="flex-grow">
                <LinearProgress variant="determinate" value={trailerProgress} />
              </div>
              <p className="ml-2 text-sm font-semibold text-typGreen">{`${trailerProgress}%`}</p>
            </div>
          )}
          {trailerError && <p className="mt-1 text-xs italic font-medium text-red-500">{trailerError}</p>}
        </div>
        {submitError && <p className="mt-1 text-xs italic font-medium text-red-500">{submitError}</p>}
        <button
          onClick={handleSubmit}
          disabled={isSubmitting || isTrailerLoading}
          className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
            isSubmitting || isTrailerLoading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};
