import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M12,7.13l.97,2.29.47,1.11,1.2.1,2.47.21-1.88,1.63-.91.79.27,1.18.56,2.41-2.12-1.28L12,14.93l-1.03.62L8.85,16.83l.56-2.41.27-1.18-.91-.79L6.89,10.82l2.47-.21,1.2-.1.47-1.11L12,7.13M12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21,12,17.27,18.18,21l-1.64-7.03L22,9.24l-7.19-.61L12,2Z" />
  </svg>
);

export default SVG;
