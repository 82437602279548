import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TyphoonToaster } from 'src/components/Toastify';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { getAccessToken } from 'src/utils';
import { useQueryClient } from 'react-query';

interface INewsData {
  title: string;
  description: string;
}
const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
});

const NewsCreate = () => {
  const queryClient = useQueryClient();
  const [submitError, setSubmitError] = useState<string>();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<INewsData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitError(undefined);
      await Axios.post(`${API_ENDPOINT_LAMBDA}/news`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      queryClient.invalidateQueries(['news-List']);
      TyphoonToaster('News Published.', 'success');

      reset();
    } catch (error) {
      console.log('error', error);
      setSubmitError('Something went wrong.');
    }
  });

  return (
    <div className="p-4 my-4 border-2 rounded-md bg-typHeaderBlack border-typGrey10">
      <form onSubmit={onSubmit}>
        <div>
          <input
            type="text"
            placeholder="Title here..."
            className="w-full py-4 text-2xl bg-transparent focus:outline-none"
            {...register('title')}
          />
          {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}

          <input
            type="text"
            placeholder="Description here..."
            className="w-full py-6 bg-transparent focus:outline-none"
            {...register('description')}
          />
          {errors.description && (
            <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
          )}
        </div>
        {submitError && <p className="mt-1 text-xs italic font-medium text-red-500">{submitError}</p>}

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-white transition rounded focus:outline-none bg-typGreen/60 hover:bg-typGreen disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Publishing' : 'Publish'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsCreate;
