import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { API_ENDPOINT_LAMBDA, AppTitle } from '../../config';
import { Helmet } from 'react-helmet';
import { getAccessToken } from 'src/utils';
import axios from 'axios';
import querystring from 'querystring';
import { useQuery } from 'react-query';

const fetchStripeSubscriptionSession = async (id: string) => {
  const res = await axios.get(`${API_ENDPOINT_LAMBDA}/stripe/subscriptions/sessions/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  });
  return res.data;
};

const SubscriptionSuccess = () => {
  const location = useLocation();
  const params = querystring.decode(location.search.substring(1, location.search.length));

  const sessionMethods = useQuery<any, Error>(
    ['stripe-session-retrieve', params.session_id],
    async () => {
      return await fetchStripeSubscriptionSession(params.session_id as string);
    },
    {
      refetchOnWindowFocus: false,
      enabled: params.session_id ? true : false,
      retry: 1,
    },
  );

  return (
    <div className="max-w-xl mx-auto space-y-4">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Typhoon Subscription</title>
      </Helmet>
      <TyphoonBackdrop open={sessionMethods.isLoading} />
      <div className="space-y-6">
        <h1 className="font-medium text-white text-xl">Typhoon Subscription</h1>
        {sessionMethods.data && sessionMethods.data.data[0].price.recurring.interval && (
          <p className="mt-5">
            You've successfully purchased{' '}
            <NavLink to="/user/settings" className="text-typGreen text-lg capitalize font-semibold underline">
              {sessionMethods.data.data[0].price.recurring.interval}
              {'ly'}
            </NavLink>
            .
          </p>
        )}
        {/*{sessionMethods.data && <pre className="p-2">{JSON.stringify(sessionMethods.data, undefined, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
