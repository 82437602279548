import { useState, useEffect } from 'react';

export default function usePaginationDebounce(pageNumber: number, delay = 500) {
  const [debouncedPageNumber, setDebouncedPageNumber] = useState(pageNumber);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPageNumber(pageNumber);
    }, delay);
    return () => clearTimeout(timer);
  }, [pageNumber, delay]);
  return debouncedPageNumber;
}
