import React, { useState } from 'react';
import { TyphoonBackdrop } from '../../../components/Backdrop';
import { Link, useHistory, useParams } from 'react-router-dom';
import { s3FileUrl, API_ENDPOINT_LAMBDA, AppTitle } from '../../../config';
import TyphoonIcon from '../../../components/icons';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import Axios from 'axios';
import { TyphoonToaster } from '../../../components/Toastify';
import { IPaginationInfo, ISeason, IStandAloneMovie } from '../../../types';
import { EditSeasonStep1 } from '../../../components/uploadForms/EditSeasonStep1';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../../utils/acl';
import { TyphoonModal } from '../../../components/Modal';
import { Helmet } from 'react-helmet';
import { getAccessToken } from 'src/utils';
import DeleteContent from 'src/components/Contents/DeleteContent';
import { getUserId, getUserRole } from 'src/stores/user/Selectors';

interface IMovieType {
  data: IStandAloneMovie[];
  paginationInfo: IPaginationInfo;
}

const fetchSeason = async (seasonID: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons/${seasonID}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

// TODO check pagination of this page
const fetchEpisodes = async (seasonID: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies?seasonID=${seasonID}&pageNumber=1&pageSize=200&isEpisodic=true`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

const liveToTyphoon = async (seasonID: string) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/seasons/${seasonID}/active`,
    {
      status: true,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

const unLiveToTyphoon = async (seasonID: string) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/seasons/${seasonID}/active`,
    {
      status: false,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

interface paramProps {
  seasonID: string;
}

export const FilmmakerSingleSeason = () => {
  const params = useParams<paramProps>();
  const history = useHistory();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  const season = useQuery<ISeason, Error>(['portal-season', params.seasonID], () => fetchSeason(params.seasonID), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!params.seasonID,
  });

  const episodes = useQuery<IMovieType, Error>(
    ['portal-season-episodes', params.seasonID],
    () => fetchEpisodes(params.seasonID),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!season.data,
    },
  );

  const liveToTyphoonMutation = useMutation(liveToTyphoon, {
    onSuccess: () => {
      TyphoonToaster('This movie is live on Typhoon.', 'success');
      queryClient.invalidateQueries(['portal-season', params.seasonID]);
    },
  });

  const unliveToTyphoonMutation = useMutation(unLiveToTyphoon, {
    onSuccess: () => {
      TyphoonToaster('This movie is un-live on Typhoon.', 'success');
      queryClient.invalidateQueries(['portal-season', params.seasonID]);
    },
  });

  const handleLiveToTyphoon = async () => {
    if (isAdmin(userRole)) {
      await liveToTyphoonMutation.mutateAsync(params.seasonID);
    }
  };

  const handleUnLiveToTyphoon = async () => {
    if (isAdmin(userRole)) {
      await unliveToTyphoonMutation.mutateAsync(params.seasonID);
    }
  };
  const handleInfoModalOnClose = React.useCallback(() => {
    setShowInfoModal(false);
  }, []);

  const handleInfoModalOpen = () => {
    setShowInfoModal(true);
  };

  const queryClient = useQueryClient();

  const handleOnClose = React.useCallback(() => {
    queryClient.invalidateQueries(['portal-season-episodes', params.seasonID]);
    setIsEditOpen(false);
  }, [queryClient, params.seasonID]);

  if (!isAdmin(userRole) && userId && season.data && season.data.uploader.toString() !== userId.toString()) {
    TyphoonToaster('Unauthorized access.', 'danger');
    history.push('/filmmaker/portal');
  }

  return (
    <div className="container mx-auto mb-12">
      <TyphoonBackdrop
        open={season.isFetching || liveToTyphoonMutation.isLoading || unliveToTyphoonMutation.isLoading}
      />
      {!season.isFetching && !season.data && (
        <>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>No Movie Found</title>
          </Helmet>
          <p>No season found.</p>
        </>
      )}
      {season.data && (
        <div>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>{season.data.title}</title>
          </Helmet>
          <div className="flex flex-col mb-12 overflow-hidden border-2 sm:flex-row bg-typHeaderBlack rounded-2xl border-typGrey10">
            <div className="sm:w-1/2 sm:flex sm:items-center sm:p-10">
              <img
                className="object-cover w-full sm:rounded-2xl sm:overflow-hidden"
                src={`${s3FileUrl}${season.data.banner}`}
                alt={season.data.title}
              />
            </div>
            <div className="p-6 sm:w-1/2">
              <h1 className="mb-5 text-3xl font-bold sm:text-5xl">{season.data.title}</h1>
              <div className="flex mb-5 space-x-2">
                <TyphoonIcon name="visibility" className="h-6 fill-current" />
                <span>{season.data.isActive ? 'Active' : 'Inactive'}</span>
              </div>
              <p className="mb-5 text-sm font-bold sm:font-medium sm:text-base line-clamp-4 md:line-clamp-3">
                {season.data.description}
              </p>
              <button
                onClick={() => setIsEditOpen(true)}
                className="px-6 py-2 font-semibold text-white transition duration-300 ease-in-out border border-white rounded-lg focus:outline-none"
              >
                Edit Season Details
              </button>
            </div>
          </div>
          <div className="flex flex-col mb-12 space-y-3 sm:flex-row sm:space-y-0 sm:justify-between">
            <div className="space-y-2">
              <h6 className="font-medium">{season.data.title}</h6>
              <div className="flex flex-wrap -m-2">
                <button
                  onClick={handleInfoModalOpen}
                  className="px-5 py-1 m-2 font-medium text-black rounded-md bg-typGreen"
                >
                  Preview
                </button>
                {isAdmin(userRole) && (
                  <>
                    <DeleteContent />
                    {season.data.isActive ? (
                      <button
                        disabled={unliveToTyphoonMutation.isLoading}
                        className={`cursor-pointer py-1 px-5 m-2 bg-typGreen text-black rounded-md font-medium ${
                          unliveToTyphoonMutation.isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'opacity-100 cursor-pointer'
                        }`}
                        onClick={handleUnLiveToTyphoon}
                      >
                        Make Un-Live
                      </button>
                    ) : (
                      <button
                        disabled={liveToTyphoonMutation.isLoading}
                        className={`cursor-pointer py-1 px-5 m-2 bg-typGreen text-black rounded-md font-medium ${
                          liveToTyphoonMutation.isLoading
                            ? 'opacity-50 cursor-not-allowed'
                            : 'opacity-100 cursor-pointer'
                        }`}
                        onClick={handleLiveToTyphoon}
                      >
                        Make Live
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>

            {season.data.filmmaker &&
            typeof season.data.filmmaker === 'object' &&
            Object.keys(season.data.filmmaker).length > 0 ? (
              <div className="space-y-2">
                <h6 className="font-semibold text-typGreen">Filmmaker Information</h6>
                <div className="inline-block p-3 bg-gray-900">
                  {season.data.filmmaker?.sub && (
                    <div className="space-x-2">
                      <span>ID :</span>
                      <span>{season.data.filmmaker.sub}</span>
                    </div>
                  )}
                  <div className="space-x-2">
                    <span>Name :</span>
                    <span>
                      {season.data.uploader === 'filmhub'
                        ? 'Filmhub'
                        : `${season.data.filmmaker.given_name} ${season.data.filmmaker.family_name}`}
                    </span>
                  </div>
                  {season.data.filmmaker?.email && (
                    <div className="space-x-2">
                      <span>Email :</span>
                      <span>{season.data.filmmaker.email}</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="space-y-2">
                <h6 className="font-semibold text-typGreen">Filmmaker Information</h6>
                <p className="inline-block w-full p-3 bg-gray-900">Name : Filmhub</p>
              </div>
            )}
          </div>
          {isEditOpen && (
            <div className="relative px-3 py-10 mb-12 border border-typGrey10">
              <div
                className="absolute top-0 right-0 p-2 text-gray-500 transition duration-500 ease-in-out border border-transparent rounded cursor-pointer hover:text-white hover:border-white"
                onClick={() => setIsEditOpen(false)}
              >
                <TyphoonIcon name="navbar-open" className="h-6 fill-current" />
              </div>
              <div>
                <EditSeasonStep1 data={season.data} onClose={handleOnClose} />
              </div>
            </div>
          )}
          <TyphoonModal page={null} open={showInfoModal} onClose={handleInfoModalOnClose}>
            <div className="max-w-3xl bg-typHeaderBlack">
              <div className="relative">
                {season.data.trailer ? (
                  <video
                    autoPlay={false}
                    muted={true}
                    poster={`${s3FileUrl}${season.data.banner}`}
                    className="w-full"
                    controls
                  >
                    <source src={`${s3FileUrl}${season.data.trailer}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={`${s3FileUrl}${season.data.banner}`} alt={season.data.title} />
                )}
              </div>
            </div>
          </TyphoonModal>
        </div>
      )}
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-wrap items-center justify-between mb-6 -m-3">
          <h1 className="m-3 text-2xl font-medium text-typGreen">
            All
            <span className="text-white"> Episodes</span>
          </h1>
          <Link
            to={`/filmmaker/upload/episodic/step2?seasonID=${season.data?.id}`}
            className="px-5 py-2 m-3 font-bold text-black rounded-md bg-typGreen"
          >
            Upload New
          </Link>
        </div>
        <div>
          {episodes.data &&
            episodes.data.data
              .map((episode, i) => (
                <div
                  key={episode.id}
                  className="mb-6 transition duration-500 ease-in-out transform border-2 rounded-md bg-typHeaderBlack border-typGrey10 hover:scale-105"
                >
                  <div className="flex items-center justify-between px-6 py-5 border-b-2 border-typGrey10 md:px-8">
                    <h2 className="text-xl font-semibold">{`Episode ${i + 1}`}</h2>{' '}
                    <Link
                      to={`/filmmaker/contents/${episode.id}`}
                      className="px-6 py-2 font-semibold text-white transition duration-300 ease-in-out border border-white rounded-lg focus:outline-none"
                    >
                      Edit
                    </Link>
                  </div>
                  <div className="flex flex-col p-6 space-y-5 md:flex-row md:space-y-0 md:space-x-8 md:p-8">
                    <div className="md:w-1/3">
                      <div className="aspect-ratio--16x9">
                        <div className="overflow-hidden border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10">
                          <img
                            className="object-cover w-full h-full"
                            src={`${s3FileUrl}${episode.banner}`}
                            alt={episode.title}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:w-2/3">
                      <h1 className="mb-5 text-xl font-semibold">{episode.title}</h1>
                      <p className="text-sm font-semibold sm:font-medium sm:text-base line-clamp-3">
                        {episode.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          {!episodes.isFetching && (!episodes.data || episodes.data.data.length < 1) && <p>No episodes found.</p>}
        </div>
      </div>
    </div>
  );
};
