import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Axios from 'axios';
import { TyphoonToaster } from 'src/components/Toastify';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { INewsData } from 'src/types';
import { getAccessToken } from 'src/utils';
import { useQueryClient } from 'react-query';

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
});

interface IProps {
  onClose: () => void;
  news: INewsData;
}

export const NewsEdit: React.FC<IProps> = ({ news, onClose }) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<INewsData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      title: news.title,
      description: news.description,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Axios.put(`${API_ENDPOINT_LAMBDA}/news/${news.id}`, data, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      queryClient.invalidateQueries(['news-List']);
      TyphoonToaster('News updated.', 'success');
      onClose();
    } catch (error: any) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <form onSubmit={onSubmit} className="p-8 text-white">
      <h1 className="text-2xl font-semibold text-center text-typGreen">Edit News</h1>
      <div className="px-8">
        <div>
          <input
            type="text"
            placeholder="Title here..."
            className="w-full py-2 text-2xl bg-transparent focus:outline-none"
            {...register('title')}
          />
          {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}
          <input
            type="text"
            placeholder="Description here..."
            className="w-full py-2 bg-transparent focus:outline-none"
            {...register('description')}
          />
          {errors.description && (
            <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
          )}
        </div>
      </div>

      {error && <p className="mt-1 text-xs font-medium text-center text-red-500">{error}</p>}
      <div className="flex flex-row-reverse">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`bg-primary text-typGreen px-4 py-2 rounded-md sticky border-2 border-green-500 ${
            isSubmitting ? 'opacity-75 cursor-not-allowed' : 'opacity-100 focus:outline-none'
          }`}
        >
          {isSubmitting ? 'Updating ...' : 'Update'}
        </button>
      </div>
    </form>
  );
};
