import React, { useState } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { API_ENDPOINT_LAMBDA, AppTitle } from '../../config';
import { IStandAloneMovie } from '../../types';
import { getAccessToken } from '../../utils';
import { Helmet } from 'react-helmet';
import { getUserLoginStatus, getUserRole } from 'src/stores/user/Selectors';
import { isAdmin, isFreeUser, isSubscriber } from 'src/utils/acl';

const fetchMovie = async (movieId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies/${movieId}/public`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

interface params {
  movieId: string;
}

const MoviePurchaseForm = () => {
  const params = useParams<params>();
  const [isLoading, setIsLoading] = useState(false);
  const userRole = useSelector(getUserRole);
  const isLogin = useSelector(getUserLoginStatus);

  const movie = useQuery<IStandAloneMovie, Error>(['movies', params.movieId], () => fetchMovie(params.movieId), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!params.movieId,
  });

  const handleCheckout = async (isMonthly: boolean) => {
    setIsLoading(true);

    try {
      const response = await Axios.post(
        `${API_ENDPOINT_LAMBDA}/stripe/subscriptions/checkout?isMonthly=${isMonthly}`,
        undefined,
        {
          headers: { Authorization: `Bearer ${getAccessToken()}` },
        },
      );
      console.log('response.data', response.data);
      setIsLoading(false);

      window.location.href = response.data.sessionUrl;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto my-6 sm:my-12 px-6 lg:px-10 xl:px-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Movie Purchase</title>
      </Helmet>
      <TyphoonBackdrop open={isLoading || movie.isLoading} />
      {movie.data ? (
        <div className="space-y-3">
          <div>
            <h1 className="font-medium text-white text-2xl mb-1">Movie</h1>
            <h2 className="text-typGreen font-semibold text-xl">{movie.data.title}</h2>
          </div>
          {movie.data.isEpisodic ? (
            <div className="space-y-1">
              <p className="text-red-600">Episode is not purchasable.</p>
              <Link to={`/seasons/${movie.data.seasonID}`} className="text-typGreen underline">
                Season Link
              </Link>
            </div>
          ) : (
            <>
              <div className="space-y-1">
                {isFreeUser(userRole) && (
                  <p className="text-white">
                    Free user can watch any movie without{' '}
                    <span className="text-typGreen font-semibold">Subscription.</span>
                  </p>
                )}
                {isAdmin(userRole) && (
                  <p className="text-white">
                    Admin can watch any movie without <span className="text-typGreen font-semibold">Subscription.</span>
                  </p>
                )}
                {isSubscriber(userRole) && (
                  <p className="text-white">
                    You have already purchased <span className="text-typGreen font-semibold">Subscription.</span>
                  </p>
                )}
                <Link to={`/movies/${movie.data.id}`} className="text-typGreen underline">
                  Watch it here!
                </Link>
              </div>
              {isLogin && isFreeUser(userRole) && (
                <div>
                  <h2 className="text-lg font-medium mb-1">Buy Subscription</h2>
                  <div className="flex flex-col sm:flex-row -m-1">
                    <button
                      type="button"
                      onClick={() => handleCheckout(true)}
                      className="bg-typGreen text-black font-semibold rounded-md px-5 py-2 m-1"
                    >
                      $9.99 Monthly
                    </button>
                    <button
                      type="button"
                      onClick={() => handleCheckout(false)}
                      className="bg-typGreen text-black font-semibold rounded-md px-5 py-2 m-1"
                    >
                      $99.99 Yearly
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        !movie.isLoading && <p className="border-b border-typGrey30 text-red-600 p-2">No movie found.</p>
      )}
    </div>
  );
};

export default MoviePurchaseForm;
