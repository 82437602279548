import React, { useEffect, useState } from 'react';
import './index.css';

interface props {
  stepsLength: number;
  currentStep: number;
  className?: string;
}

export const StepIndicator: React.FC<props> = ({ stepsLength, currentStep, className = '' }) => {
  const [width, setWidth] = useState<number>(50);
  const [steps] = useState(new Array(stepsLength).fill(1));

  useEffect(() => {
    setWidth(Math.round(100 / stepsLength));
  }, [stepsLength]);

  const checkIsCompleted = (i: number) => {
    const step = i + 1;
    if (currentStep <= stepsLength && step < currentStep) {
      return 'complete';
    }
    return '';
  };

  return (
    <div className={className}>
      <div className="overflow-auto">
        <div className="step-indicator">
          {steps.map((step, i) => (
            <div key={i} style={{ width: `${width}%` }} className={`step ${checkIsCompleted(i)}`}>
              <div className="bullet text-typGreen font-bold">{step + i}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
