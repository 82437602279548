import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AppTitle } from '../../config';

const NoMatch = () => {
  let location = useLocation();

  return (
    <>
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Not Found</title>
      </Helmet>
      <h2 className="font-semibold text-red-600">
        No match for <code>{location.pathname}</code>
      </h2>
    </>
  );
};

export default NoMatch;
