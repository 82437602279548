import Axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { API_ENDPOINT_LAMBDA, s3FileUrl } from '../config';
import { categoryTypes, IPaginationInfo, ISeason, IStandAloneMovie } from '../types';
import { getQueryParams, splitTitle } from '../utils';
import TyphoonIcon from './icons';
import MoviePopOver from './shared/PopOver';

interface IMovieType {
  data: IStandAloneMovie[];
  paginationInfo: IPaginationInfo;
}

interface ISeasonType {
  data: ISeason[];
  paginationInfo: IPaginationInfo;
}

interface IMovieQueryData {
  categoryName: string;
  seasonId: string | undefined;
  pageNumber: number;
  pageSize: number;
}
interface ISeasonQueryData {
  categoryName: string;
  pageNumber: number;
  pageSize: number;
}

const fetchMovies = async (queryData: IMovieQueryData) => {
  const querystring = `${getQueryParams(queryData)}&isActive=true&category=${queryData.categoryName}&${
    queryData.seasonId ? `seasonID=${queryData.seasonId}&isEpisodic=true` : 'isEpisodic=false'
  }`;

  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies?${querystring}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

const fetchSeasons = async (queryData: ISeasonQueryData) => {
  const querystring = `${getQueryParams(queryData)}&isActive=true&category=${queryData.categoryName}`;

  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons?${querystring}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

interface props {
  title: string;
  categoryName: string;
  showSeason?: boolean;
  seasonId?: string;
}

// TODO: Add loading skeletons
export const MoviesRowSlider: React.FC<props> = ({ title, categoryName, showSeason = false, seasonId }) => {
  const pageNumber = 1;
  const pageSize = 24;

  const movies = useQuery<IMovieType, Error>(
    [`${title} - ${categoryName} - movies`, { categoryName, seasonId }],
    () => fetchMovies({ categoryName, seasonId, pageNumber, pageSize }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !showSeason,
    },
  );

  const seasons = useQuery<ISeasonType, Error>(
    [`${title} - ${categoryName} - seasons`, { categoryName }],
    () => fetchSeasons({ categoryName, pageNumber, pageSize }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: showSeason,
    },
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [activeMovie, setActiveMovie] = useState<any>();
  const [delayHandler, setDelayHandler] = useState<any>(null);
  const [activeKey, setActiveKey] = useState(-1);

  const [slider, setSlider] = useState<Slider>();
  const [isPrevShow, setIsPrevShow] = useState(false);
  const handleNextSlide = () => slider && slider.slickNext();
  const handlePrevSlide = () => slider && slider.slickPrev();
  const settings: Settings = {
    lazyLoad: 'ondemand',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    initialSlide: 0,
    arrows: false,
    className: 'slider-moviesRow',
    dotsClass: 'slick-dots-moviesRow',
    beforeChange: (current, next) => {
      if (next > 0) {
        setIsPrevShow(true);
      } else {
        setIsPrevShow(false);
      }
    },
    appendDots: (dots) => {
      return (
        <div>
          <ul> {dots} </ul>
        </div>
      );
    },
    customPaging: (i) => (
      <div className="w-3 h-1 transition duration-200 ease-in-out rounded-lg bg-typGrey30 hover:bg-typGrey80"></div>
    ),
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 18000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePopoverOpen = (event: any, movie: any, key: number) => {
    setAnchorEl(event.currentTarget);
    setDelayHandler(
      setTimeout(() => {
        setActiveMovie(movie);
        setActiveKey(key);
      }, 500),
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    clearTimeout(delayHandler);
    setActiveKey(-1);
    setActiveMovie(null);
  };

  return (
    <>
      {((seasons.isFetching === false && seasons.data && seasons.data.data.length > 0) ||
        (movies.isFetching === false && movies.data && movies.data.data.length > 0)) && (
        <div className="space-y-1">
          <div className="flex items-center -ml-2 space-x-5">
            <h1 className="z-10 text-2xl font-medium text-typGreen">
              {splitTitle(title)[0]}{' '}
              {splitTitle(title).length === 2 && <span className="text-white">{splitTitle(title)[1]}</span>}
            </h1>
            <div className="z-10 flex justify-between space-x-3">
              {isPrevShow && (
                <button
                  className="transition duration-200 ease-in-out border rounded-md border-typGrey30 hover:border-white bg-gray-500/50 focus:outline-none"
                  onClick={handlePrevSlide}
                >
                  <TyphoonIcon name="arrow-left" className="h-6 fill-current text-typGrey80" />
                </button>
              )}
              <button
                className="transition duration-200 ease-in-out border rounded-md border-typGrey30 hover:border-white bg-gray-500/50 focus:outline-none"
                onClick={handleNextSlide}
              >
                <TyphoonIcon name="arrow-right" className="h-6 fill-current text-typGrey80" />
              </button>
            </div>
            <Link className="hover:underline" to={`/browse/${showSeason ? 'series' : 'movies'}/${categoryName}`}>
              See More
            </Link>
          </div>
          {showSeason && seasons.isFetching === false && seasons.data && seasons.data.data.length > 0 && (
            <Slider {...settings} ref={(r) => r && setSlider(r)}>
              {seasons.data.data.map((season, key) => (
                <div key={season.id}>
                  <Link to={`/seasons/${season.id}`}>
                    <div className="aspect-ratio--16x9">
                      <div
                        onMouseEnter={(e) => handlePopoverOpen(e, season, key)}
                        onMouseLeave={handlePopoverClose}
                        className="overflow-hidden transition-shadow duration-100 ease-out border rounded-md aspect-ratio__inner-wrapper border-typGrey10 hover:shadow-thumbnail"
                      >
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${season.thumbnail}`}
                          alt={season.title}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          )}
          {!showSeason && movies.isFetching === false && movies.data && movies.data.data.length > 0 && (
            <Slider {...settings} ref={(r) => r && setSlider(r)}>
              {movies.data.data.map((movie, key) => (
                <div key={movie.id}>
                  <Link key={movie.id} to={`/movies/${movie.id}`}>
                    <div className="aspect-ratio--16x9">
                      <div
                        onMouseEnter={(e) => handlePopoverOpen(e, movie, key)}
                        onMouseLeave={handlePopoverClose}
                        className="overflow-hidden transition-shadow duration-200 ease-out border rounded-md aspect-ratio__inner-wrapper border-typGrey10 hover:shadow-thumbnail"
                      >
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${movie.thumbnail}`}
                          alt={movie.title}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          )}
        </div>
      )}
      {activeMovie ? (
        <MoviePopOver movie={activeMovie} pos={activeKey} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} />
      ) : (
        ''
      )}
    </>
  );
};
