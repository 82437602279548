import React, { createRef, useCallback, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { API_ENDPOINT_LAMBDA, AppTitle, s3FileUrl } from '../../config';
import { IAddSeasonToMyList, IMyListSeasons, ISeason } from '../../types';
import { getAccessToken, MovieRatingNames } from '../../utils';
import Axios from 'axios';
import { TyphoonModal } from '../../components/Modal';
import TyphoonIcon from '../../components/icons';
import { TyphoonToaster } from '../../components/Toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getUserId, getUserLoginStatus } from 'src/stores/user/Selectors';
import { showLoginModalAction } from 'src/stores/Global/Actions';
import { useCategories } from '../../components/hooks/useCategory';

// interface IMovieType {
//   data: IStandAloneMovie[];
//   paginationInfo: IPaginationInfo;
// }

const fetchSeason = async (seasonID: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons/${seasonID}/public`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data;
};

// TODO check pagination of this page
// const fetchEpisodes = async (seasonID: string) => {
//   const res = await Axios.get(
//     `${API_ENDPOINT_LAMBDA}/movies?seasonID=${seasonID}&isActive=true&pageNumber=1&pageSize=200`,
//     {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     },
//   );
//   return res.data;
// };

const addSeasonToMyListMutation = async (data: IAddSeasonToMyList) => {
  await Axios.post(`${API_ENDPOINT_LAMBDA}/myList/seasons`, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

const fetchSingleSeasonFromMyList = async (data: { seasonId: string; userId: string }) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/myList/seasons/${data.userId}?seasonId=${data.seasonId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data;
};

interface paramProps {
  seasonId: string;
}

const SingleSeasonPage = () => {
  const dispatch = useDispatch();

  const params = useParams<paramProps>();
  const [showInfoModal, setShowInfoModal] = useState(true);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [isTrailerMuted, setIsTrailerMuted] = useState(true);
  const [isTrailerPlaying, setIsTrailerPlaying] = useState(true);
  const vidRef = createRef<HTMLVideoElement>();
  const userId = useSelector(getUserId);
  const isLogin = useSelector(getUserLoginStatus);
  const categories = useCategories();

  const season = useQuery<ISeason, Error>(['seasons', params.seasonId], () => fetchSeason(params.seasonId), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!params.seasonId,
  });

  const getCategoryName = function(id: string) {
    return categories.data?.find((category) => category.id === id)?.label
  }

  // const episodes = season.data?.episodes;

  // console.log('Episodes', episodes);

  const myList = useQuery<IMyListSeasons, Error>(
    ['check-season-myList', { seasonId: params.seasonId, userId }],
    () => fetchSingleSeasonFromMyList({ seasonId: params.seasonId, userId: userId! }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!params.seasonId && userId ? true : false,
    },
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(addSeasonToMyListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['check-season-myList', { seasonId: params.seasonId, userId }]);
      TyphoonToaster('Season added to your list.', 'success');
    },
  });

  const handleAddToMyList = async () => {
    if (season.data && userId) {
      await mutation.mutateAsync({
        seasonId: typeof season.data.id === 'number' ? season.data.id : season.data.id,
        userId,
      });
    }
  };

  const handleInfoModalOnClose = React.useCallback(() => {
    setShowInfoModal(false);
    setIsTrailerPlaying(true);
    setIsTrailerMuted(true);
  }, []);

  const handleInfoModalOpen = () => {
    setShowInfoModal(true);
  };

  const handleToggleTrailerPlay = () => {
    if (vidRef.current) {
      isTrailerPlaying ? vidRef.current.pause() : vidRef.current.play();
      setIsTrailerPlaying((v) => !v);
    }
  };
  const handleToggleTrailerMute = () => {
    if (vidRef.current) {
      vidRef.current.muted = !isTrailerMuted;
      setIsTrailerMuted((v) => !v);
    }
  };

  const showLoginModal = useCallback(() => dispatch(showLoginModalAction()), [dispatch]);

  return (
    <div className="container px-6 mx-auto my-12">
      <TyphoonBackdrop open={season.isFetching || mutation.isLoading} />

      {season.data ? (
        <>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>{season.data.title}</title>
          </Helmet>
          <div>
            <div className="flex flex-col mb-12 overflow-hidden border-2 md:flex-row bg-typHeaderBlack rounded-2xl border-typGrey10">
              <div className="md:w-1/2 md:flex md:items-center md:p-6 md:pr-0">
                <img
                  className="object-cover w-full md:rounded-2xl md:overflow-hidden"
                  src={`${s3FileUrl}${season.data.banner}`}
                  alt={season.data.title}
                />
              </div>
              <div className="p-6 space-y-2 md:w-1/2">
                <h1 className="text-3xl font-bold md:text-4xl">{season.data.title}</h1>
                <div className="text-sm font-bold md:font-medium md:text-base line-clamp-4 md:line-clamp-3">
                  <span className="mr-1 text-gray-500">Description:</span>
                  <span>{season.data.description}</span>
                </div>
                <div className="text-sm font-bold md:font-medium md:text-base">
                  <span className="mr-1 text-gray-500">Cast:</span>
                  <span>{season.data.castAndCrew}</span>
                </div>
                {/* <div className="flex flex-wrap -m-1">
                  <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                    {season.data.isPaid ? 'PAID' : 'FREE'}
                  </div>
                  {season.data.isPaid && (
                    <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                      {`One-Time : $${(season.data.price / 100).toFixed(2)}`}
                    </div>
                  )}
                  {season.data.isRentable && (
                    <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                      {`Rent : $${(season.data.rentalPrice / 100).toFixed(2)} / ${
                        parseInt(season.data.rentalDays.toString()) > 0
                          ? `${season.data.rentalDays} days`
                          : `${season.data.rentalDays} day`
                      }`}
                    </div>
                  )}
                </div> */}
                <div className="flex flex-wrap -m-2">
                  {myList.data?.seasons.length === 1 && (
                    <button className="px-4 py-2 m-2 font-bold text-black bg-white border-2 border-white rounded-md focus:outline-none">
                      Added in My List
                    </button>
                  )}
                  <button
                    onClick={handleInfoModalOpen}
                    className="px-6 py-2 m-2 font-bold text-white border-2 border-white rounded-lg focus:outline-none"
                  >
                    More Info
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Start Information Modal  */}
          <TyphoonModal page={null} open={showInfoModal} onClose={handleInfoModalOnClose}>
            <div className="max-w-3xl bg-typHeaderBlack">
              <div className="relative">
                {season.data.trailer ? (
                  <video
                    ref={vidRef}
                    autoPlay={true}
                    muted={true}
                    poster={`${s3FileUrl}${season.data.banner}`}
                    className="w-full"
                  >
                    <source src={`${s3FileUrl}${season.data.trailer}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={`${s3FileUrl}${season.data.banner}`} alt={season.data.title} />
                )}
                <div className="absolute inset-x-0 bottom-0 px-6 -mb-1 movie-modal-fadeBottom">
                  <h1 className="mb-5 text-3xl font-bold leading-none text-white sm:text-5xl">{season.data.title}</h1>
                  <div className="flex items-center justify-between">
                    {season.data.trailer && (
                      <div className="flex space-x-2">
                        <button
                          className="inline-block p-2 transition duration-300 ease-in-out border-2 rounded-full cursor-pointer bg-typHeaderBlack border-typGrey80 hover:border-white group focus:outline-none"
                          onClick={handleToggleTrailerPlay}
                        >
                          {isTrailerPlaying ? (
                            <TyphoonIcon
                              name="pause"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          ) : (
                            <TyphoonIcon
                              name="play"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          )}
                        </button>
                        <button
                          className="inline-block p-2 transition duration-300 ease-in-out border-2 rounded-full cursor-pointer bg-typHeaderBlack border-typGrey80 hover:border-white group focus:outline-none"
                          onClick={handleToggleTrailerMute}
                        >
                          {isTrailerMuted ? (
                            <TyphoonIcon
                              name="mute"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          ) : (
                            <TyphoonIcon
                              name="unmute"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          )}
                        </button>
                      </div>
                    )}

                    {isLogin &&
                      (myList.data?.seasons.length === 1 ? (
                        <button className="px-4 py-2 font-bold text-black transition duration-300 ease-in-out bg-white border-2 border-white rounded-md focus:outline-none">
                          Added in My List
                        </button>
                      ) : (
                        <button
                          onClick={handleAddToMyList}
                          className={`flex items-center space-x-2 px-4 py-2 rounded-md focus:outline-none text-white font-bold border-2 border-white hover:bg-white hover:text-black transition ease-in-out duration-300 ${
                            mutation.isLoading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
                          }`}
                          disabled={mutation.isLoading}
                        >
                          Add to My List
                        </button>
                      ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-6 py-10 space-y-5 text-white sm:space-y-0 sm:space-x-5 sm:flex-row sm:justify-between">
                <div className="space-y-5">
                  <div className="flex space-x-2 font-medium">
                    <span className="px-2 border border-white rounded">{MovieRatingNames[season.data.ratings]}</span>
                  </div>
                  <div className="space-y-1">
                    <p className={showMoreDescription ? '' : 'line-clamp-1 sm:line-clamp-2 md:line-clamp-3'}>
                      {season.data.description}
                    </p>
                    <div
                      className="inline-block p-1 text-xs font-medium text-black bg-white rounded cursor-pointer"
                      onClick={() => setShowMoreDescription((prev) => !prev)}
                    >
                      {showMoreDescription ? 'Less Info' : 'More Info'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-2 text-sm">
                  <div>
                    <span className="mr-1 text-gray-500">Cast:</span>
                    <span>{season.data.castAndCrew}</span>
                  </div>
                  <div>
                    <span className="mr-1 text-gray-500">Category:</span>
                    <span>{getCategoryName(season.data.categoryId)}</span>
                  </div>
                </div>
              </div>
            </div>
          </TyphoonModal>
          {/* End Information Modal  */}
        </>
      ) : (
        !season.isLoading && (
          <>
            <Helmet titleTemplate={`%s | ${AppTitle}`}>
              <title>No Season Found</title>
            </Helmet>
            <p className="p-2 text-red-600 border-b border-typGrey30">No season found.</p>
          </>
        )
      )}
      <div className="max-w-3xl mx-auto">
        <h1 className="mb-6 text-2xl font-medium text-typGreen">
          All
          <span className="text-white"> Episodes</span>
        </h1>
        <div>
          {season.data && season.data.episodes.length > 0
            ? season.data.episodes
                .map((episode: any, i: number) => (
                  <div
                    key={episode.id}
                    className="mb-6 transition duration-500 ease-in-out transform border-2 rounded-md bg-typHeaderBlack border-typGrey10 hover:scale-105"
                  >
                    <div className="flex items-center justify-between px-6 py-5 border-b-2 border-typGrey10 md:px-8">
                      <h2 className="text-xl font-semibold">{`Episode ${i + 1}`}</h2>{' '}
                      <Link
                        to={`/movies/${episode.id}`}
                        className="px-6 py-2 font-semibold text-white transition duration-300 ease-in-out border border-white rounded-lg focus:outline-none"
                      >
                        View
                      </Link>
                    </div>
                    <div className="flex flex-col p-6 space-y-5 md:flex-row md:space-y-0 md:space-x-8 md:p-8">
                      <div className="md:w-1/3">
                        <div className="aspect-ratio--16x9">
                          <div className="overflow-hidden border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10">
                            <img
                              className="object-cover w-full h-full"
                              src={`${s3FileUrl}${episode.banner}`}
                              alt={episode.title}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="md:w-2/3">
                        <h1 className="mb-5 text-xl font-semibold">{episode.title}</h1>
                        <p className="text-sm font-semibold sm:font-medium sm:text-base line-clamp-3">
                          {episode.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
            : !season.isLoading && !season.data?.episodes.length && <p>No episodes found.</p>}
        </div>
      </div>
    </div>
  );
};

export default SingleSeasonPage;
