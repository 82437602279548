import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Axios from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { ISubscriptionResponse } from 'src/types';
import { getAccessToken } from 'src/utils';
import { TyphoonBackdrop } from 'src/components/Backdrop';
import { getUserId } from 'src/stores/user/Selectors';

const fetchSubscription = async (userId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/stripe/subscriptions/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return res.data.subscription;
};

const Subscription = () => {
  const [isPortalLoading, setIsPortalLoading] = useState(false);
  const userId = useSelector(getUserId);
  const { data, isLoading } = useQuery<ISubscriptionResponse, Error>(
    ['subscription', userId],
    () => fetchSubscription(userId!),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!userId,
    },
  );

  const handlePortalSession = async () => {
    setIsPortalLoading(true);

    try {
      const response = await Axios.post(`${API_ENDPOINT_LAMBDA}/stripe/portal/sessions`, undefined, {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
      });
      console.log('response.data', response.data);
      setIsPortalLoading(false);

      window.location.href = response.data.sessionUrl;
    } catch (error) {
      console.log(error);
      setIsPortalLoading(false);
    }
  };

  return (
    <div>
      <TyphoonBackdrop open={isPortalLoading} />
      <h1 className="text-2xl font-semibold mb-6">Subscription</h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
        <div className="flex justify-center p-6 sm:p-10">
          <div className="space-y-5">
            <div>
              <div className="sm:flex space-x-2 items-center">
                <h2 className="text-lg font-medium text-typGreen">Current Subscription:</h2>
                {data ? (
                  <p className="capitalize">{data.transaction.interval + 'ly'}</p>
                ) : isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <p>No subscription.</p>
                )}
              </div>
              {data && (
                <React.Fragment>
                  <div className="sm:flex space-x-2 items-center">
                    <h2 className="text-lg font-medium text-typGreen">Start Date:</h2>
                    <p>{moment(new Date(data.currentPeriodStart)).format('hh:mm a, Do MMM, YYYY')}</p>
                  </div>
                  <div className="sm:flex space-x-2 items-center">
                    <h2 className="text-lg font-medium text-typGreen">End Date:</h2>
                    <p>{moment(new Date(data.currentPeriodEnd)).format('hh:mm a, Do MMM, YYYY')}</p>
                  </div>
                </React.Fragment>
              )}
            </div>
            <button
              type="button"
              className="text-black bg-white font-semibold rounded-md px-2 py-1"
              onClick={async () => await handlePortalSession()}
            >
              Manage Billing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
