import Axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { IBlog, IQueryParams } from 'src/types';
import { getAccessToken, getQueryParams } from 'src/utils';
import usePaginationDebounce from '../hooks/usePaginationDebounce';
import BlogsRss from './BlogsRss';

const fetchBlog = async (queryData: IQueryParams) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/blog/draft?${getQueryParams(queryData)}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return res.data.data;
};

const BlogDrafts = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const debouncedQuery = usePaginationDebounce(pageNumber);

  let queryData = {
    pageNumber: debouncedQuery,
    pageSize: pageSize,
  };

  const blogData = useQuery<IBlog, Error>(['blog-drafts', debouncedQuery], () => fetchBlog(queryData as IQueryParams), {
    refetchOnWindowFocus: false,
  });

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  return <BlogsRss blogData={blogData} handlePageChange={handlePageChange} pageNumber={pageNumber} />;
};

export default BlogDrafts;
