import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: '#222222',
    borderColor: '#ffffff1a',
    maxWidth: 350,
  },
}));

export default function MoviePop(props: any) {
  const { movie, pos, anchorEl, handlePopoverClose } = props;
  const classes = useStyles();

  const aOrigin: any =
    pos % 2
      ? {
          vertical: 'center',
          horizontal: 'left',
        }
      : {
          vertical: 'center',
          horizontal: 'right',
        };

  const tOrigin: any =
    pos % 2
      ? {
          vertical: 'center',
          horizontal: 'right',
        }
      : {
          vertical: 'center',
          horizontal: 'left',
        };

  const open = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={aOrigin}
      transformOrigin={tOrigin}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div className="text-white float-right text-base block md:hidden" onClick={handlePopoverClose}>
        &#x2715;
      </div>
      <div className="font-bold text-white text-2xl leading-none m-5">{movie.title}</div>
      <div className="font-normal text-white text-lg m-5">{movie.description}</div>
    </Popover>
  );
}
