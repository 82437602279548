import React from 'react';
import News from './News';

interface props {
  children: React.ReactNode;
}

const BlogLayout = ({ children }: props) => {
  return (
    <div>
      <div className="mx-auto space-y-12 max-w-7xl">
        <div className="lg:flex">
          <div className="lg:w-[120px]" />
          <div className="flex-1">{children}</div>
          <News />
        </div>
      </div>
    </div>
  );
};

export default BlogLayout;
