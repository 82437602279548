import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M7 11h2v2H7zm14-5v14a2 2 0 01-2 2H5a2 2 0 01-2-2l.01-14A2 2 0 015 4h1V2h2v2h8V2h2v2h1a2 2 0 012 2zM5 8h14V6H5zm14 12V10H5v10zm-4-7h2v-2h-2zm-4 0h2v-2h-2z" />
  </svg>
);

export default SVG;
