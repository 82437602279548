import React, { useState } from 'react';
import TextField from 'src/components/TextField';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import {
  emailRegex,
  lowercaseRegex,
  uppercaseRegex,
  numericRegex,
  specialCharRegex,
  setAccessToken,
} from '../../utils';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../stores/user/Actions';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../config';
import { identify as addUserIdentityInHeap } from 'src/heap/heap-analytics';
import { IUser } from 'src/stores/user/Reducer';

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .trim()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
  newPassword: Yup.string()
    .trim()
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

interface IData {
  email: string;
  password: string;
  newPassword?: string;
}

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { from } = (location.state as {
    from: {
      pathname: string;
    };
  }) || { from: { pathname: '/' } };

  const [cognitoError, setCognitoError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [showVerifyLink, setShowVerifyLink] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = (cognitoUserObject: any) => {
    const token = cognitoUserObject.signInUserSession.idToken.jwtToken;
    const cognitoPayload = cognitoUserObject.signInUserSession.idToken.payload;

    const user: IUser = {
      username: cognitoPayload['cognito:username'],
      email: cognitoPayload.email,
      email_verified: cognitoPayload.email_verified,
      family_name: cognitoPayload.family_name,
      given_name: cognitoPayload.given_name,
      sub: cognitoPayload.sub,
      role: cognitoPayload['cognito:groups'] || [],
      authCode: cognitoUserObject?.attributes ? cognitoUserObject?.attributes['custom:authCode'] : undefined,
    };

    setAccessToken(token);
    dispatch(loginAction(user));
    addUserIdentityInHeap({
      ...user,
      authCode: undefined,
    });

    history.replace(from);
  };

  const onSubmit = handleSubmit(async (data) => {
    setCognitoError(undefined);

    try {
      const cognitoUserObject = await Auth.signIn(data.email, data.password);

      if (cognitoUserObject.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (showNewPasswordField && data.newPassword) {
          try {
            const user = await Auth.completeNewPassword(
              cognitoUserObject, // the Cognito User Object
              data.newPassword, // the new password
            );

            // at this time the user is logged in if no MFA required
            onSuccess(user);
          } catch (error: any) {
            setCognitoError(error.message ? error.message : error);
          }
        } else {
          setShowNewPasswordField(true);
        }
      } else {
        onSuccess(cognitoUserObject);
      }
    } catch (error: any) {
      setCognitoError(error.message ? error.message : error);
      if (error.code && error.code === 'UserNotConfirmedException') {
        setShowVerifyLink(true);
      }
    }
  });

  return (
    <div className="p-6 lg:px-10 xl:px-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Login</title>
      </Helmet>
      <TyphoonBackdrop open={isSubmitting} />
      <div className="max-w-md mx-auto">
        <h1 className="text-2xl font-semibold mb-6">
          <span className="text-typGreen">Typhoon</span> Login
        </h1>
        <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
          <div className="border-b-2 border-typGrey10 p-6 sm:p-10">
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type="text"
                  name="email"
                  id="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  error={errors.email ? true : false}
                />
                {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
              </div>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  fullWidth
                  variant="outlined"
                  placeholder="Password"
                  error={errors.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password ? (
                  <p className="text-red-500 text-xs italic font-medium mt-1">{errors.password.message}</p>
                ) : (
                  <p className="text-gray-400 text-xs italic font-medium mt-1">
                    Password should contain one lowercase, uppercase, number & special character.
                  </p>
                )}
              </div>
              {showNewPasswordField && (
                <div className="mb-5">
                  <TextField
                    control={control}
                    defaultValue=""
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    id="newPassword"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your new password"
                    error={errors.newPassword ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle newPassword visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.newPassword ? (
                    <p className="text-red-500 text-xs italic font-medium mt-1">{errors.newPassword.message}</p>
                  ) : (
                    <p className="text-gray-400 text-xs italic font-medium mt-1">
                      New password should contain one lowercase, uppercase, number & special character.
                    </p>
                  )}
                </div>
              )}
              {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}
              {showVerifyLink && (
                <Link to="/user/verification" className="text-typGreen underline text-xs italic font-medium mt-1">
                  Verify your email here!
                </Link>
              )}
              <button
                type="submit"
                className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
              >
                LOG IN
              </button>
            </form>
            <Link to="/user/forgot-password" className="text-typGreen font-medium text-xs mt-1">
              Forgot password ?
            </Link>
          </div>
          <div className="text-center p-6">
            <p className="text-white text-sm">
              Don’t have an account? Click here to{' '}
              <Link to="/register" className="text-typGreen">
                Register
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
