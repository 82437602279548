import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal/Modal';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import TyphoonIcon from './icons';
import { Search } from './Search';
import Avatar from '@material-ui/core/Avatar/Avatar';
import { LoginModalView } from './LoginModalView';
import { TyphoonModal } from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../stores/user/Actions';
import { Auth } from 'aws-amplify';
import { hideLoginModalAction } from '../stores/Global/Actions';
import { isAdmin, isSubscriber } from '../utils/acl';
import { s3ResourceUrl } from '../config';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { removeAccessToken } from 'src/utils';
import { getUserLoginStatus, getUserRole } from 'src/stores/user/Selectors';
import { getLoginComponentStatus } from 'src/stores/Global/Selectors';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',
    },
    size: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    padding: {
      padding: theme.spacing(1),
    },
  }),
);

export const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [showSearchComponent, setShowSearchComponent] = useState(false);
  const showLoginComponent = useSelector(getLoginComponentStatus);
  const isLogin = useSelector(getUserLoginStatus);
  const userRole = useSelector(getUserRole);

  const matches = useMediaQuery('(max-width:1024px)');

  //user menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMenuOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuOnClose = () => {
    setMenuAnchorEl(null);
  };
  const handleMenuItemOnClick = (url: string) => {
    history.push(url);
    handleMenuOnClose();
  };

  const handleSearchOnClose = React.useCallback(() => {
    setShowSearchComponent(false);
  }, [setShowSearchComponent]);

  const handleLogout = async () => {
    removeAccessToken();
    await Auth.signOut({ global: true });

    dispatch(logoutAction());
    history.push('/');
  };

  const handleLoginOnClose = React.useCallback(() => dispatch(hideLoginModalAction()), [dispatch]);

  // const showLoginModal = useCallback(() => dispatch(showLoginModalAction()), [dispatch]);

  return (
    <nav className="fixed inset-x-0 z-20 border-b-2 border-typGrey10">
      <div
        className={`relative lg:flex lg:items-center lg:justify-between bg-typHeaderBlack py-3 px-6 overflow-y-hidden lg:overflow-y-visible h-16 transition-all ease-in-out duration-500 lg:px-10 xl:px-16 ${
          isOpen ? 'h-auto' : ''
        }`}
      >
        {/* Logo */}
        <div className="flex items-center justify-between">
          <NavLink onClick={() => setIsOpen(false)} to="/">
            <TyphoonIcon name="typhoon-logo" className="h-8 fill-current text-typGreen" />
          </NavLink>
          <div
            className="p-2 text-gray-500 transition duration-500 ease-in-out border border-transparent rounded cursor-pointer hover:text-white hover:border-white lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <TyphoonIcon name="navbar-open" className="h-6 fill-current" />
            ) : (
              <TyphoonIcon name="navbar-close" className="h-6 fill-current" />
            )}
          </div>
        </div>
        {/* END Logo */}

        {/* links */}
        <div className={`mt-4 ${isOpen ? 'block' : 'hidden'} lg:mt-0 lg:flex lg:space-x-8`}>
          {!isLogin && (
            <NavLink
              onClick={() => setIsOpen(false)}
              exact
              to="/browse"
              className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
            >
              Browse
            </NavLink>
          )}
          <NavLink
            onClick={() => setIsOpen(false)}
            exact
            to="/filmmaker/subscription"
            className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
          >
            Filmmakers
          </NavLink>
          {isLogin && (
            <>
              <NavLink
                onClick={() => setIsOpen(false)}
                exact
                to="/theater"
                className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
              >
                Typhoon Theater
              </NavLink>
            </>
          )}
          <NavLink
            onClick={() => setIsOpen(false)}
            target="_blank"
            to={{ pathname: '/blog/index.html' }}
            rel="noopener noreferrer"
            className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
          >
            Blog
          </NavLink>
          {!isLogin && (
            <>
              <NavLink
                onClick={() => setIsOpen(false)}
                exact
                to="/support"
                className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
              >
                Support
              </NavLink>
              <NavLink
                onClick={() => setIsOpen(false)}
                exact
                to="/contact-us"
                className="block px-4 py-3 mt-4 font-semibold text-white rounded hover:bg-typGrey10 lg:inline-block lg:mt-0 lg:hover:bg-transparent lg:p-0 lg:rounded-none"
              >
                Contact Us
              </NavLink>
            </>
          )}
        </div>
        {/* END links */}

        {/* search bar and login */}
        <div
          className={`mt-4 ${isOpen ? 'block' : 'hidden'} lg:mt-0 lg:flex lg:items-center ${
            isLogin ? 'lg:space-x-4 xl:space-x-6' : 'lg:space-x-3 xl:space-x-5'
          }`}
        >
          {/* Search component */}
          <>
            {/* Show Dialog for lg and xl devices */}
            <div
              className={`absolute right-0 top-0 z-10 mt-3 ${
                isLogin ? 'mr-24 xl:mr-32' : 'mr-32 xl:mr-40'
              } shadow-lg hidden lg:block transition trans ease-in-out duration-500 transform ${
                showSearchComponent ? 'translate-y-0 scale-100' : '-translate-y-6xl scale-0'
              }`}
            >
              {showSearchComponent && <Search onClose={handleSearchOnClose} />}
            </div>
            {/* END Show Dialog for lg and xl devices */}
            {/* Show Dialog for less lg devices */}
            <Modal
              className={classes.modal}
              open={showSearchComponent && matches}
              onClose={handleSearchOnClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showSearchComponent && matches}>
                <Search onClose={handleSearchOnClose} />
              </Fade>
            </Modal>
            {/* END Show Dialog for less lg devices */}
            <div
              className="flex mb-4 overflow-hidden border rounded-lg cursor-pointer border-typGrey10 bg-typBodyBlack lg:mb-0"
              onClick={() => setShowSearchComponent(!showSearchComponent)}
            >
              <div className="flex items-center px-3">
                <TyphoonIcon name="search" className="h-4 fill-current text-typGrey30" />
              </div>
              <div className="w-40 max-w-full py-2 bg-transparent text-typGrey80 placeholder-typGrey10">Search</div>
            </div>
          </>

          {/* END Search component */}
          {/* login component */}
          {isLogin ? (
            <div>
              <IconButton
                aria-label="User menu"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuOnClick}
                className={classes.padding}
              >
                <Avatar alt="avatar" src={`${s3ResourceUrl}/images/user-avatar.jpg`} className={classes.size} />
              </IconButton>
              <Menu id="simple-menu" anchorEl={menuAnchorEl} keepMounted open={isMenuOpen} onClose={handleMenuOnClose}>
                <MenuItem onClick={() => handleMenuItemOnClick('/filmmaker/portal')}>Portal</MenuItem>
                <MenuItem onClick={() => handleMenuItemOnClick('/theater')}>Theater</MenuItem>
                <MenuItem onClick={() => handleMenuItemOnClick('/user/list')}>My List</MenuItem>
                <MenuItem onClick={() => handleMenuItemOnClick('/user/settings')}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            // <button
            //   className="inline-block px-4 py-2 font-semibold transition duration-300 ease-in-out border rounded-lg text-typGreen border-typGreen hover:border-transparent hover:text-black hover:bg-typGreen"
            //   onClick={() => {
            //     setIsOpen(false);
            //     showLoginModal();
            //   }}
            // >
            //   Log in
            // </button>
            <Link
              to="/login"
              className="inline-block px-4 py-2 font-semibold transition duration-300 ease-in-out border rounded-lg text-typGreen border-typGreen hover:border-transparent hover:text-black hover:bg-typGreen"
              onClick={() => setIsOpen(false)}
            >
              Log in
            </Link>
          )}
          {/* END Login component */}
        </div>
        {/* END search bar and login */}

        {/* Start Login Modal  */}
        <TyphoonModal open={showLoginComponent} onClose={handleLoginOnClose}>
          <LoginModalView />
        </TyphoonModal>
        {/* End Login Modal  */}
      </div>
    </nav>
  );
};
