import { useHistory } from 'react-router-dom';
import { TyphoonToaster } from 'src/components/Toastify';
import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { getAccessToken } from 'src/utils';

const deleteMovie = async (id: string) => {
  await Axios.delete(`${API_ENDPOINT_LAMBDA}/movies/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const useDeleteMovie = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteMovie(id), {
    mutationKey: 'DELETE_MOVIE',
    retry: false,
    onSuccess: () => {
      TyphoonToaster('Movie deleted', 'success');
      queryClient.invalidateQueries('portal-stand-alone');
      history.push('/filmmaker/portal');
    },
    onError: () => {
      TyphoonToaster('Error deleting movie', 'danger');
    },
  });
};
