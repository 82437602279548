import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M19.35 10.04a7.5 7.5 0 00-14-2A6 6 0 006 20h13a4.99 4.99 0 00.35-9.96zM14 13v4h-4v-4H7l5-5 5 5z" />
  </svg>
);

export default SVG;
