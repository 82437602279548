import React, { useEffect, useState } from 'react';
import MonthYearSelector from './MonthYearSelector';
import Chart from 'react-apexcharts';
import { Color } from 'src/utils';

export interface IAnalytics {
  movieId: number;
  movieTitle: string;
  watchMinutes?: number;
  globalViewerCount?: number;
}

interface props {
  title: string;
  year: string;
  month: string;
  children: IAnalytics[];
  handleYearChange: (e: string) => void;
  handleMonthChange: (e: string) => void;
}

const initialOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  fill: {
    colors: [Color.Primary],
  },
  xaxis: {
    categories: [''],
    labels: {
      style: {
        colors: [''],
      },
    },
  },
  yaxis: {
    categories: [''],
    labels: {
      style: {
        colors: [''],
      },
    },
    title: {
      text: 'Minutes',
      style: {
        color: '#fff',
        fontSize: '12px',
      },
    },
  },
  tooltip: {
    style: {
      color: '#000',
    },
  },
};

const initialSeries = [{ name: '', data: [0] }];

const Top5MoviesLayout = ({ title, year, month, children, handleMonthChange, handleYearChange }: props) => {
  const [showDate, setShowDate] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const [series, setSeries] = useState(initialSeries);

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: children.map((movie: IAnalytics) => {
          return movie.movieTitle;
        }),
        labels: {
          style: {
            colors: children.map((movie: any) => '#fff'),
          },
        },
      },
      yaxis: {
        ...options.yaxis,
        labels: {
          style: {
            colors: children.map((movie: any) => '#fff'),
          },
        },
      },
    });

    setSeries([
      {
        name: title,
        data: children.map((movie: IAnalytics) => {
          if (movie.watchMinutes) {
            return movie.watchMinutes;
          }
          if (movie.globalViewerCount) {
            return movie.globalViewerCount;
          } else {
            return 0;
          }
        }),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const populateGraph = (children: IAnalytics[], options: any, series: any) => {
    if (children && children.length > 0) {
      return (
        <div className="flex flex-1 w-full h-96 lg:max-w-7xl">
          <Chart id="chartContainer" type="bar" options={options} series={series} height="100%" width="100%" />
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex flex-wrap justify-between">
        <h1 className="mr-8 text-xl">{title}</h1>
        {showDate ? (
          <MonthYearSelector
            handleMonthChange={handleMonthChange}
            handleYearChange={handleYearChange}
            month={month}
            year={year}
          />
        ) : (
          children && children.length > 0 && <button onClick={() => setShowDate(true)}>View by date</button>
        )}
      </div>
      <div className="w-full space-y-4">{populateGraph(children, options, series)}</div>
    </>
  );
};

export default Top5MoviesLayout;
