import { GLOBAL_TYPES } from './Types';

interface IGlobalReducer {
  showLogin: boolean;
}

const InitialState: IGlobalReducer = {
  showLogin: false,
};

const Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case GLOBAL_TYPES.SHOW_LOGIN_VIEW:
      return {
        ...state,
        showLogin: true,
      };
    case GLOBAL_TYPES.HIDE_LOGIN_VIEW:
      return {
        ...state,
        showLogin: false,
      };
    default:
      return state;
  }
};

export const globalReducer = Reducer;
