import { Switch } from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserId } from 'src/stores/user/Selectors';
import { getAccessToken } from 'src/utils';
import { TyphoonBackdrop } from '../../components/Backdrop';
import TyphoonIcon from '../../components/icons';
import { TyphoonModal } from '../../components/Modal';
import { TyphoonToaster } from '../../components/Toastify';
import { API_ENDPOINT_LAMBDA, AppTitle, s3FileUrl } from '../../config';
import { IDeleteMovieFromMyList, IDeleteSeasonFromMyList, IMyListMovies, IMyListSeasons } from '../../types';

const fetchMyListMovies = async (userId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/myList/movies/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};
const fetchMyListSeasons = async (userId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/myList/seasons/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};
const deleteMovieFromMyListMutation = async (data: IDeleteMovieFromMyList) => {
  await Axios.delete(`${API_ENDPOINT_LAMBDA}/myList/movies?userId=${data.userId}&movieId=${data.movieId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};
const deleteSeasonFromMyListMutation = async (data: IDeleteSeasonFromMyList) => {
  await Axios.delete(`${API_ENDPOINT_LAMBDA}/myList/seasons?userId=${data.userId}&seasonId=${data.seasonId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

export const UserMyListPage = () => {
  const [showSeason, setShowSeason] = useState(false);
  const userId = useSelector(getUserId);
  const [showDeleteMovieModal, setShowDeleteMovieModal] = useState(false);
  const [movieValues, setMovieValues] = useState<IDeleteMovieFromMyList>();
  const [showDeleteSeasonModal, setShowDeleteSeasonModal] = useState(false);
  const [seasonValues, setSeasonValues] = useState<IDeleteSeasonFromMyList>();

  const movies = useQuery<IMyListMovies, Error>(['my-list-movies', userId], () => fetchMyListMovies(userId!), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !showSeason && !!userId,
  });

  const seasons = useQuery<IMyListSeasons, Error>(['my-list-seasons', userId], () => fetchMyListSeasons(userId!), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: showSeason && !!userId,
  });

  const queryClient = useQueryClient();

  const deleteMovieMutation = useMutation(deleteMovieFromMyListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['my-list-movies', userId]);
      TyphoonToaster('Movie deleted from your list.', 'success');
    },
  });

  const deleteSeasonMutation = useMutation(deleteSeasonFromMyListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['my-list-seasons', userId]);
      TyphoonToaster('Season deleted from your list.', 'success');
    },
  });

  const handleMovieModalOnClose = React.useCallback(() => setShowDeleteMovieModal(false), []);
  const handleMovieValues = async (data: IDeleteMovieFromMyList) => {
    setShowDeleteMovieModal(true);
    setMovieValues(data);
  };
  const handleDeleteMovieRequest = async () => {
    if (movieValues) {
      await deleteMovieMutation.mutateAsync(movieValues);
      setMovieValues(undefined);
      setShowDeleteMovieModal(false);
    }
  };

  const handleSeasonModalOnClose = React.useCallback(() => setShowDeleteSeasonModal(false), []);
  const handleSeasonValues = async (data: IDeleteSeasonFromMyList) => {
    setShowDeleteSeasonModal(true);
    setSeasonValues(data);
  };
  const handleDeleteSeasonRequest = async () => {
    if (seasonValues) {
      await deleteSeasonMutation.mutateAsync(seasonValues);
      setSeasonValues(undefined);
      setShowDeleteSeasonModal(false);
    }
  };

  const handleSeasonTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSeason(event.target.checked);
  };

  return (
    <div className="">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>My List</title>
      </Helmet>
      <TyphoonBackdrop open={movies.isFetching || seasons.isFetching} />
      <div className="flex flex-wrap items-center justify-between mb-6 -m-3">
        <h1 className="m-3 text-2xl font-medium text-typGreen">
          My
          <span className="text-white"> List</span>
        </h1>
        <div className="flex items-center m-3">
          <span className={`font-semibold ${showSeason ? 'text-white' : 'text-typGreen'}`}>Movies</span>
          <Switch
            checked={showSeason}
            onChange={handleSeasonTypeChange}
            color="secondary"
            name="libraryView"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <span className={`font-semibold ${showSeason ? 'text-typGreen' : 'text-white'}`}>Seasons</span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-2 sm:gap-x-3 md:grid-cols-3 lg:grid-cols-4">
        {showSeason ? (
          <>
            {seasons.data && seasons.data.seasons.length > 0
              ? seasons.data.seasons.map((season) => (
                  <div key={season.details.id} className="space-y-3 focus:outline-none">
                    <div className="aspect-ratio--16x9">
                      <div className="overflow-hidden aspect-ratio__inner-wrapper rounded-2xl">
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${season.details.thumbnail}`}
                          alt={season.details.title}
                        />
                        <span
                          className="absolute top-0 right-0 p-1 mt-1 mr-1 bg-red-600 rounded-full cursor-pointer"
                          onClick={() => {
                            if (userId) {
                              handleSeasonValues({ userId, seasonId: season.details.id });
                            }
                          }}
                        >
                          <TyphoonIcon name="delete" className="h-6 text-white fill-current" />
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="font-medium">{season.details.title}</h6>
                      <Link
                        to={`/seasons/${season.details.id}`}
                        className="inline-block px-6 py-3 font-bold text-black rounded-lg bg-typGreen"
                      >
                        Watch
                      </Link>
                    </div>
                    {season.isRented && (
                      <p>
                        <span className="font-semibold text-typGreen">Expire :</span>{' '}
                        {moment(new Date(season.expiryDate)).fromNow()}
                      </p>
                    )}
                  </div>
                ))
              : !seasons.isLoading && <p>You don't have any seasons.</p>}
          </>
        ) : (
          <>
            {movies.data && movies.data.movies.length > 0
              ? movies.data.movies.map((movie) => (
                  <div key={movie.details.id} className="space-y-3 focus:outline-none">
                    <div className="aspect-ratio--16x9">
                      <div className="overflow-hidden aspect-ratio__inner-wrapper rounded-2xl">
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${movie.details.thumbnail}`}
                          alt={movie.details.title}
                        />
                        <span
                          className="absolute top-0 right-0 p-1 mt-1 mr-1 bg-red-600 rounded-full cursor-pointer"
                          onClick={() => {
                            if (userId) {
                              handleMovieValues({ userId, movieId: movie.details.id });
                            }
                          }}
                        >
                          <TyphoonIcon name="delete" className="h-6 text-white fill-current" />
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="font-medium">{movie.details.title}</h6>
                      <Link
                        to={`/movies/${movie.details.id}`}
                        className="inline-block px-6 py-3 font-bold text-black rounded-lg bg-typGreen"
                      >
                        Watch
                      </Link>
                    </div>
                    {movie.isRented && (
                      <p>
                        <span className="font-semibold text-typGreen">Expire :</span>{' '}
                        {moment(new Date(movie.expiryDate)).fromNow()}
                      </p>
                    )}
                  </div>
                ))
              : !movies.isLoading && <p>You don't have any movies.</p>}
          </>
        )}
      </div>

      {/* Start Season Modal */}
      <TyphoonModal page={null} open={showDeleteSeasonModal} onClose={handleSeasonModalOnClose}>
        <div>
          <TyphoonBackdrop open={deleteSeasonMutation.isLoading} />
          <div className="p-8">
            <div className="relative mt-4 mb-2">
              <div className="pb-8 text-lg font-medium text-white">
                <p>
                  Are you sure you want to <span className="text-typGreen">REMOVE</span> this season from your list?
                </p>
              </div>
              <div className="flex justify-end space-x-5">
                <button
                  onClick={() => {
                    setSeasonValues(undefined);
                    setShowDeleteSeasonModal(false);
                  }}
                  className="w-32 py-3 font-medium border rounded-md text-typGreen black border-typGreen focus:outline-none"
                >
                  No
                </button>
                <button
                  onClick={handleDeleteSeasonRequest}
                  className="w-32 py-3 font-medium text-black border rounded-md border-typGreen bg-typGreen focus:outline-none"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </TyphoonModal>
      {/* End Season Modal */}
      {/* Start Movie  Modal */}
      <TyphoonModal page={null} open={showDeleteMovieModal} onClose={handleMovieModalOnClose}>
        <div>
          <TyphoonBackdrop open={deleteMovieMutation.isLoading} />
          <div className="p-8">
            <div className="relative mt-4 mb-2">
              <div className="pb-8 text-lg font-medium text-white">
                <p>
                  Are you sure you want to <span className="text-typGreen">REMOVE</span> this movie from your list?
                </p>
              </div>
              <div className="flex justify-end space-x-5">
                <button
                  onClick={() => {
                    setMovieValues(undefined);
                    setShowDeleteMovieModal(false);
                  }}
                  className="w-32 py-3 font-medium border rounded-md text-typGreen black border-typGreen focus:outline-none"
                >
                  No
                </button>
                <button
                  onClick={handleDeleteMovieRequest}
                  className="w-32 py-3 font-medium text-black border rounded-md border-typGreen bg-typGreen focus:outline-none"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </TyphoonModal>
      {/* End Movie Modal */}
    </div>
  );
};
