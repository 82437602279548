import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M3 9v6h4l5 5V4L7 9zm14 3a5 5 0 00-3-4v8a4 4 0 003-4zm-3-9v2a7 7 0 010 14v2a9 9 0 000-18z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

export default SVG;
