import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import NewsCreate from './NewsCreate';
import { isAdmin } from 'src/utils/acl';
import Axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { INews, INewsData } from 'src/types';
import Newsletter from './Newsletter';
import { usePopupState, bindTrigger, bindMenu, bindPopper } from 'material-ui-popup-state/hooks';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import TyphoonIcon from 'src/components/icons';
import { useHistory } from 'react-router-dom';
import { TyphoonToaster } from 'src/components/Toastify';
import { getAccessToken, getQueryParams } from 'src/utils';
import { TyphoonModal } from 'src/components/Modal';
import { NewsEdit } from './NewsEdit';
import useDebounce from '../hooks/useDebounce';
import Pagination from './Pagination';
import Popper from '@material-ui/core/Popper';
import { getUserRole } from 'src/stores/user/Selectors';

interface IQueryData {
  pageSize: number;
  pageNumber: number;
}
const fetchNews = async (queryData: IQueryData) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/news?${getQueryParams(queryData)}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data.data;
};

const News = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [editableNews, setEditableNews] = useState<INewsData>();
  const [removeNews, setRemoveNews] = useState<INewsData>();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);
  const debouncedQuery = useDebounce<IQueryData>({ pageSize, pageNumber });
  const userRole = useSelector(getUserRole);

  const news = useQuery<INews, Error>(
    ['news-List', debouncedQuery],
    async (context) => {
      return await fetchNews((context.queryKey[1] as unknown) as IQueryData);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handleRemoveNews = async (id: string) => {
    try {
      await Axios.delete(`${API_ENDPOINT_LAMBDA}/news/${id}`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      queryClient.invalidateQueries(['news-List', debouncedQuery]);
      history.push('/blog');
      TyphoonToaster('News deleted.', 'success');
    } catch (error) {}
  };

  const handleRemoveModalOnClose = useCallback(() => {
    setRemoveNews(undefined);
    setShowRemoveModal(false);
  }, []);

  const handleEditNews = async (news: INewsData) => {
    console.log('🚀 ~ file: index.tsx ~ line 101 ~ handleEditUser ~ user', news);
    setEditableNews(news);
    setShowModal(true);
  };

  const handleModalOnClose = useCallback(() => {
    setEditableNews(undefined);
    setShowModal(false);
  }, []);

  const popupState: any = usePopupState({
    variant: 'popover',
    popupId: 'demo-popup-menu',
  });

  return (
    <div className="max-w-full p-2 mt-4 space-y-5 lg:max-w-xs">
      <h1 className="mb-6 text-4xl font-black text-center text-typGreen">NEWS</h1>
      {isAdmin(userRole) && (
        <div>
          <NewsCreate />
        </div>
      )}
      <div>
        {news.data && news.data.news && news.data.news?.length > 0 ? (
          news.data.news.map((v: INewsData, i: number) => (
            <div key={i} className="p-4 mb-6 bg-typHeaderBlack">
              <div className="flex justify-between">
                <h2 className="mb-2 text-xl">{v.title}</h2>
                {isAdmin(userRole) ? (
                  <Popper {...bindPopper(popupState)} transition>
                    <Button {...bindTrigger(popupState)}>
                      <TyphoonIcon name="list" className="w-5 h-5 text-gray-400 fill-current focus:outline-none" />
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={async () => {
                          await handleEditNews(v);
                          popupState.close();
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          setRemoveNews(v);
                          setShowRemoveModal(true);
                          popupState.close();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Popper>
                ) : (
                  ''
                )}
              </div>
              <div className="text-sm">{v.description}</div>
            </div>
          ))
        ) : (
          <div>
            {news.isLoading ? (
              <div className="flex items-center justify-center">
                <CircularProgress color="primary" />
              </div>
            ) : (
              <p className="text-sm text-gray-200">No news available.</p>
            )}
          </div>
        )}
      </div>
      <div className="p-4 md:p-0">
        {news.data && news.data.paginationInfo && (
          <Pagination
            paginationInfo={news.data.paginationInfo}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
          />
        )}
      </div>
      <div className="flex justify-center">
        <Newsletter />
      </div>
      {editableNews && (
        <TyphoonModal open={showModal} onClose={handleModalOnClose}>
          <NewsEdit news={editableNews} onClose={handleModalOnClose} />
        </TyphoonModal>
      )}
      {removeNews && (
        <TyphoonModal open={showRemoveModal} onClose={handleRemoveModalOnClose}>
          <div className="p-6 w-96">
            <h1 className="text-2xl font-semibold text-center text-typGreen">Remove News</h1>
            <div className="relative mt-4 mb-2">
              <h1 className="pb-4 text-lg font-medium text-center text-white">
                Are you sure you want to remove
                <span className="ml-2 font-semibold text-typGreen">{removeNews.title}</span>?
              </h1>
              <div className="flex justify-end pt-4">
                <button
                  onClick={handleRemoveModalOnClose}
                  className="px-6 py-2 mr-4 text-red-500 border-2 border-red-500 rounded-md"
                >
                  No
                </button>
                <button
                  onClick={async () => {
                    handleRemoveNews(removeNews.id);
                    handleRemoveModalOnClose();
                  }}
                  className="px-6 py-2 mr-4 border-2 border-green-500 rounded-md text-typGreen"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </TyphoonModal>
      )}
    </div>
  );
};

export default News;
