import React from 'react';
import { NavLink } from 'react-router-dom';
import TyphoonIcon from './icons';

export const Footer = () => {
  return (
    <footer className="bg-typHeaderBlack">
      <div className="flex flex-col items-center py-4 space-y-8 sm:py-10 sm:flex-row sm:justify-around sm:items-stretch sm:space-y-0">
        <div className="flex flex-col items-center space-y-2 text-white lg:flex-row lg:space-y-0 lg:justify-evenly lg:w-5/12">
          <NavLink
            to={{ pathname: '/advertise/index.html' }}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block hover:underline">
            Advertise
          </NavLink>
          <NavLink className="inline-block hover:underline" to="/support">
            Support
          </NavLink>
          <NavLink className="inline-block hover:underline" to="/about">
            About Us
          </NavLink>
        </div>
        <div className="flex items-center justify-center lg:w-2/12">
          <NavLink className="inline-block" to="/">
            <TyphoonIcon name="typhoon-logo" className="h-10 fill-current text-typGreen" />
          </NavLink>
        </div>
        <div className="flex flex-col items-center space-y-2 text-white lg:flex-row lg:space-y-0 lg:justify-evenly lg:w-5/12">
          <NavLink className="inline-block hover:underline" to="/contact-us">
            Contact Us
          </NavLink>
          <NavLink className="inline-block hover:underline" to="/terms-and-conditions">
            Terms & Conditions
          </NavLink>
          <NavLink className="inline-block hover:underline" to="/privacy-policy">
            Privacy Policy
          </NavLink>
        </div>
      </div>
      <hr className="border-typGrey10" />
      <div className="flex flex-col items-center py-4 space-y-4 sm:py-10 lg:space-y-6">
        <div className="flex space-x-3">
          <NavLink
            to={{ pathname: 'https://www.facebook.com/profile.php?id=61551470226950' }}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block p-3 rounded-full"
            style={{ background: '#1777F2' }}
          >
            <TyphoonIcon name="facebook" className="h-5 text-white fill-current" />
          </NavLink>
          <NavLink
            to={{ pathname: ' https://www.instagram.com/typhoonmike01/' }}
            target="_blank"
            rel="noopener"
            className="inline-block p-3 rounded-full"
            style={{ background: 'linear-gradient(45deg, #FFD600 8.39%, #FF0100 50%, #D800B9 91.61%)' }}
          >
            <TyphoonIcon name="instagram" className="h-5 text-white fill-current" />
          </NavLink>
          <NavLink
            to={{ pathname: 'https://www.youtube.com/channel/UCufIQEvtsYGPWBcBGyXZ_4Q' }}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block p-3 bg-white rounded-full"
          >
            <TyphoonIcon name="youtube" className="h-5 text-red-600 fill-current" />
          </NavLink>
        </div>
        <p className="text-sm text-elecBlue">© Typhoon {new Date().getFullYear()}. We love our users!</p>
      </div>
    </footer>
  );
};
