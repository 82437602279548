import React, { useState } from 'react';
import { IStandAloneMovie, IStandAloneMovieStep3 } from '../../types';
import { TyphoonBackdrop } from '../Backdrop';
import { API_ENDPOINT_LAMBDA, config } from '../../config';
import { getAccessToken, truncateReverse } from '../../utils';
import TyphoonIcon from '../icons';
import { LinearProgress } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { useQueryClient, useMutation } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import Axios from 'axios';

const fileExtType = ['mp4'];

const updateMovieMutation = async (data: { body: IStandAloneMovieStep3; id: IStandAloneMovie['id'] }) => {
  await Axios.put(`${API_ENDPOINT_LAMBDA}/movies/${data.id}`, data.body, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

interface props {
  data: IStandAloneMovie;
  onClose: () => void;
}

export const EditMovieStep3: React.FC<props> = ({ data, onClose }) => {
  // loading
  const [isTrailerLoading, setIsTrailerLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // progress
  const [trailerProgress, setTrailerProgress] = useState(0);

  // hover on drop
  const [isTrailerHoverOnDrop, setTrailerHoverOnDrop] = useState(false);

  // file
  const [trailer, setTrailer] = useState<File>();

  // errors
  const [trailerError, setTrailerError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const queryClient = useQueryClient();

  const movieMutation = useMutation(updateMovieMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portal-stand-alone', data.id.toString()]);
    },
  });

  const handleChangeTrailerFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrailer(undefined);
    if (event.target.files) {
      const file = event.target.files[0];
      const extType = file.name.split('.').pop();
      if (file && extType) {
        if (!fileExtType.includes(extType.toLocaleLowerCase())) {
          setTrailerError('Only mp4 is allowed.');
          return;
        } else {
          setTrailer(file);
          setTrailerError(undefined);
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (trailer && data) {
      try {
        setTrailerProgress(0);

        let values: IStandAloneMovieStep3 = {};

        const trailerType = trailer.name.split('.').pop();
        setIsTrailerLoading(true);
        const trailerResponse = (await Storage.put(`${data.id}/trailer.${trailerType}`, trailer, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setTrailerProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        values.trailer = trailerResponse.key;
        setIsTrailerLoading(false);

        setIsSubmitting(true);
        await movieMutation.mutateAsync({ body: values, id: data.id });
        TyphoonToaster('Trailer uploaded.', 'success');
        onClose();
      } catch (error: any) {
        console.log(error.response);
        setSubmitError('Unable to Edit!!');
      }
      setIsSubmitting(false);
    } else {
      setSubmitError('Upload at least one new content!!');
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-semibold text-center mb-6">
        <span className="text-typGreen">Edit</span> Content STEP - 3
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
        <TyphoonBackdrop open={isSubmitting} />
        <div className="mb-5">
          <div
            className={`border ${isTrailerHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
              trailerError ? 'border-red-500' : ''
            } bg-typBodyBlack rounded-md`}
            onDragEnter={() => {
              setTrailerHoverOnDrop(true);
            }}
            onDragLeave={() => {
              setTrailerHoverOnDrop(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setTrailerHoverOnDrop(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              setTrailerHoverOnDrop(false);
              setTrailer(undefined);
              const files = Array.from(e.dataTransfer.files);
              const extType = files[0].name.split('.').pop();
              if (files.length > 1) {
                setTrailerError('Drop only One file.');
              } else if (files.length === 1 && extType) {
                if (!fileExtType.includes(extType.toLocaleLowerCase())) {
                  setTrailerError('Only mp4 is allowed.');
                  return;
                } else {
                  setTrailer(files[0]);
                  setTrailerError(undefined);
                  return;
                }
              }
            }}
          >
            <input
              accept="video/*"
              className="hidden"
              id="browse-trailer-file-button"
              type="file"
              onChange={handleChangeTrailerFile}
            />
            <label htmlFor="browse-trailer-file-button">
              {trailer ? (
                <div className="flex justify-start items-center p-6 cursor-pointer">
                  <div>{truncateReverse(trailer.name, 30)}</div>
                </div>
              ) : data.trailer ? (
                <div className="flex justify-start items-center p-6 cursor-pointer">
                  <div>{truncateReverse(data.trailer, 30)}</div>
                </div>
              ) : (
                <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                  <TyphoonIcon name="upload" className="fill-current text-typGreen h-10" />
                  <p className="text-typGreen text-sm">
                    Drop or Click here to upload <span className="font-semibold text-base">Trailer</span>
                  </p>
                </div>
              )}
            </label>
          </div>
          {isTrailerLoading && (
            <div className="flex items-center mt-1">
              <div className="flex-grow">
                <LinearProgress variant="determinate" value={trailerProgress} />
              </div>
              <p className="text-typGreen text-sm ml-2 font-semibold">{`${trailerProgress}%`}</p>
            </div>
          )}
          {trailerError && <p className="text-red-500 text-xs italic font-medium mt-1">{trailerError}</p>}
        </div>

        {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
        <button
          onClick={handleSubmit}
          disabled={isSubmitting || isTrailerLoading}
          className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
            isSubmitting || isTrailerLoading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
          }`}
        >
          Edit
        </button>
      </div>
    </div>
  );
};
