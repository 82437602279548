import React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../config';

function TermsAndConditions() {
  return (
    <div className="bg-typDarkBlue p-6 lg:px-10 xl:px-16 sm:py-12">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Terms and Conditions</title>
      </Helmet>
      <div className="max-w-3xl mx-auto mb-32">
        <h1 className="text-center text-3xl font-semibold mb-5">Terms and Conditions</h1>
        <div className="bg-gray-900 border-2 border-typGrey10 rounded-md p-6 sm:p-10">
          <p className="text-justify mb-8">
            Welcome to the terms of use for the Typhoon On Demand Program Site. Sage V-Tech LLC, and/or its affiliates
            ("Typhoon" or "us") provide the Program Site to you (if using or registering as an individual) or the entity
            you represent (if using or registering as a business) ("you") subject to the following terms of use ("Terms
            of Use") in connection with your use of the Program Site. By visiting a Program Site or registering as a
            user of a Program Site, you accept the Terms of Use and represent that you have the authority to bind
            yourself or the company you represent to the Terms of Use and the Typhoon License Agreement. Please read
            these Terms of Use carefully, together with all other rules and policies related to such Program Site
            (including but not limited to the Typhoon.com Conditions of Use and the Typhoon.com Privacy Notice).
          </p>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">No Unauthorized, Unlawful or Harmful Use of the Program Site</h1>
            <p className="text-justify">
              The Program Site is only for your use or the use of those authorized by you to access the Program Site on
              your behalf and you are only authorized to use it in connection with Typhoon On Demand as determined by
              your account status. Typhoon grants you a limited, revocable license to access and use the Program Site in
              the course of participating in The Filmmaker Subscription Program. You may not use the Program Site for
              any other commercial or non-commercial purposes or in any way that:{' '}
            </p>
            <p>
              1. Is unlawful or harms Typhoon, its customers, suppliers or other parties, as determined at Typhoon's
              sole discretion.
            </p>
            <p>2. Breaches any agreement, policy or other notice applicable to the Program Site.</p>
            <p>
              3. Could damage, disable, overburden, or impair the Program Site (or any network(s) connected to the
              Program Site) or interfere with any other party’s use of the Program Site.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Your Account; Changes to Account Settings; User Permissions</h1>
            <p className="text-justify">
              By using the Program Site, you confirm that you are at least 18 years old (or the age of majority where
              you reside, whichever is older). You are responsible for maintaining the confidentiality of your account
              and password and for restricting access to your computer. We may provide the ability for you to grant
              additional users access to your account under separate usernames and passwords. You agree that you are
              responsible for all activities that occur under your account or password, including all activities that
              occur under any username you grant access to your account. You will ensure that all users granted access
              to your account comply with these Terms of Use, together with all other rules and policies related to the
              Program Site in use. If you make changes to your settings or user permissions within a Program Site, it
              may take some time for our system to process the change and you acknowledge that those changes may not
              take effect immediately.{' '}
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Changes to these Terms of Use and the Program Site</h1>
            <p className="text-justify">
              We may, in our discretion, make changes to these Terms of Use without notice to you. You are responsible
              for reviewing the revised Terms of Use and/or any applicable postings.
            </p>
            <p>
              CONTINUED USE OF THE PROGRAM SITE OR PARTICIPATION IN THE PROGRAM AFTER SUCH CHANGES OR POSTINGS WILL
              CONSTITUTE YOUR ACCEPTANCE OF THE CHANGES OR POSTINGS. We may change the Program Site or delete any
              features or functionality, or any aspect of the services, in any way at any time for any reason.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Materials You Post or Provide; Communications Monitoring</h1>
            <p className="text-justify">
              For information and materials you post or otherwise provide to Typhoon via a Program Site that is not
              subject to the terms of a license agreement with Typhoon (a "Submission"), you otherwise grant Typhoon and
              its affiliates a non-exclusive, royalty-free, perpetual, irrevocable license to (1) use, copy, distribute,
              transmit, make available, publicly display, publicly perform, reproduce, edit, adapt, modify, translate,
              reformat and create derivative works of your Submission in connection with the Program Site, and (2)
              sublicense these rights, to the maximum extent permitted by applicable law. Typhoon may remove your
              Submission at any time. For each Submission you provide, you represent that you have all rights necessary
              for you to grant us the rights provided in this section.
            </p>
            <p>
              You acknowledge that we will communicate with you primarily via email or by posting notices on the Program
              Site. You agree that all agreements, notices, disclosures and any other communications that we provide to
              you electronically satisfy any legal requirements that such communications be in writing. To the maximum
              extent permitted by applicable law, Typhoon may monitor any electronic communications you make in
              connection with the Program Site and may disclose such information in the event it has a good faith reason
              to believe it is necessary for purposes of ensuring your compliance with this Agreement, and protecting
              the rights, property, and interests of Typhoon or any third party. You consent to us sending you emails
              relating to Typhoon On Demand and other publishing opportunities from time to time. This takes precedence
              over any directions you may have given us, including through another Typhoon website. You may opt out of
              these communications at any time.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">No Warranties</h1>
            <p className="text-justify">
              Typhoon PROVIDES EACH PROGRAM SITE "AS IS," "WITH ALL FAULTS" AND "AS AVAILABLE." YOU EXPRESSLY AGREE THAT
              USE OF THE PROGRAM SITE IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Typhoon
              MAKES NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESSED OR IMPLIED, AS TO THE OPERATION OF THE
              PROGRAM SITE OR THE INFORMATION, CONTENT, OR MATERIALS INCLUDED ON THE PROGRAM SITE AND DISCLAIMS ANY AND
              ALL WARRANTIES OR CONDITIONS, EXPRESSED, STATUTORY AND IMPLIED, INCLUDING WITHOUT LIMITATION (1)
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT,
              ACCURACY, TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT, (2) WARRANTIES OR
              CONDITIONS ARISING THROUGH COURSE OF DEALING OR USAGE OF TRADE, AND (3) WARRANTIES OR CONDITIONS THAT
              ACCESS TO OR USE OF THE PROGRAM SITE WILL BE UNINTERRUPTED OR ERROR-FREE. Typhoon DOES NOT WARRANT THAT
              THE PROGRAM SITE, ITS SERVERS OR E-MAILS SENT ARE ERROR-FREE, WILL OPERATE WITHOUT INTERRUPTIONS OR
              DOWNTIME, OR ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Limitation of Liability</h1>
            <p className="text-justify">
              IN NO EVENT WILL Typhoon BE LIABLE FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM THESE
              TERMS OF USE OR YOUR USE OF A PROGRAM SITE, EVEN IF Typhoon HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1)
              BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) NEGLIGENCE, OR (4) ANY OTHER CAUSE OF ACTION, TO THE
              EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW. WITHOUT LIMITING THE
              GENERALITY OF THE PRECEDING SECTIONS, YOU ACKNOWLEDGE AND AGREE THAT Typhoon IS NOT RESPONSIBLE OR LIABLE
              FOR (1) ANY CONTENT; (2) ANY THIRD PARTY CONDUCT, TRANSMISSIONS OR DATA; (3) ANY VIRUSES OR OTHER
              DISABLING FEATURES THAT AFFECT YOUR ACCESS TO OR USE OF ANY PROGRAM SITE; (4) ANY INCOMPATIBILITY BETWEEN
              A PROGRAM SITE, SERVICES, SOFTWARE AND HARDWARE; OR (5) ANY DELAYS OR FAILURES YOU MAY EXPERIENCE IN
              INITIATING, CONDUCTING OR COMPLETING ANY TRANSMISSIONS OR TRANSACTIONS IN CONNECTION WITH A PROGRAM SITE.
              THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THESE TERMS OF USE APPLY TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Indemnification</h1>
            <p className="text-justify">
              You release us and will indemnify, defend and hold harmless Typhoon and its affiliates, and their
              respective officers, directors, employees, and agents against any claim, loss, damage, settlement, cost,
              expense or other liability (including, without limitation, attorneys’ fees) arising from or related to (a)
              your breach of any representation or warranty or other obligation contained in this Agreement; or (b) any
              Submission (together, "Claims"). You will use counsel reasonably satisfactory to us to defend each Claim.
              If at any time we reasonably determine that any Claim might adversely affect us, we may take control of
              the defense at our expense. You may not consent to the entry of any judgment or enter into any settlement
              without our prior written consent, which may not be unreasonably withheld.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Termination: Access Restriction</h1>
            <p className="text-justify">
              Typhoon On Demand may terminate these Terms of Use, or terminate or suspend your access to the Program
              Site at any time with or without cause. Upon any termination or suspension of these Terms of Use, your
              right to use the Program Site will cease immediately, and you will not be able to retrieve any information
              related to your account. All provisions that reasonably may be construed as surviving these Terms of Use
              will survive the expiration or termination of these Terms of Use.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Disputes</h1>
            <p className="text-justify">
              Any dispute or claim relating in any way to your use of the Program Site, or to any products or services
              sold or distributed by Typhoon or through Typhoon.com will be resolved by binding arbitration, rather than
              in court, except that you may assert claims in small claims court if your claims qualify. The Federal
              Arbitration Act and federal arbitration law apply to this agreement.
            </p>
            <p>
              There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an
              arbitrator can award on an individual basis the same damages and relief as a court (including injunctive
              and declaratory relief or statutory damages), and must follow the terms of these Terms of Use as a court
              would.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Applicable Law</h1>
            <p className="text-justify">
              By using any of the Program Site, you agree that the Federal Arbitration Act, applicable federal law, and
              the laws of the state of Nevada, without regard to principles of conflict of laws, will govern these Terms
              of Use and any dispute of any sort that might arise between you and Typhoon On Demand.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="text-xl font-semibold mb-4">Interpreting the Terms: Assignment</h1>
            <p className="text-justify">
              If any part of these Terms of Use are determined to be invalid or unenforceable pursuant to applicable
              law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable
              provision that most closely matches the intent of the original provision and the remainder of these Terms
              of Use will continue in effect. Typhoon On Demand may assign these Terms of Use, in whole or in part, at
              any time without notice to you. You may not assign these Terms of Use, or assign, transfer or sublicense
              your rights, if any, in the Program Site. The section titles are solely used for the convenience of the
              parties and have no legal or contractual significance.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
