import { yupResolver } from '@hookform/resolvers/yup';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TyphoonBackdrop } from 'src/components/Backdrop';
import { API_ENDPOINT_LAMBDA, config } from 'src/config';
import { IBlogData } from 'src/types';
import { getAccessToken, pictureMimetypeValues, pictureSizeLimit } from 'src/utils';
import * as Yup from 'yup';
import { TyphoonToaster } from '../Toastify';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { Storage } from 'aws-amplify';

interface IBlogFormProps {
  blogData?: IBlogData;
  handleBlogEditFormVisibility?: () => void;
}

const blogValidationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Required'),
  description: Yup.string().trim().required('Required'),
});

const BlogForm = ({ blogData, handleBlogEditFormVisibility }: IBlogFormProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [picture, setPicture] = useState<File>();
  const [pictureError, setPictureError] = useState<string>();
  const [description, setDescription] = useState('');

  const {
    reset,
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<IBlogData>({
    mode: 'all',
    resolver: yupResolver(blogValidationSchema),
  });

  const handleChangePictureFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPicture(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      event.target.value = '';
      if (image) {
        if (!pictureMimetypeValues.includes(image.type)) {
          setPictureError('Only png, webp, jpg, and jpeg is allowed.');
          return;
        } else {
          setPicture(image);
          setPictureError(undefined);
          return;
        }
      }
    }
  };

  const handleResetPicture = () => {
    setPicture(undefined);
    setPictureError(undefined);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data.description.length === 0) {
        setError('description', { type: 'required', message: 'Description is required' });
        return;
      }

      const blob = new Blob([data.description], { type: 'text/plain' });

      // upload to s3
      const blogDesc = (await Storage.put(`${data.title}-${Date.now()}.txt`, blob, {
        bucket: config.S3.blog.bucket,
        contentType: 'text/plain',
      })) as { key: string };

      data.description = blogDesc.key;

      if (picture) {
        const presignedurlRes = await Axios.get(`${API_ENDPOINT_LAMBDA}/presigned-url`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        });

        await Axios.put(presignedurlRes.data.presigned_url, picture, {
          headers: {
            'Content-Type': picture.type,
          },
        });
        data.imgUrl = presignedurlRes.data.filename;
      }

      if (blogData) {
        await Axios.put(`${API_ENDPOINT_LAMBDA}/blog/${blogData.id}`, data, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        });
      } else {
        await Axios.post(`${API_ENDPOINT_LAMBDA}/blog`, data, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        });
      }
      handleResetPicture();
      reset();
      setDescription('');

      queryClient.invalidateQueries(['blog-published']);
      TyphoonToaster('Submitted successfully.', 'success');
      history.push('/blog');
    } catch (error: any) {
      TyphoonToaster(error?.response?.data?.message || 'Something went wrong!', 'danger');
    }
  });

  console.log(blogData);

  useEffect(() => {
    if (blogData) {
      reset({
        title: blogData.title,
        shortDescription: blogData.shortDescription,
        description: blogData.descriptionInString,
      });
      setDescription(blogData.descriptionInString || '');
    }
  }, [blogData, reset, setValue]);

  useEffect(() => {
    setValue('description', description || '');
  }, [description, setValue]);

  return (
    <div>
      <TyphoonBackdrop open={isSubmitting} />
      <form onSubmit={onSubmit} className="p-4 mx-2 my-4 border-2 rounded-md bg-typHeaderBlack border-typGrey10">
        <div
          className="flex flex-wrap items-center"
          onDrop={(e) => {
            e.preventDefault();
            setPicture(undefined);
            const files = Array.from(e.dataTransfer.files);
            if (files.length > 1) {
              setPictureError('Drop only One file.');
            } else if (files.length === 1) {
              if (!pictureMimetypeValues.includes(files[0].type)) {
                setPictureError('Only png, webp, jpg, and jpeg is allowed.');
                return;
              } else if (files[0].size / 1024 / 1024 > pictureSizeLimit) {
                setPictureError(`Picture should be less then ${pictureSizeLimit} MB`);
                return;
              } else {
                setPicture(files[0]);
                setPictureError(undefined);
                return;
              }
            }
          }}
        >
          <input
            accept="image/*"
            className="hidden"
            id="browse-picture-file-button"
            type="file"
            onChange={handleChangePictureFile}
          />
          {(picture || blogData?.imgUrl) && (
            <img
              className="object-cover border "
              src={picture ? URL.createObjectURL(picture) : blogData?.imgUrl}
              alt={'Banner'}
            />
          )}

          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <label htmlFor="browse-picture-file-button" className="w-full">
              <div className="px-2 py-2 font-medium text-center text-white rounded-lg cursor-pointer bg-primary focus:outline-none">
                Browse
              </div>
            </label>
            {picture && (
              <button
                onClick={handleResetPicture}
                className="px-4 py-2 font-medium bg-gray-200 rounded-lg focus:outline-none"
              >
                Delete
              </button>
            )}
          </div>
        </div>
        {pictureError && <p className="text-sm font-semibold text-red-500">{pictureError}</p>}

        <input
          type="text"
          placeholder="Blog title here..."
          className="w-full py-6 text-5xl bg-transparent focus:outline-none"
          {...register('title')}
        />
        {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}

        <input
          type="text"
          placeholder="Short Description here..."
          className="w-full py-6 bg-transparent focus:outline-none"
          {...register('shortDescription')}
        />
        {errors.shortDescription && (
          <p className="mt-1 text-xs italic font-medium text-red-500">{errors.shortDescription.message}</p>
        )}

        <SunEditor
          autoFocus={true}
          width="100%"
          height="200px"
          setOptions={{
            buttonList: [
              // default
              ['undo', 'redo'],
              ['font', 'fontSize', 'fontColor', 'bold', 'italic', 'list'],
              ['link', 'image'],
              // ['fullScreen'],
            ],
          }}
          setContents={description}
          onChange={setDescription}
        />
        {errors.description && (
          <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
        )}
        <div className="flex justify-between">
          {blogData && (
            <button onClick={handleBlogEditFormVisibility} className="transition text-typGreen/60 hover:text-typGreen">
              Cancel
            </button>
          )}

          <div className="flex justify-end w-full mt-2">
            <button
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setValue('status', 'DRAFT');
              }}
              className="px-4 py-2 mr-4 transition rounded focus:outline-none bg-typGreen/60 hover:bg-typGreen disabled:cursor-not-allowed"
            >
              Draft
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setValue('status', 'PUBLISHED');
              }}
              className="px-4 py-2 transition rounded focus:outline-none bg-typGreen/60 hover:bg-typGreen disabled:cursor-not-allowed"
            >
              Publish
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BlogForm;
