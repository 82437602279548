import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M23.5 6.2a3 3 0 00-2.1-2.08c-1.86-.5-9.39-.5-9.39-.5s-7.5-.01-9.4.5A3 3 0 00.53 6.2 31.25 31.25 0 000 12.02a31.25 31.25 0 00.52 5.78 3 3 0 002.08 2.1c1.87.5 9.4.5 9.4.5s7.5 0 9.4-.5a3 3 0 002.09-2.1 31.25 31.25 0 00.5-5.78 31.25 31.25 0 00-.5-5.8zM9.6 15.6V8.4l6.27 3.61z" />
  </svg>
);

export default SVG;
