import React from 'react';
import { IPaginationInfo } from 'src/types';
import TyphoonIcon from '../icons';

interface IProps {
  paginationInfo: IPaginationInfo;
  pageNumber: number;
  handlePageChange: (page: number) => void;
}

const ContentPagination = ({ paginationInfo, pageNumber, handlePageChange }: IProps) => {
  return (
    <div>
      {paginationInfo && (
        <div>
          <div className="flex space-x-3">
            <button
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={!paginationInfo.previous}
              className={`transition duration-200 ease-in-out border rounded-md border-typGrey30 hover:border-white bg-gray-500/50 focus:outline-none ${
                paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-700'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <TyphoonIcon name="arrow-left" className="h-6 fill-current" />
            </button>
            <button
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={!paginationInfo.next}
              className={`border border-typGrey30 hover:border-white rounded-md bg-gray-500/50  focus:outline-none transition ease-in-out duration-200${
                paginationInfo.next ? 'opacity-100 cursor-pointer hover:bg-gray-700' : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <TyphoonIcon name="arrow-right" className="h-6 fill-current" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentPagination;
