import React from 'react';
import TF, { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { Control, useController } from 'react-hook-form';
import { makeStyles, createStyles } from '@material-ui/core';

type propTypes = {
  name: string;
  control: Control<any>;
  defaultValue?: any;
};
type props = propTypes & TextFieldProps;

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      '& label.Mui-focused': {
        color: '#4b4b4b',
      },
      '& .MuiInputBase-root': {
        backgroundColor: '#292929',
        color: '#ffffff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#4b4b4b',
        },
        '&:hover fieldset': {
          borderColor: '#4b4b4b',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#4b4b4b',
        },
      },
    },
  }),
);

const TextField = ({ name, control, defaultValue, ...props }: props) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const classes = useStyles();

  return <TF className={classes.input} {...props} {...inputProps} inputRef={ref} />;
};

export default TextField;
