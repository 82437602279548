import React, { useRef, useState } from 'react';
import TyphoonIcon from './icons';

interface props {
  title: string | number;
  body: string | number | JSX.Element;
}

const TyphoonAccordion = ({ title, body }: props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState(false);

  return (
    <div
      onClick={() => setSelected((v) => !v)}
      className="bg-typDarkBlue ring-1 ring-gray-300 rounded-md cursor-pointer group"
    >
      <div className="flex justify-between items-center border-b border-gray-400 px-8 py-5">
        <h3 className="font-semibold text-lg">{title}</h3>{' '}
        <span className={`transition transform duration-500 ease-out ${selected ? 'rotate-180' : 'rotate-0'}`}>
          <TyphoonIcon name="arrow-down" className="fill-current text-gray-300 h-8" />
        </span>
      </div>
      <div
        ref={ref}
        className="relative overflow-hidden transition-all duration-500"
        style={{ maxHeight: selected && ref.current ? ref.current.scrollHeight : 0 }}
      >
        <div className="flex p-5">
          <div className="border-2 rounded border-typGreen mr-4"></div>
          <div className="flex-1">{body}</div>
        </div>
      </div>
    </div>
  );
};

export default TyphoonAccordion;
