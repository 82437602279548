import React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../../config';

const PayMe = () => {
  return (
    <div className="max-w-3xl mx-auto mb-32">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Pay Me</title>
      </Helmet>
      <h1 className="text-center text-3xl font-semibold mb-1">Pay Me</h1>
      <p className="text-typGreen font-semibold text-center text-xl mb-5">Typhoon Self-Service Payout System</p>
      <div className="bg-typHeaderBlack text-lg border-2 border-typGrey10 rounded-md p-6 sm:p-10 space-y-4">
        <div>
          <p>Typhoon self-service payout system is due to go live very soon.</p>
          <p>We can't wait!</p>
        </div>
        <div>
          <p>Until then, we are making quarterly payouts to filmmakers directly via PayPal account.</p>
          <p>
            If your PayPal email is different than the one used to sign-up with on Typhoon, Email at{' '}
            <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
              support@typhoonondemand.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayMe;
