import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../../../utils';
import TextField from 'src/components/TextField';
import { Auth } from 'aws-amplify';
import { TyphoonBackdrop } from '../../../components/Backdrop';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TyphoonToaster } from '../../../components/Toastify';

interface IData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
  newPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!')
    .notOneOf([Yup.ref('oldPassword')], 'Should not match with old password!'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Password must be matched with new password!'),
});

export const ChangePasswordPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [cognitoError, setCognitoError] = useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, data.oldPassword, data.newPassword);
      console.log(user);
      reset();
      setCognitoError(undefined);
      TyphoonToaster('Password change successful.', 'success');
    } catch (error: any) {
      console.log('error', error);
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className="">
      <TyphoonBackdrop open={isSubmitting} />
      <h1 className="text-2xl font-semibold mb-6">Change Password</h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
        <div className="p-6 sm:p-10 max-w-xl mx-auto">
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type={showPassword ? 'text' : 'password'}
                name="oldPassword"
                id="oldPassword"
                fullWidth
                variant="outlined"
                placeholder="Old Password"
                error={errors.oldPassword ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle oldPassword visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.oldPassword && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.oldPassword.message}</p>
              )}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                id="newPassword"
                fullWidth
                variant="outlined"
                placeholder="New Password"
                error={errors.newPassword ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle newPassword visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newPassword ? (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.newPassword.message}</p>
              ) : (
                <p className="text-gray-400 text-xs italic font-medium mt-1">
                  Password should contain lowercase, uppercase, number & special characters.
                </p>
              )}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                id="confirmPassword"
                fullWidth
                variant="outlined"
                placeholder="Confirm Password"
                error={errors.confirmPassword ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirmPassword visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.confirmPassword.message}</p>
              )}
            </div>
            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}
            <button
              type="submit"
              className="w-full border font-bold uppercase text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
