import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { USER_TYPES } from './Types';

export type userRoleType = 'ADMIN' | 'M_SUBSCRIBER' | 'Y_SUBSCRIBER' | 'FILMMAKER';

export interface IUser {
  username: string;
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  email_verified: boolean;
  role: userRoleType[];
  authCode?: string;
}

interface IUserReducer {
  user: IUser | null;
}

const InitialState: IUserReducer = {
  user: null,
};

const login = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: action.payload,
  };
};

const logout = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: null,
  };
};

const updateAuthCode = (state: IUserReducer, action: any): IUserReducer => {
  let user = state.user;
  if (user) {
    user.authCode = action.payload;
  }

  return {
    ...state,
    user,
  };
};

const Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case USER_TYPES.LOGIN:
      return login(state, action);
    case USER_TYPES.LOGOUT:
      return logout(state, action);
    case USER_TYPES.AUTH_CODE_UPDATED:
      return updateAuthCode(state, action);
    default:
      return state;
  }
};

// persistor for userReducer
const persistConfig = {
  key: 'userReducer',
  storage: storage,
  whitelist: ['user'],
};

export const userReducer = persistReducer(persistConfig, Reducer);
