import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
  >
    <path d="M446.844 208.875A121.541 121.541 0 00448 192c0-70.563-57.406-128-128-128-40.938 0-78.531 19.344-102.344 51.063A108.881 108.881 0 00192 112c-61.75 0-112 50.25-112 112 0 1.563.031 3.094.094 4.625C33.813 242.375 0 285.313 0 336c0 61.75 50.25 112 112 112h272c70.594 0 128-57.406 128-128 0-46.656-25.656-88.812-65.156-111.125zM384 416H112c-44.188 0-80-35.813-80-80s35.813-80 80-80c2.438 0 4.75.5 7.125.719-4.5-10-7.125-21.031-7.125-32.719 0-44.188 35.813-80 80-80 14.438 0 27.813 4.125 39.5 10.813C246 120.25 280.156 96 320 96c53.031 0 96 42.969 96 96 0 12.625-2.594 24.625-7.031 35.688C449.813 238.75 480 275.688 480 320c0 53.031-42.969 96-96 96z" />
    <path d="M288 192h-64v96h-64l96 96 96-96h-64z" />
  </svg>
);

export default SVG;
