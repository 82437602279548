import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { UploadMovieStep1 } from '../../../../components/uploadForms/UploadMovieStep1';
import { UploadMovieStep2 } from '../../../../components/uploadForms/UploadMovieStep2';
import { UploadMovieStep3 } from '../../../../components/uploadForms/UploadMovieStep3';
import { UploadMovieStep4 } from '../../../../components/uploadForms/UploadMovieStep4';
import { AppTitle } from '../../../../config';

export const UploadStandAloneMovie = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${path}/step1`} />}></Route>
      <Route exact path={`${path}/step1`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Movie Step - 1</title>
        </Helmet>
        <UploadMovieStep1 subpath="stand-alone" stepsLength={4} currentStep={1} title="Upload Stand-alone Content" />
      </Route>
      <Route exact path={`${path}/step2`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Movie Step - 2</title>
        </Helmet>
        <UploadMovieStep2 subpath="stand-alone" stepsLength={4} currentStep={2} title="Upload Stand-alone Content" />
      </Route>
      <Route exact path={`${path}/step3`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Movie Step - 3</title>
        </Helmet>
        <UploadMovieStep3 subpath="stand-alone" stepsLength={4} currentStep={3} title="Upload Stand-alone Content" />
      </Route>
      <Route exact path={`${path}/step4`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Movie Step - 4</title>
        </Helmet>
        <UploadMovieStep4 subpath="stand-alone" stepsLength={4} currentStep={4} title="Upload Stand-alone Content" />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}/step1`} />}></Route>
    </Switch>
  );
};
