import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { TyphoonBackdrop } from '../../components/Backdrop';
import TyphoonIcon from '../../components/icons';
import { MoviesRowSlider } from '../../components/MoviesRowSlider';
import { API_ENDPOINT_LAMBDA, AppTitle, config, s3FileUrl } from '../../config';
import { IAddMovieToMyList, IMyListMovies, IStandAloneMovie } from '../../types';
import { getAccessToken, MovieRatingNames, path_to_vast_vpaid } from '../../utils';
import { TyphoonModal } from '../../components/Modal';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TyphoonToaster } from '../../components/Toastify';
import { Helmet } from 'react-helmet';
import '../../videojs/skins/shaka/videojs.css';
import videojs from 'video.js';
import '../../videojs/components/nuevo.js';
import AWS from 'aws-sdk';
import { getUserId, getUserLoginStatus, getUserRole } from 'src/stores/user/Selectors';
import { showLoginModalAction } from 'src/stores/Global/Actions';
import { useCategories } from '../../components/hooks/useCategory';

AWS.config.update({
  region: 'us-east-1',
  accessKeyId: config.IAM.firehose.accessKeyId,
  secretAccessKey: config.IAM.firehose.secretAccessKey,
});
// const firehose = new AWS.Firehose({ apiVersion: '2015-08-04', region: 'us-east-1' });

const fetchMovie = async (movieId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies/${movieId}/public`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

const addMovieToMyListMutation = async (data: IAddMovieToMyList) => {
  await Axios.post(`${API_ENDPOINT_LAMBDA}/myList/movies`, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

const fetchSingleMovieFromMyList = async (data: { movieId: string; userId: string }) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/myList/movies/${data.userId}?movieId=${data.movieId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data;
};

interface paramProps {
  movieId: string;
}

const SingleMoviePage = () => {
  const dispatch = useDispatch();

  const vidRef = createRef<HTMLVideoElement>();
  const params = useParams<paramProps>();

  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const categories = useCategories();

  let isPlay = query.get('play');

  const videoNode = useRef<HTMLVideoElement>();
  // const currentPlayedTime = useRef<number>();

  const [showInfoModal, setShowInfoModal] = useState(true);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(isPlay === 'true' ? true : false);
  const [isTrailerMuted, setIsTrailerMuted] = useState(true);
  const [isTrailerPlaying, setIsTrailerPlaying] = useState(true);
  const userId = useSelector(getUserId);
  const isLogin = useSelector(getUserLoginStatus);
  const userRole = useSelector(getUserRole);

  const getCategoryName = function(id: string) {
    return categories.data?.find((category) => category.id === id)?.label
  }
  const movie = useQuery<IStandAloneMovie, Error>(['movies', params.movieId], () => fetchMovie(params.movieId), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!params.movieId,
  });

  const myMovieList = useQuery<IMyListMovies, Error>(
    ['check-movie-myList', { movieId: params.movieId, userId }],
    () => fetchSingleMovieFromMyList({ movieId: params.movieId, userId: userId! }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: params.movieId && userId ? true : false,
    },
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(addMovieToMyListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['check-movie-myList', { movieId: params.movieId, userId }]);
      TyphoonToaster('Movie added to your list.', 'success');
    },
  });

  const handleAddToMyList = async () => {
    if (movie.data && userId) {
      await mutation.mutateAsync({
        movieId: typeof movie.data.id === 'number' ? movie.data.id : movie.data.id,
        userId,
      });
    }
  };

  const handleInfoModalOnClose = React.useCallback(() => {
    setShowInfoModal(false);
    setIsTrailerPlaying(true);
    setIsTrailerMuted(true);
  }, []);

  const handleInfoModalOpen = () => {
    setShowInfoModal(true);
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
    handleInfoModalOnClose();
  };
  const handleVideoClose = () => {
    setIsVideoPlaying(false);
  };
  const handleToggleTrailerPlay = () => {
    if (vidRef.current) {
      isTrailerPlaying ? vidRef.current.pause() : vidRef.current.play();
      setIsTrailerPlaying((v) => !v);
    }
  };
  const handleToggleTrailerMute = () => {
    if (vidRef.current) {
      vidRef.current.muted = !isTrailerMuted;
      setIsTrailerMuted((v) => !v);
    }
  };

  const showLoginModal = useCallback(() => dispatch(showLoginModalAction()), [dispatch]);

  // create a script that will be executed
  const script = document.createElement('script');

  useEffect(() => {
    if (isVideoPlaying) {
      script.src = path_to_vast_vpaid;
      document.body.appendChild(script);
    }

    return () => {
      if (isVideoPlaying) {
        document.body.removeChild(script);
      }
    };
  }, [isVideoPlaying, script]);

  useEffect(
    function () {
      if (isVideoPlaying && movie.data && movie.data.hlsUrl && videoNode?.current) {
        // const tracks = movie.data.subtitle
        //   ? movie.data.subtitle.map((v) => {
        //       return {
        //         kind: 'captions',
        //         file: `${s3FileUrl}${v.file}`,
        //         label: v.label,
        //       };
        //     })
        //   : [];

        //? tracks
        const subTitle: any[] | undefined = movie ? (movie.data ? movie.data.subtitle : []) : [];
        const tracks = subTitle
          ? subTitle.map((item: any) => {
              let check = item.file.match('.vtt') ? true : false;
              if (check) {
                let trackObj = {
                  src: `${s3FileUrl}${item.file}`,
                  label: item.label,
                };
                return trackObj;
              } else return null;
            })
          : [];
        const filterTracks: any[] | undefined = tracks.filter(Boolean);

        //? video properties
        const videoJsOptions: videojs.PlayerOptions = {
          controls: true,
          preload: 'auto',
          sources: [
            {
              src: movie.data.hlsUrl,
              type: 'application/x-mpegURL',
            },
          ],
          poster: `${s3FileUrl}${movie.data.banner}`,
          tracks: filterTracks,
        };

        //? initialize Video.js
        (window as any).player = videojs(videoNode.current, videoJsOptions, function onPlayerReady() {
          //? Load Vast/Vpaid plugin and initialize ad
          // if (isFreeUser(userRole)) {
          //   (window as any).videojs = videojs;
          //   const tagURL = `https://s.adtelligent.com/vmap?aid=775037&width=200&height=500&content_page_url=${window.location.href}&cb={cb}`;
          //   script.onload = () => {
          //     // @ts-ignore
          //     (window as any).player.vastAds({
          //       tagURL,
          //     });
          //   };
          // }
        });

        //? Initialize Nuevo plugin
        // @ts-ignore
        window.player.nuevo({
          videoInfo: true,
          infoBold: true,
          infoTitle: movie.data.title,
          infoDescription: movie.data.description,
        });

        // @ts-ignore
        // window.player.on('vast', (e: any, data: { eventType: any }) => {
        //   console.log('vast - eventType', data.eventType);
        //   console.log('vast - event', e);
        // });

        // player.current.on('timeupdate', () => {
        //   const intervalSeconds = 5;
        //   var intPlayedTime = Math.round(player.current?.currentTime() || 0);
        //   let everyFiveSec = intPlayedTime % intervalSeconds === 0 && intPlayedTime !== 0;

        //   //process it only every 5 seconds.
        //   if (
        //     everyFiveSec &&
        //     ((currentPlayedTime.current && intPlayedTime > currentPlayedTime.current) || !currentPlayedTime.current)
        //   ) {
        //     currentPlayedTime.current = intPlayedTime;
        //     console.log('everyFiveSec', intPlayedTime);

        //     let log = {
        //       userId,
        //       movieId: movie.data.id,
        //       movieTitle: movie.data.title,
        //       filmhubSku: movie.data.filmhubSku || '',
        //       timestamp: new Date().toISOString().split('.')[0],
        //       seconds: intervalSeconds,
        //     };

        //     const firehoseParams = {
        //       DeliveryStreamName: 'dev-typhoon-analytics-delivery-stream',
        //       Record: { Data: JSON.stringify(log) + '\n' },
        //     };

        //     firehose.putRecord(firehoseParams, (err, data) => {
        //       if (err) {
        //         console.log('firehose-error', err);
        //       }
        //       console.log('firehose-data', data);
        //     });
        //   }
        // });
      }
      return () => {
        // @ts-ignore
        if (window.player) {
          // @ts-ignore
          window.player.dispose();
          // @ts-ignore
          window.player = undefined;
          handleVideoClose();
        }
      };
    },
    [movie, isLogin, userId, isVideoPlaying, userRole, script],
  );

  return (
    <div className="">
      <TyphoonBackdrop open={movie.isFetching || mutation.isLoading} />
      {!movie.isFetching && !movie.data && (
        <>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>No Movie Found</title>
          </Helmet>
          <p className="p-2 text-red-600 border-b border-typGrey30">No movie found.</p>
        </>
      )}
      {movie.data && (
        <>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>{movie.data.title}</title>
          </Helmet>
          {isVideoPlaying ? (
            <div>
              <div className="flex justify-end px-10 py-5">
                <button className="focus:outline-none" onClick={handleVideoClose}>
                  <TyphoonIcon name="navbar-open" className="h-10 text-white fill-current" />
                </button>
              </div>
              <div className="w-full max-w-4xl mx-auto">
                <div className="aspect-ratio--16x9">
                  <div className="aspect-ratio__inner-wrapper">
                    <div data-vjs-player>
                      <video
                        ref={(node: HTMLVideoElement) => (videoNode.current = node)}
                        className="video-js vjs-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative aspect-w-16 aspect-h-14 sm:aspect-h-9">
              <div className="overflow-hidden aspect-ratio__inner-wrapper">
                <div
                  className="relative w-full h-full bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${s3FileUrl}${movie.data.banner})`,
                  }}
                >
                  <div className="w-full h-full bg-gray-800/25">
                    <div
                      className="flex flex-col justify-center h-full px-6 sm:block sm:max-w-2xl sm:px-10"
                      style={{ paddingTop: '11%' }}
                    >
                      <div className="space-y-2 sm:space-y-3">
                        <h1 className="text-3xl font-bold leading-none sm:text-4xl line-clamp-1 sm:line-clamp-none">
                          {movie.data.title}
                        </h1>
                        <p className="text-sm font-bold sm:font-medium sm:text-base line-clamp-1 sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-4 xl:line-clamp-6">
                          {movie.data.description}
                        </p>
                        {/* <div className="flex flex-wrap -m-1">
                          <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                            {movie.data.isEpisodic ? 'EPISODE' : 'MOVIE'}
                          </div>
                          <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                            {movie.data.isPaid ? 'PAID' : 'FREE'}
                          </div>
                          {movie.data.isPaid && (
                            <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                              {`Purchase : $${(movie.data.price / 100).toFixed(2)}`}
                            </div>
                          )}
                          {movie.data.isRentable && (
                            <div className="px-2 py-1 m-1 text-sm font-medium tracking-wider text-white rounded-full bg-typGreen/25">
                              {`Rent : $${(movie.data.rentalPrice / 100).toFixed(2)} / ${
                                parseInt(movie.data.rentalDays.toString()) > 0
                                  ? `${movie.data.rentalDays} days`
                                  : `${movie.data.rentalDays} day`
                              }`}
                            </div>
                          )}
                        </div> */}
                        <div className="flex space-x-3">
                            <button
                              onClick={handleVideoPlay}
                              className="flex items-center px-4 py-2 space-x-2 font-semibold text-black border-2 rounded-lg bg-typGreen border-typGreen hover:bg-green-400"
                            >
                              <TyphoonIcon name="play" className="h-6 fill-current" />
                              <span>Play</span>
                            </button>
                          <button
                            onClick={handleInfoModalOpen}
                            className="inline-block px-4 py-2 font-semibold text-white rounded-lg bg-gray-500/50 ring-2 ring-white"
                          >
                            More Details
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-x-0 bottom-0 h-8 sm:h-14 md:h-24 lg:h-40 movie-banner-fadeBottom"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="px-6 my-8 space-y-4 lg:px-10 xl:px-16">
            {movie.data.seasonID && movie.data.isEpisodic ? (
              <>
                <MoviesRowSlider title="Episodes" categoryName={movie.data.categoryId} seasonId={movie.data.seasonID} />
                <MoviesRowSlider
                  title="Recommended Series for You"
                  categoryName={movie.data.categoryId}
                  showSeason={true}
                />
              </>
            ) : (
              <MoviesRowSlider title="Recommended Movies for You" categoryName={movie.data.categoryId} />
            )}
          </div>
          {/* Start Information Modal  */}
          <TyphoonModal page={null} open={showInfoModal} onClose={handleInfoModalOnClose}>
            <div className="max-w-3xl bg-typHeaderBlack">
              <div className="relative">
                {movie.data.trailer ? (
                  <video
                    ref={vidRef}
                    autoPlay={true}
                    muted={true}
                    poster={`${s3FileUrl}${movie.data.banner}`}
                    className="w-full"
                  >
                    <source src={`${s3FileUrl}${movie.data.trailer}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={`${s3FileUrl}${movie.data.banner}`} alt={movie.data.title} />
                )}
                <div className="absolute inset-x-0 bottom-0 px-6 -mb-1 movie-modal-fadeBottom">
                  <h1 className="mb-5 text-3xl font-bold leading-none text-white sm:text-5xl">{movie.data.title}</h1>
                  <div className="space-y-3">
                    {movie.data.trailer && (
                      <div className="flex space-x-2">
                        <button
                          className="inline-block p-2 transition duration-300 ease-in-out border-2 rounded-full cursor-pointer bg-typHeaderBlack border-typGrey80 hover:border-white group focus:outline-none"
                          onClick={handleToggleTrailerPlay}
                        >
                          {isTrailerPlaying ? (
                            <TyphoonIcon
                              name="pause"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          ) : (
                            <TyphoonIcon
                              name="play"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          )}
                        </button>
                        <button
                          className="inline-block p-2 transition duration-300 ease-in-out border-2 rounded-full cursor-pointer bg-typHeaderBlack border-typGrey80 hover:border-white group focus:outline-none"
                          onClick={handleToggleTrailerMute}
                        >
                          {isTrailerMuted ? (
                            <TyphoonIcon
                              name="mute"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          ) : (
                            <TyphoonIcon
                              name="unmute"
                              className="h-4 fill-current text-typGrey80 group-hover:text-white"
                            />
                          )}
                        </button>
                      </div>
                    )}
                    <div className="flex items-center justify-between">
                        <button
                          onClick={handleVideoPlay}
                          className="flex items-center px-4 py-1 space-x-2 rounded-md focus:outline-none bg-typGreen hover:bg-green-400"
                        >
                          <TyphoonIcon name="play" className="h-8 text-black fill-current" />
                          <span className="text-xl font-bold">Play</span>
                        </button>
                      {isLogin &&
                        movie.data.isEpisodic === false &&
                        (myMovieList.data?.movies.length === 1 ? (
                          <button
                            type="button"
                            className="px-4 py-2 font-bold text-black transition duration-300 ease-in-out bg-white border-2 border-white rounded-md focus:outline-none"
                          >
                            Added in My List
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={handleAddToMyList}
                            className={`flex items-center space-x-2 px-4 py-2 rounded-md focus:outline-none text-white font-bold border-2 border-white hover:bg-white hover:text-black transition ease-in-out duration-300 ${
                              mutation.isLoading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
                            }`}
                            disabled={mutation.isLoading}
                          >
                            Add to My List
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-6 py-10 space-y-5 text-white sm:space-y-0 sm:space-x-5 sm:flex-row sm:justify-between">
                <div className="space-y-5">
                  <div className="flex space-x-2 font-medium">
                    <span className="px-2 border border-white rounded">{MovieRatingNames[movie.data.ratings]}</span>
                    <span>{movie.data.videoDuration}</span>
                  </div>
                  <div className="space-y-1">
                    <p className={showMoreDescription ? '' : 'line-clamp-1 sm:line-clamp-2 md:line-clamp-3'}>
                      {movie.data.description}
                    </p>
                    <div
                      className="inline-block p-1 text-xs font-medium text-black bg-white rounded cursor-pointer"
                      onClick={() => setShowMoreDescription((prev) => !prev)}
                    >
                      {showMoreDescription ? 'Less Info' : 'More Info'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-2 text-sm">
                  <div>
                    <span className="mr-1 text-gray-500">Cast:</span>
                    <span>{movie.data.castAndCrew}</span>
                  </div>
                  <div>
                    <span className="mr-1 text-gray-500">Category:</span>
                    <span>{getCategoryName(movie.data.categoryId)}</span>
                  </div>
                </div>
              </div>
            </div>
          </TyphoonModal>
          {/* End Information Modal  */}
        </>
      )}
    </div>
  );
};

export default SingleMoviePage;
