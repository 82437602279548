import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = () =>
  Yup.object().shape({
    first: Yup.boolean().oneOf([true], 'You need to read and accept this.'),
    second: Yup.boolean().oneOf([true], 'You need to read and accept this.'),
    third: Yup.boolean().oneOf([true], 'You need to read and accept this.'),
  });

interface IData {
  first: boolean;
  second: boolean;
  third: boolean;
}

interface Props {
  onClose: () => void;
  movieType: 'STAND-ALONE' | 'EPISODIC';
}

const RightsModalView = ({ onClose, movieType }: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IData>({
    defaultValues: {
      first: false,
      second: false,
      third: false,
    },
    resolver: yupResolver(validationSchema()),
  });

  const handleOnAccept = handleSubmit(async (data) => {
    const IsAllChecked = Object.values(data).every((v) => v);

    if (IsAllChecked) {
      onClose();
      if (movieType === 'STAND-ALONE') {
        history.push(`${path}/stand-alone`);
      } else {
        history.push(`${path}/episodic`);
      }
    }
  });

  return (
    <div className="max-w-3xl p-8">
      <form onSubmit={handleOnAccept}>
        <div className="relative mt-4 mb-2 space-y-10">
          <div className="text-white space-y-8">
            <h2 className="text-lg font-medium">By clicking the ACCEPT button below, you acknowledge that:</h2>
            <div className="bg-white text-black rounded-md p-4">
              <div className="flex items-start">
                <Controller
                  name="first"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <div className="pt-2 space-y-2">
                  <p>YOU, THE UPLOADER, OWN FULL RIGHTS TO THE FILM.</p>
                  {errors.first && (
                    <p role="alert" className="text-xs italic font-medium text-red-500">
                      {errors.first?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-start">
                <Controller
                  name="second"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <div className="pt-2 space-y-2">
                  <p>
                    YOU, THE UPLOADER, HAVE LICENSING RIGHTS FOR ANY MUSIC USAGE. Please make sure all licenses are for
                    online usage for all territories. If you have "handshake" agreements with labels or musicians, you
                    MUST obtain formal licenses to pass through Typhoon’s automatic copyright system (or be prepared to
                    reach out to your contacts to clear all copyright claims which may pop up). Nothing escapes
                    Typhoon’s system, so have your paperwork and licensing contacts ready. Online music rights are one
                    of the biggest hurdles, and claims can take up to 30 days to clear - so be prepared.
                  </p>
                  {errors.second && (
                    <p role="alert" className="text-xs italic font-medium text-red-500">
                      {errors.second?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-start mt-3">
                <Controller
                  name="third"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <div className="pt-2 space-y-2">
                  <p>I HAVE READ THE TYPHOON ONBOARDING GUIDE PRIOR TO UPLOADING MY FILM OR SERIES.</p>
                  {errors.third && (
                    <p role="alert" className="text-xs italic font-medium text-red-500">
                      {errors.third?.message}
                    </p>
                  )}
                </div>
              </div>
              <p></p>
            </div>
            <p className="text-sm">
              By making a submission to us, you represent and warrant that: (a) you have the authority to submit the
              film for our consideration; and (b) your submission is made without any condition of secrecy or
              confidentiality; and (c) your submitted film contains no material that (i) infringes upon any third-party
              right and/or (ii) is defamatory, indecent, hate speech, excessive violence, slanderous or libelous, or
              contains any other actionable offenses and/or (iii) violates any applicable law; and (d) you have obtained
              all the necessary rights (including music rights), consents, authorizations and licenses in respect of the
              film; and (e) there is no pending or anticipated litigation regarding the film; and (f) the film is a
              non-fiction/documentary or a fiction/narrative film; and (g) the film must not be in the public domain,
              and must be validly registered for copyright (if applicable). You further agree that you will indemnify
              SageVtech parent company of Typhoon On Demand, and its affiliates, successors, licensees and assigns
              against any and all claims arising out of your breach of any of your representations or warranties
              hereunder.
            </p>
          </div>
          <div className="flex justify-end space-x-5">
            <button
              type="button"
              onClick={onClose}
              className="w-32 py-3 font-medium border rounded-md text-typGreen black border-typGreen focus:outline-none"
            >
              No
            </button>
            <button
              type="submit"
              onClick={handleOnAccept}
              className="w-32 py-3 font-medium text-black border rounded-md border-typGreen bg-typGreen focus:outline-none"
            >
              Yes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RightsModalView;
