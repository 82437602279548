import React from 'react';
import { Helmet } from 'react-helmet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AppTitle } from '../../../config';
import { ChangePasswordPage } from './ChangePassword';
import { UserProfilePage } from './Profile';
import Subscription from './Subscription';
import AuthCode from './AuthCode';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="py-6">{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const UserSettingsPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>My Settings</title>
      </Helmet>
      <h1 className="text-2xl font-medium text-typGreen mb-6">
        My
        <span className="text-white"> Settings</span>
      </h1>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tab"
      >
        <Tab label="Profile" {...a11yProps(0)} style={{ textTransform: 'none', outline: ' none ', color: 'white' }} />
        <Tab
          label="Change Password"
          {...a11yProps(1)}
          style={{ textTransform: 'none', outline: ' none ', color: 'white' }}
        />
        <Tab
          label="Subscription"
          {...a11yProps(2)}
          style={{ textTransform: 'none', outline: ' none ', color: 'white' }}
        />
        <Tab label="Auth Code" {...a11yProps(3)} style={{ textTransform: 'none', outline: ' none ', color: 'white' }} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UserProfilePage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePasswordPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Subscription />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AuthCode />
      </TabPanel>
    </div>
  );
};
