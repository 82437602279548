import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import TextField from 'src/components/TextField';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { TyphoonBackdrop } from '../Backdrop';
import { StepIndicator } from '../StepIndicator';
import { getAccessToken } from '../../utils';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISeason, IStandAloneMovieStep1, IUploadForms } from '../../types';
import { durationRegex, MovieRatingNames, splitTitle } from '../../utils';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA } from '../../config';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import ReactHookFormSelect from '../ReactHookFormSelect';
import { useCategories } from '../hooks/useCategory';
import { getUserId } from 'src/stores/user/Selectors';

interface IData extends Omit<IStandAloneMovieStep1, 'category' | 'ratings'> {
  category: IStandAloneMovieStep1['categoryId'] | '';
  ratings: IStandAloneMovieStep1['ratings'] | '';
}

const validationSchema = (categoryData: any) =>
  Yup.object().shape({
    title: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
    description: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
    videoDuration: Yup.string()
      .trim()
      .required('Required')
      .matches(durationRegex, 'Invalid time format. It should be like HH:MM:SS'),
    categoryId: Yup.string()
      .required('Required')
      .oneOf(categoryData ? categoryData.map((categoryTuple: any) => categoryTuple.id) : []),
    ratings: Yup.string().required('Required').oneOf(['G', 'PG', 'PG-13', 'R', 'NC-17']),
    castAndCrew: Yup.string()
      .trim()
      .required('Required')
      .min(2, 'Should be at least 2 characters long')
      .max(300, 'Should be less then 300 characters'),
  });

const fetchSeason = async (id: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export const UploadMovieStep1: React.FC<IUploadForms> = ({ stepsLength, currentStep, title, subpath }) => {
  const history = useHistory();
  const location = useLocation();
  const queryObject = new URLSearchParams(location.search);
  const seasonID = queryObject.get('seasonID');
  const uploader = useSelector(getUserId);
  const [error] = useState<string>();
  const categories = useCategories();

  useQuery<ISeason, Error>(['check-season', seasonID], () => fetchSeason(seasonID!), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!seasonID,
    onError: () => {
      TyphoonToaster('Season not found!!', 'danger');
      history.push('/filmmaker/portal');
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<IData>({
    defaultValues: {
      title: '',
      description: '',
      videoDuration: '',
      categoryId: '',
      ratings: '',
      castAndCrew: '',
      searchableTitle: '',
      searchableDescription: '',
      searchableCastAndCrew: '',
    },
    resolver: yupResolver(validationSchema(categories.data)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (uploader) {
      try {
        data.searchableTitle = data.title.toLowerCase();
        data.searchableDescription = data.description.toLowerCase();
        data.searchableCastAndCrew = data.castAndCrew.toLowerCase();
        const res = await Axios.post(
          `${API_ENDPOINT_LAMBDA}/movies${seasonID ? `?seasonID=${seasonID}` : ''}`,
          { ...data, uploader },
          {
            headers: {
              'Authorization': `Bearer ${getAccessToken()}`,
              'Content-Type': 'application/json',
            },
          },
        );

        const path =
          subpath === 'stand-alone'
            ? `/filmmaker/upload/${subpath}/step2?movieId=${res.data.id}`
            : `/filmmaker/upload/${subpath}/step3?movieId=${res.data.id}`;

        history.push(path);
      } catch (error: any) {
        console.log(error.response);
      }
    }
  });

  return (
    <div className="max-w-md mx-auto">
      <h1 className="mb-6 text-2xl font-semibold text-center">
        <span className="text-typGreen">{splitTitle(title)[0]}</span> {splitTitle(title)[1]}
      </h1>
      <div className="p-6 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
        <StepIndicator className="mb-8" stepsLength={stepsLength} currentStep={currentStep} />
        <form onSubmit={onSubmit}>
          <TyphoonBackdrop open={isSubmitting} />
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={errors.title ? true : false}
            />
            {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="description"
              fullWidth
              variant="outlined"
              placeholder="Description"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.description ? true : false}
            />
            {errors.description && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
            )}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="videoDuration"
              fullWidth
              variant="outlined"
              placeholder="Duration"
              error={errors.videoDuration ? true : false}
            />
            {errors.videoDuration && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.videoDuration.message}</p>
            )}
          </div>
          <div className="mb-5">
            <ReactHookFormSelect
              name="categoryId"
              variant="outlined"
              error={errors.categoryId ? true : false}
              control={control}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {categories.data?.map((category, i) => (
                <MenuItem key={i} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.categoryId && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.categoryId.message}</p>
            )}
          </div>
          <div className="mb-5">
            <ReactHookFormSelect
              name="ratings"
              variant="outlined"
              error={errors.ratings ? true : false}
              control={control}
            >
              <MenuItem value="" disabled>
                Select Ratings
              </MenuItem>
              {Object.entries(MovieRatingNames).map((rating, i) => (
                <MenuItem key={i} value={rating[0]}>
                  {rating[1]}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.ratings && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.ratings.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="castAndCrew"
              fullWidth
              variant="outlined"
              placeholder="Filmmaker/Cast/Crew"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.castAndCrew ? true : false}
            />
            {errors.castAndCrew && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.castAndCrew.message}</p>
            )}
          </div>

          {error && <p className="mt-1 text-xs italic font-medium text-red-500">{error}</p>}
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
              isSubmitting ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
            }`}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};
