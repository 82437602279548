import React, { useCallback, useState } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ContentPagination from 'src/components/shared/ContentPagination';
import { SkeletonSearchContent } from 'src/components/skeletons/SearchContent';
import { getQueryParams } from 'src/utils';
import TyphoonIcon from '../../../components/icons';
import { API_ENDPOINT_LAMBDA, s3FileUrl } from '../../../config';
import { IPaginationInfo, ISeason, IStandAloneMovie } from '../../../types';

interface IMovie {
  data: IStandAloneMovie[];
  paginationInfo: IPaginationInfo;
}
interface IFeaturedSeason {
  data: ISeason[];
  paginationInfo: IPaginationInfo;
}
interface IQueryData {
  pageSize: number;
  pageNumber: number;
}
const fetchFeaturedMovies = async (queryData: IQueryData) => {
  const res = await Axios.get(
    `${API_ENDPOINT_LAMBDA}/movies?isEpisodic=false&isFeatured=true&${getQueryParams(queryData)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return res.data;
};

const fetchFeaturedSeasons = async (queryData: IQueryData) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons?isFeatured=true&${getQueryParams(queryData)}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export const FilmmakerFeaturedList = () => {
  const [pageSize] = useState(8);
  const [moviesPageNumber, setMoviesPageNumber] = useState(1);
  const [seasonsPageNumber, setSeasonsPageNumber] = useState(1);

  const handleMoviesPageChange = useCallback((page: number) => {
    setMoviesPageNumber(page);
  }, []);

  const handleSeasonsPageChange = useCallback((page: number) => {
    setSeasonsPageNumber(page);
  }, []);

  const movies = useQuery<IMovie, Error>(
    ['portal-featured-movies', moviesPageNumber],
    () => fetchFeaturedMovies({ pageSize, pageNumber: moviesPageNumber }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  const seasons = useQuery<IFeaturedSeason, Error>(
    ['portal-featured-series', seasonsPageNumber],
    () => fetchFeaturedSeasons({ pageSize, pageNumber: seasonsPageNumber }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  return (
    <div className="space-y-12">
      <div>
        <div className="flex mb-6 space-x-4 items-center">
          <h1 className="text-2xl font-medium">
            <span className="text-typGreen">Featured</span> Movies
          </h1>
          <div className="">
            {movies.data && movies.data.paginationInfo && (
              <ContentPagination
                paginationInfo={movies.data.paginationInfo}
                handlePageChange={handleMoviesPageChange}
                pageNumber={moviesPageNumber}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 mb-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {movies.data && movies.data.data.length > 0 ? (
            movies.data.data.map((movie) => (
              <Link to={`/filmmaker/contents/${movie.id}`} key={movie.id}>
                <div className="aspect-ratio--16x9">
                  <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                    <img
                      className="object-cover w-full h-full"
                      src={`${s3FileUrl}${movie.thumbnail}`}
                      alt={movie.title}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-3">
                  <h6 className="font-medium">{movie.title}</h6>
                  <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                </div>
              </Link>
            ))
          ) : movies.isLoading ? (
            <>
              <SkeletonSearchContent className="animate-pulse" />
              <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              <SkeletonSearchContent className="animate-pulse" />
              <SkeletonSearchContent className="animate-pulse" isSmall={true} />
            </>
          ) : (
            <p>No featured movies.</p>
          )}
        </div>
      </div>
      <div>
        <div className="flex mb-6 space-x-4 items-center">
          <h1 className="text-2xl font-medium">
            <span className="text-typGreen">Featured</span> Series
          </h1>
          <div className="">
            {seasons.data && seasons.data.paginationInfo && (
              <ContentPagination
                paginationInfo={seasons.data.paginationInfo}
                handlePageChange={handleSeasonsPageChange}
                pageNumber={seasonsPageNumber}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 mb-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {seasons.data && seasons.data.data.length > 0 ? (
            seasons.data.data.map((season) => (
              <Link to={`/filmmaker/contents/seasons/${season.id}`} key={season.id}>
                <div className="aspect-ratio--16x9">
                  <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                    <img
                      className="object-cover w-full h-full"
                      src={`${s3FileUrl}${season.thumbnail}`}
                      alt={season.title}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-3">
                  <h6 className="font-medium">{season.title}</h6>
                  <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                </div>
              </Link>
            ))
          ) : seasons.isLoading ? (
            <>
              <SkeletonSearchContent className="animate-pulse" />
              <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              <SkeletonSearchContent className="animate-pulse" />
              <SkeletonSearchContent className="animate-pulse" isSmall={true} />
            </>
          ) : (
            <p>No featured series.</p>
          )}
        </div>
      </div>
    </div>
  );
};
