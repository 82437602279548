import React, { useState } from 'react';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { getAccessToken } from 'src/utils';
import { TyphoonBackdrop } from 'src/components/Backdrop';
import { updateAuthCodeAction } from 'src/stores/user/Actions';
import { getUser, getUserLoginStatus } from 'src/stores/user/Selectors';

const AuthCode = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);
  const isLogin = useSelector(getUserLoginStatus);

  const handleGenerateCode = async () => {
    setIsLoading(true);

    try {
      const response = await Axios.post(`${API_ENDPOINT_LAMBDA}/auth/code`, undefined, {
        headers: { Authorization: `Bearer ${getAccessToken()}`, 'X-ID': user?.sub },
      });
      dispatch(updateAuthCodeAction(response.data.authCode));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div>
      <TyphoonBackdrop open={isLoading} />
      <h1 className="text-2xl font-semibold mb-6">Authorization code for Roku and other devices</h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
        <div className="flex justify-center p-6 sm:p-10">
          <div className="space-y-4">
            <h2 className="text-lg font-medium text-typGreen">Authorization Code</h2>
            {isLogin && user?.authCode ? (
              <div className="flex space-x-3 items-center">
                <p className="text-lg font-medium text-white tracking-widest">{user.authCode}</p>
                <button
                  type="button"
                  className="text-white hover:text-black hover:bg-white first-line:font-semibold rounded-md px-2 py-1"
                  onClick={() => handleCopyCode(user.authCode || '')}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 115.77 122.88"
                    xmlSpace="preserve"
                    className="h-6 w-auto fill-current"
                  >
                    <path
                      d="M89.62 13.96v7.73h12.2v.02c3.85.01 7.34 1.57 9.86 4.1 2.5 2.51 4.06 5.98 4.07 9.82h.02v73.3h-.02c-.01 3.84-1.57 7.33-4.1 9.86-2.51 2.5-5.98 4.06-9.82 4.07v.02H40.1v-.02c-3.84-.01-7.34-1.57-9.86-4.1-2.5-2.51-4.06-5.98-4.07-9.82h-.02V92.51h-12.2v-.02c-3.84-.01-7.34-1.57-9.86-4.1-2.5-2.51-4.06-5.98-4.07-9.82H0V13.95h.02c.01-3.85 1.58-7.34 4.1-9.86C6.63 1.59 10.1.03 13.94.02V0H75.67v.02c3.85.01 7.34 1.57 9.86 4.1 2.5 2.51 4.06 5.98 4.07 9.82h.02v.02zm-10.58 7.73v-7.75h.02c0-.91-.39-1.75-1.01-2.37-.61-.61-1.46-1-2.37-1v.02H13.95v-.02c-.91 0-1.75.39-2.37 1.01-.61.61-1 1.46-1 2.37h.02v64.62h-.02c0 .91.39 1.75 1.01 2.37.61.61 1.46 1 2.37 1v-.02h12.2V35.64h.02c.01-3.85 1.58-7.34 4.1-9.86 2.51-2.5 5.98-4.06 9.82-4.07v-.02H79.04zm26.14 87.23V35.63h.02c0-.91-.39-1.75-1.01-2.37-.61-.61-1.46-1-2.37-1v.02H40.09v-.02c-.91 0-1.75.39-2.37 1.01-.61.61-1 1.46-1 2.37h.02v73.3h-.02c0 .91.39 1.75 1.01 2.37.61.61 1.46 1 2.37 1v-.02H101.83v.02c.91 0 1.75-.39 2.37-1.01.61-.61 1-1.46 1-2.37h-.02v-.01z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <p className="text-lg font-medium text-gray-400">No code generated</p>
            )}
            {isLogin && (
              <button
                type="button"
                className="text-black bg-white font-semibold rounded-md px-2 py-1"
                onClick={async () => await handleGenerateCode()}
              >
                {user?.authCode ? 'Regenerate Code' : 'Generate Code'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthCode;
