import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import TextField from 'src/components/TextField';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IStandAloneMovie, IStandAloneMovieStep1, IUploadForms } from '../../types';
import { durationRegex, getAccessToken, MovieRatingNames } from '../../utils';
import { TyphoonBackdrop } from '../Backdrop';
import ReactHookFormSelect from '../ReactHookFormSelect';
import { useQueryClient, useMutation } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA } from '../../config';
import { useCategories } from '../hooks/useCategory';

const validationSchema = (categoryData: any) =>
  Yup.object().shape({
  title: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
  description: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
  videoDuration: Yup.string()
    .trim()
    .required('Required')
    .matches(durationRegex, 'Invalid time format. It should be like HH:MM:SS'),
  categoryId: Yup.string()
    .required('Required')
    .oneOf(categoryData ? categoryData.map((categoryTuple: any) => categoryTuple.id) : []),
  ratings: Yup.string().required('Required').oneOf(['G', 'PG', 'PG-13', 'R', 'NC-17']),
  castAndCrew: Yup.string()
    .trim()
    .required('Required')
    .min(2, 'Should be at least 2 characters long')
    .max(300, 'Should be less then 300 characters'),
});

const updateMovieMutation = async (data: { body: IStandAloneMovieStep1; id: IStandAloneMovie['id'] }) => {
  await Axios.put(`${API_ENDPOINT_LAMBDA}/movies/${data.id}`, data.body, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

interface props {
  data: IStandAloneMovie;
  onClose: () => void;
  subpath: IUploadForms['subpath'];
}

export const EditMovieStep1: React.FC<props> = ({ data, onClose }) => {
  const [error] = useState<string>();
  const queryClient = useQueryClient();
  const categories = useCategories();

  const movieMutation = useMutation(updateMovieMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portal-stand-alone', data.id.toString()]);
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<IStandAloneMovieStep1>({
    mode: 'onChange',
    defaultValues: {
      title: data.title,
      description: data.description,
      videoDuration: data.videoDuration,
      categoryId: data.categoryId,
      ratings: data.ratings,
      castAndCrew: data.castAndCrew,
      searchableTitle: data.searchableTitle,
      searchableDescription: data.searchableDescription,
      searchableCastAndCrew: data.searchableCastAndCrew,
    },
    resolver: yupResolver(validationSchema(categories.data)),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      values.searchableTitle = values.title.toLowerCase();
      values.searchableDescription = values.description.toLowerCase();
      values.searchableCastAndCrew = values.castAndCrew.toLowerCase();
      await movieMutation.mutateAsync({ body: values, id: data.id });
      TyphoonToaster('Information updated.', 'success');
      onClose();
    } catch (error: any) {
      console.log(error.response);
    }
  });

  return (
    <div className="max-w-md mx-auto">
      <h1 className="mb-6 text-2xl font-semibold text-center">
        <span className="text-typGreen">Edit</span> Content STEP - 1
      </h1>
      <div className="p-6 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
        <form onSubmit={onSubmit}>
          <TyphoonBackdrop open={isSubmitting} />
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={errors.title ? true : false}
            />
            {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="description"
              fullWidth
              variant="outlined"
              placeholder="Description"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.description ? true : false}
            />
            {errors.description && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
            )}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="videoDuration"
              fullWidth
              variant="outlined"
              placeholder="Duration"
              error={errors.videoDuration ? true : false}
            />
            {errors.videoDuration && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.videoDuration.message}</p>
            )}
          </div>

          <div className="mb-5">
            <ReactHookFormSelect
              name="categoryId"
              variant="outlined"
              error={!!errors.categoryId ? true : false}
              control={control}
            >
              <MenuItem value="">Select Category</MenuItem>
              {categories.data?.map((category, i) => (
                <MenuItem key={i} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.categoryId && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.categoryId.message}</p>
            )}
          </div>
          <div className="mb-5">
            <ReactHookFormSelect
              name="ratings"
              variant="outlined"
              error={errors.ratings ? true : false}
              control={control}
            >
              <MenuItem value="" disabled>
                Select Ratings
              </MenuItem>
              {Object.entries(MovieRatingNames).map((rating, i) => (
                <MenuItem key={i} value={rating[0]}>
                  {rating[1]}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.ratings && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.ratings.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="castAndCrew"
              fullWidth
              variant="outlined"
              placeholder="Filmmaker/Cast/Crew"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.castAndCrew ? true : false}
            />
            {errors.castAndCrew && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.castAndCrew.message}</p>
            )}
          </div>

          {error && <p className="mt-1 text-xs italic font-medium text-red-500">{error}</p>}
          <button
            type="submit"
            disabled={!isDirty || isSubmitting}
            className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
              !isDirty || isSubmitting ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
            }`}
          >
            Edit
          </button>
        </form>
      </div>
    </div>
  );
};
