import { useSelector } from 'react-redux';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { generateSearchParams, getAccessToken } from 'src/utils';
import { IAnalytics } from 'src/pages/analytics/Top5MoviesLayout';
import { getUserId } from 'src/stores/user/Selectors';

interface ISearchParams {
  month: string;
  year: string;
}

const topContents = async (userId: string, searchParams: ISearchParams) => {
  const response = await Axios.get(
    // todo change userId
    `${API_ENDPOINT_LAMBDA}/analytics/user/${userId}/top5?${
      searchParams.month && searchParams.year && generateSearchParams(searchParams)
    }`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );
  return response.data.data;
};

export const useViewCountWiseAnalytics = (searchParams: ISearchParams) => {
  const userId = useSelector(getUserId);

  return useQuery<IAnalytics[], Error>(
    [`view count wise top 5- ${searchParams.month}-${searchParams.year}`],
    async () => {
      return await topContents(userId!, searchParams);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
};
