import React, { useState } from 'react';
import { useViewCountWiseAnalytics } from 'src/components/hooks/useViewCountWiseAnalytics';
import Top5MoviesLayout from './Top5MoviesLayout';

const ViewCountWise = () => {
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const handleMonthChange = (e: string) => {
    setMonth(e);
  };

  const handleYearChange = (e: string) => {
    setYear(e);
  };

  const movies = useViewCountWiseAnalytics({ month: month, year: year });

  return (
    <div>
      {movies.data && (
        <Top5MoviesLayout
          title="By view counts"
          year={year}
          month={month}
          handleYearChange={handleYearChange}
          handleMonthChange={handleMonthChange}
        >
          {movies.data}
        </Top5MoviesLayout>
      )}
      {movies.data && movies.data.length === 0 && <p>No data</p>}
      {movies.isLoading && (
        <div>
          <p>Calculating your most viewed movies...</p>
        </div>
      )}
    </div>
  );
};

export default ViewCountWise;
