import React, { useState } from 'react';
import { TyphoonBackdrop } from '../../../components/Backdrop';
import { Link } from 'react-router-dom';
import { IStandAloneMovie, ISeason } from '../../../types';
import { API_ENDPOINT_LAMBDA, s3FileUrl } from '../../../config';
import TyphoonIcon from '../../../components/icons';
import Switch from '@material-ui/core/Switch';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { getAccessToken, isVideoPlayable } from '../../../utils';
import Axios from 'axios';
import { TyphoonModal } from '../../../components/Modal';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../../utils/acl';
import { getUserRole } from 'src/stores/user/Selectors';
import useDebounce from 'src/components/hooks/useDebounce';
import { SkeletonSearchContent } from 'src/components/skeletons/SearchContent';

interface IPortalSearchQueryData {
  isActive: boolean;
  showSeries: boolean;
  searchableTitle?: string;
  searchableDescription?: string;
  searchableCastAndCrew?: string;
}

type searchMoviePayload = Pick<
  IStandAloneMovie,
  | 'id'
  | 'title'
  | 'thumbnail'
  | 'isEpisodic'
  | 'isFeatured'
  | 'isActive'
  | 'hlsUrl'
  | 'srcVideo'
  | 'videoDuration'
  | 'createdAt'
  | 'updatedAt'
>;
type searchSeasonPayload = Pick<
  ISeason,
  'id' | 'title' | 'thumbnail' | 'createdAt' | 'isFeatured' | 'isActive' | 'updatedAt'
>;

interface ISearchData {
  movies?: searchMoviePayload[];
  seasons?: searchSeasonPayload[];
}

const searchContents = async (queryData: IPortalSearchQueryData) => {
  const querystring = new URLSearchParams(queryData as any).toString();

  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/portal/search?${querystring}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return res.data;
};

interface IFeaturedValues {
  id: string;
  status: boolean;
  isMovie: 'movie' | 'season';
}

const featuredMovieMutation = async ({
  id,
  status,
}: {
  status: IFeaturedValues['status'];
  id: IFeaturedValues['id'];
}) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/movies/${id}/featured`,
    { status },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

const featuredSeasonMutation = async ({
  id,
  status,
}: {
  status: IFeaturedValues['status'];
  id: IFeaturedValues['id'];
}) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/seasons/${id}/featured`,
    { status },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const FilmmakerContentList = () => {
  const [showSeason, setShowSeason] = useState(false);
  const [showActive, setShowActive] = useState(true);

  const [showFeaturedModal, setShowFeaturedModal] = useState(false);
  const [featuredValues, setFeaturedValues] = useState<IFeaturedValues>();

  const [queryText, setQueryText] = useState('');
  const debouncedQuery = useDebounce<string>(queryText);
  const [key, setKey] = useState<'searchableTitle' | 'searchableCastAndCrew' | 'searchableDescription'>(
    'searchableTitle',
  );

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryText(event.target.value);
  };

  const handleQueryReset = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setQueryText('');
  };

  const queryClient = useQueryClient();

  const userRole = useSelector(getUserRole);

  const search = useQuery<ISearchData, Error>(
    [`portal-search`, { [key]: debouncedQuery, isActive: showActive, showSeries: showSeason }],
    () => {
      let queryData: IPortalSearchQueryData = {
        isActive: showActive,
        showSeries: showSeason,
      };

      if (debouncedQuery.length > 0) {
        queryData[key] = debouncedQuery;
      }

      return searchContents(queryData);
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  const featureMovieMutation = useMutation(featuredMovieMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('portal-search');
      queryClient.invalidateQueries('portal-featured-movies');
    },
  });

  const featureSeasonMutation = useMutation(featuredSeasonMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('portal-search');
      queryClient.invalidateQueries('portal-featured-seasons');
    },
  });

  const handleSeasonTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSeason(event.target.checked);
  };
  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowActive(event.target.checked);
  };

  const handleFeaturedModalOnClose = React.useCallback(() => setShowFeaturedModal(false), []);

  const handleFeaturedValues = async (data: IFeaturedValues) => {
    setShowFeaturedModal(true);
    setFeaturedValues(data);
  };

  const handleFeaturedRequest = async () => {
    if (featuredValues && isAdmin(userRole)) {
      if (featuredValues.isMovie === 'season') {
        await featureSeasonMutation.mutateAsync({ id: featuredValues.id, status: featuredValues.status });
      } else {
        await featureMovieMutation.mutateAsync({ id: featuredValues.id, status: featuredValues.status });
      }
      setShowFeaturedModal(false);
    }
  };

  return (
    <div>
      <div className="grid md:grid-cols-3 md:gap-x-2 md:gap-y-4 md:items-center mb-6">
        <div className="flex space-x-4 items-center justify-between">
          <h1 className="text-2xl font-medium text-typGreen">
            My
            <span className="text-white"> Content</span>
          </h1>
          <Link to="/filmmaker/upload" className="px-5 py-2 font-bold text-black rounded-md bg-typGreen md:hidden">
            Upload New
          </Link>
        </div>
        <div className="relative border border-typGrey10 bg-typHeaderBlack rounded-md overflow-hidden px-3 py-1 my-4 md:my-0">
          <input
            type="text"
            placeholder="Search film"
            className="w-full text-typGrey80 placeholder-typGrey30 font-medium bg-transparent py-2 pr-10 focus:outline-none"
            value={queryText}
            onChange={handleQueryChange}
          />
          <div
            className="cursor-pointer transition ease-in-out duration-200 p-2 rounded border border-transparent hover:border-typGrey80 absolute inset-y-0 right-0 flex items-center focus:outline-none"
            onClick={handleQueryReset}
          >
            <TyphoonIcon name="navbar-open" className="fill-current text-typGrey80 h-6" />
          </div>
        </div>
        <div className="hidden md:flex justify-end items-center">
          <Link to="/filmmaker/upload" className="px-5 py-2 font-bold text-black rounded-md bg-typGreen">
            Upload New
          </Link>
        </div>
        <div className="md:col-start-2 md:row-start-2 flex justify-center gap-2 mb-2 md:mb-0">
          <button
            className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 ${
              key === 'searchableTitle' ? 'border-typGreen' : 'border-typGrey10'
            }`}
            onClick={() => setKey('searchableTitle')}
          >
            Title
          </button>
          <button
            className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 ${
              key === 'searchableDescription' ? 'border-typGreen' : 'border-typGrey10'
            }`}
            onClick={() => setKey('searchableDescription')}
          >
            Description
          </button>
          <button
            className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 ${
              key === 'searchableCastAndCrew' ? 'border-typGreen' : 'border-typGrey10'
            }`}
            onClick={() => setKey('searchableCastAndCrew')}
          >
            Cast & Crew
          </button>
        </div>
        <div className="md:col-start-1 md:row-start-2 flex justify-center items-center">
          <span className={`font-semibold ${showSeason ? 'text-white' : 'text-typGreen'}`}>Movies</span>
          <Switch
            checked={showSeason}
            onChange={handleSeasonTypeChange}
            color="secondary"
            name="libraryView"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <span className={`font-semibold ${showSeason ? 'text-typGreen' : 'text-white'}`}>Series</span>
        </div>
        <div className="md:col-start-3 md:row-start-2 flex justify-center items-center">
          <span className={`font-semibold ${showActive ? 'text-white' : 'text-typGreen'}`}>Inactive</span>
          <Switch
            checked={showActive}
            onChange={handleActiveChange}
            color="secondary"
            name="libraryView"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <span className={`font-semibold ${showActive ? 'text-typGreen' : 'text-white'}`}>Active</span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 mb-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        {/* <TyphoonBackdrop open={movies.isLoading || seasons.isLoading} /> */}
        {showSeason ? (
          <>
            {search.data && search.data.seasons && search.data.seasons.length > 0 ? (
              search.data.seasons.map((season) => (
                <div key={season.id}>
                  <Link to={`/filmmaker/contents/seasons/${season.id}`} key={season.id}>
                    <div className="aspect-ratio--16x9">
                      <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${season.thumbnail}`}
                          alt={season.title}
                        />
                      </div>
                    </div>
                  </Link>
                  <div className="flex justify-between mt-3">
                    <h6 className="font-medium">{season.title}</h6>
                    {isAdmin(userRole) && season.isActive && (
                      <>
                        {season.isFeatured ? (
                          <div
                            className="cursor-pointer"
                            onClick={async () =>
                              handleFeaturedValues({ id: season.id, status: !season.isFeatured, isMovie: 'season' })
                            }
                          >
                            <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={async () =>
                              handleFeaturedValues({ id: season.id, status: !season.isFeatured, isMovie: 'season' })
                            }
                          >
                            <TyphoonIcon name="star-outline" className="h-6 fill-current" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : search.isLoading ? (
              <>
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              </>
            ) : (
              <p>You don't have content.</p>
            )}
          </>
        ) : (
          <>
            {search.data && search.data.movies && search.data.movies.length > 0 ? (
              search.data.movies.map((movie) => (
                <div key={movie.id}>
                  <Link to={`/filmmaker/contents/${movie.id}`} key={movie.id}>
                    <div className="aspect-ratio--16x9">
                      <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                        <img
                          className="object-cover w-full h-full"
                          src={`${s3FileUrl}${movie.thumbnail}`}
                          alt={movie.title}
                        />
                      </div>
                    </div>
                  </Link>
                  <div className="flex justify-between mt-3">
                    <h6 className="font-medium">{movie.title}</h6>
                    {!isVideoPlayable(movie) &&
                      (movie.srcVideo ? (
                        <span className="text-gray-500">processing</span>
                      ) : (
                        <span className="text-gray-500">pending</span>
                      ))}
                  </div>
                  <div className="flex justify-between mt-1 space-x-2 font-medium">
                    <div className="flex space-x-2">
                      <TyphoonIcon name="video-cam" className="h-6 fill-current" />
                      <span>{movie.videoDuration}</span>
                    </div>
                    {isAdmin(userRole) && movie.isActive && (
                      <>
                        {movie.isFeatured ? (
                          <div
                            className="cursor-pointer"
                            onClick={async () =>
                              handleFeaturedValues({ id: movie.id, status: !movie.isFeatured, isMovie: 'movie' })
                            }
                          >
                            <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={async () =>
                              handleFeaturedValues({ id: movie.id, status: !movie.isFeatured, isMovie: 'movie' })
                            }
                          >
                            <TyphoonIcon name="star-outline" className="h-6 fill-current" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : search.isLoading ? (
              <>
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              </>
            ) : (
              <p>You don't have content.</p>
            )}
          </>
        )}
      </div>
      {/* <div>
        <div className="flex flex-wrap">
          <button
            onClick={handlePrevious}
            disabled={!canPreviousPage()}
            className={`focus:outline-none bg-typGreen py-2 px-4 rounded-l ${
              canPreviousPage() ? 'opacity-100 cursor-pointer' : 'opacity-75 cursor-not-allowed'
            }`}
          >
            <TyphoonIcon name="arrow-left" className="h-6 text-white fill-current" />
          </button>
          <button
            onClick={handleNext}
            disabled={!canNextPage()}
            className={`focus:outline-none bg-typGreen py-2 px-4 rounded-r ${
              canNextPage() ? 'opacity-100 cursor-pointer' : 'opacity-75 cursor-not-allowed'
            }`}
          >
            <TyphoonIcon name="arrow-right" className="h-6 text-white fill-current" />
          </button>
        </div>
      </div> */}
      {/* Start Featured  Modal */}
      <TyphoonModal page={null} open={showFeaturedModal} onClose={handleFeaturedModalOnClose}>
        <div>
          <TyphoonBackdrop open={featureSeasonMutation.isLoading || featureMovieMutation.isLoading} />
          <div className="p-8">
            <div className="relative mt-4 mb-2">
              {featuredValues && (
                <div className="pb-8 text-lg font-medium text-white">
                  <p>
                    Are you sure to{' '}
                    <span className="text-typGreen">
                      {featuredValues.status && 'ADD'}
                      {!featuredValues.status && 'REMOVE'}
                    </span>{' '}
                    this on <span className="text-typGreen">Featured</span> list?
                  </p>
                </div>
              )}
              <div className="flex justify-end space-x-5">
                <button
                  onClick={() => {
                    setFeaturedValues(undefined);
                    setShowFeaturedModal(false);
                  }}
                  className="w-32 py-3 font-medium border rounded-md text-typGreen black border-typGreen focus:outline-none"
                >
                  No
                </button>
                <button
                  onClick={handleFeaturedRequest}
                  className="w-32 py-3 font-medium text-black border rounded-md border-typGreen bg-typGreen focus:outline-none"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </TyphoonModal>
      {/* End Featured Modal */}
    </div>
  );
};
