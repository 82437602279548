import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M12 17l6 4-1-7 5-5h-7l-3-7-3 7H2l5 5-1 7z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);

export default SVG;
