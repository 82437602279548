import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IFeaturedContents } from '../types';
import { s3FileUrl } from '../config';
import { useDispatch } from 'react-redux';
import { showLoginModalAction } from 'src/stores/Global/Actions';

interface props {
  content: IFeaturedContents;
}

export const MovieBanner: React.FC<props> = ({ content: { id, title, description, banner, type } }) => {
  const dispatch = useDispatch();

  const showLoginModal = useCallback(() => dispatch(showLoginModalAction()), [dispatch]);

  return (
    <div className="relative aspect-w-16 aspect-h-14 sm:aspect-h-9">
      <div className="aspect-ratio__inner-wrapper overflow-hidden">
        <div
          key={id}
          className="relative h-full w-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${s3FileUrl}${banner})`,
          }}
        >
          <div className="bg-gray-800/25 w-full h-full">
            <div
              className="h-full flex flex-col justify-center sm:block sm:max-w-2xl px-6 sm:px-10"
              style={{ paddingTop: '11%' }}
            >
              <div className="space-y-2 sm:space-y-3">
                <h1 className="font-bold text-3xl sm:text-4xl leading-none line-clamp-1 sm:line-clamp-none">{title}</h1>
                <p className="font-bold sm:font-medium text-sm sm:text-base line-clamp-1 sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-4 xl:line-clamp-6">
                  {description}
                </p>
                {/* <div className="flex flex-wrap -m-1">
                  {type === 'movie' && (
                    <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                      {isEpisodic ? 'EPISODE' : 'MOVIE'}
                    </div>
                  )}
                  {type === 'season' && (
                    <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                      SEASON
                    </div>
                  )}
                  <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                    {isPaid ? 'PAID' : 'FREE'}
                  </div>
                  {isPaid && (
                    <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                      {`One-Time : $${(price / 100).toFixed(2)}`}
                    </div>
                  )}
                  {isRentable && (
                    <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                      {`Rent : $${(rentalPrice / 100).toFixed(2)} / ${
                        parseInt(rentalDays.toString()) > 0 ? `${rentalDays} days` : `${rentalDays} day`
                      }`}
                    </div>
                  )}
                </div> */}
                <div className="flex space-x-3">
                    <Link
                      to={`/${type === 'movie' ? 'movies' : 'seasons'}/${id}`}
                      className="inline-block rounded-lg text-black font-semibold bg-typGreen ring-2 ring-typGreen px-4 py-2"
                    >
                      Watch
                    </Link>
                  <Link
                    to={`/${type === 'movie' ? 'movies' : 'seasons'}/${id}`}
                    className="inline-block rounded-lg text-white font-semibold bg-gray-500/50 ring-2 ring-white px-4 py-2"
                  >
                    More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 inset-x-0 h-8 sm:h-14 md:h-24 lg:h-40 movie-banner-fadeBottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
