import moment from 'moment';
import React from 'react';
import { s3FileUrl } from '../../config';
import { IEvent } from '../../types';

interface props {
  event: IEvent;
  handleSetUpdateEvent: (event: IEvent) => void;
  isAdmin: boolean;
}

export const EventMoreInfoModalView = ({ isAdmin, event, handleSetUpdateEvent }: props) => {
  return (
    <div className="sm:max-w-md md:max-w-xl p-8">
      <div className="relative mt-4 mb-2">
        <div className="font-medium text-white text-lg pb-8">
          <p>Event Details</p>
        </div>
        <div className="flex flex-col space-y-3 overflow-auto pb-1" style={{ maxHeight: '70vh' }}>
          <div className="border border-typGrey80 px-6 py-5 rounded-md space-y-4">
            <div className="text-white md:flex md:items-center">
              <div className="md:w-2/3">
                <h2 className="text-typGreen font-semibold text-lg mb-3">{event.title}</h2>
                <p>
                  <span className="font-semibold mb-1">Description: </span>
                  <span className="text-gray-300">{event.description}</span>
                </p>
                <p>
                  <span className="font-semibold mb-1">Price: </span>
                  <span className="text-gray-300">${(event.price / 100).toFixed(2)}</span>
                </p>
                <p>
                  <span className="font-semibold mb-1">Schedule: </span>
                  <span className="text-gray-300">
                    {moment(typeof event.schedule === 'string' ? parseInt(event.schedule, 10) : event.schedule).format(
                      'hh:mm a, Do MMM, YYYY',
                    )}
                  </span>
                </p>
              </div>
              <div className="hidden md:block md:w-1/3">
                <img src={`${s3FileUrl}${event.banner}`} alt={event.title} />
              </div>
            </div>
            {isAdmin && (
              <button
                className="w-full border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5"
                onClick={() => handleSetUpdateEvent(event)}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
