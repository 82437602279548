import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import BlogForm from 'src/components/Blog/BlogForm';
import BlogLayout from 'src/components/Blog/BlogLayout';
import Blogs from 'src/components/Blog/Blogs';
import { AppTitle } from 'src/config';
import { getUserRole } from 'src/stores/user/Selectors';
import { isAdmin } from 'src/utils/acl';

const Blog = () => {
  const userRole = useSelector(getUserRole);

  return (
    <>
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Blogs</title>
      </Helmet>
      <BlogLayout>
        <div>
          {isAdmin(userRole) && <BlogForm />}
          <Blogs />
        </div>
      </BlogLayout>
    </>
  );
};

export default Blog;
