import moment from 'moment';
import React from 'react';
import { s3FileUrl } from '../../config';
import { IEvent } from '../../types';

interface props {
  events: IEvent[];
  handleSetBuyEventTicket: (event: IEvent) => void;
  isTicketPurchased: (eventId: IEvent['id']) => boolean;
  handleSetUpdateEvent: (event: IEvent) => void;
  handleSetArchiveEventModal: (event: IEvent) => void;
  isAdmin: boolean;
}

export const UpcomingEventsModalView = ({
  events,
  handleSetBuyEventTicket,
  isTicketPurchased,
  handleSetUpdateEvent,
  handleSetArchiveEventModal,
  isAdmin,
}: props) => {
  return (
    <div className="sm:max-w-md md:max-w-xl p-8">
      <div className="relative mt-4 mb-2">
        <div className="font-medium text-white text-lg pb-8">
          <p>Upcoming Events</p>
        </div>
        <div className="flex flex-col space-y-3 overflow-auto pb-1" style={{ maxHeight: '70vh' }}>
          {events.length > 0 ? (
            events.map((event) => (
              <div
                key={event.id}
                className="text-white border border-typGrey80 px-6 py-5 rounded-md md:flex md:items-center"
              >
                <div className="md:w-2/3">
                  <h2 className="text-typGreen font-semibold text-lg mb-3">{event.title}</h2>
                  <p>
                    <span className="font-semibold mb-1">Description: </span>
                    <span className="text-gray-300">{event.description}</span>
                  </p>
                  <p>
                    <span className="font-semibold mb-1">Price: </span>
                    <span className="text-gray-300">${(event.price / 100).toFixed(2)}</span>
                  </p>
                  <p>
                    <span className="font-semibold mb-1">Schedule: </span>
                    <span className="text-gray-300">
                      {moment(
                        typeof event.schedule === 'string' ? parseInt(event.schedule, 10) : event.schedule,
                      ).format('hh:mm a, Do MMM, YYYY')}
                    </span>
                  </p>
                  {isAdmin ? (
                    <div className="-m-1 mt-2">
                      <button
                        onClick={() => handleSetUpdateEvent(event)}
                        className="border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleSetArchiveEventModal(event)}
                        className="border-2 border-red-600 bg-red-600 rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
                      >
                        Archive
                      </button>
                    </div>
                  ) : (
                    <>
                      {isTicketPurchased(event.id) ? (
                        <p className="text-typGreen font-semibold mt-3">Already purchased.</p>
                      ) : (
                        <button
                          onClick={() => {
                            handleSetBuyEventTicket(event);
                          }}
                          className="border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5 focus:outline-none mt-2"
                        >
                          Buy Ticket
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="hidden md:block md:w-1/3">
                  <img src={`${s3FileUrl}${event.banner}`} alt={event.title} />
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No upcoming events.</p>
          )}
        </div>
      </div>
    </div>
  );
};
