import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M15 8v8H5V8h10m1-2H4a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1v-3.5l4 4v-11l-4 4V7a1 1 0 00-1-1z" />
  </svg>
);

export default SVG;
