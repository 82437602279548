import { useState } from 'react';
import BlogDrafts from './BlogDrafts';
import BlogPublished from './BlogPublished';
import { useSelector } from 'react-redux';
import { isAdmin } from 'src/utils/acl';
import { getUserRole } from 'src/stores/user/Selectors';

const Blogs = () => {
  const [showDrafts, setShowDrafts] = useState(false);
  const userRole = useSelector(getUserRole);

  return (
    <div>
      <div>
        {isAdmin(userRole) && (
          <div className="flex p-4">
            <button
              className={`${
                !showDrafts ? 'text-typGreen' : 'text-typGreen/60'
              } transition focus:outline-none hover:text-typGreen mr-4`}
              onClick={() => setShowDrafts(false)}
            >
              Published
            </button>
            <button
              className={`${
                showDrafts ? 'text-typGreen' : 'text-typGreen/60'
              } transition focus:outline-none hover:text-typGreen`}
              onClick={() => setShowDrafts(true)}
            >
              Draft
            </button>
          </div>
        )}

        <div>
          {showDrafts ? (
            <div>
              <BlogDrafts />
            </div>
          ) : (
            <div>
              <BlogPublished />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
