import { store } from '../stores';
import { categoryTypes, IPaginationInfo, IQueryParams, IStandAloneMovie, ratingTypes } from '../types';
import { useCategories } from '../components/hooks/useCategory';

export const Color = {
  Primary: '#3dcab1',
};

export const parseINT = (v: string | number): number => (typeof v === 'string' ? parseInt(v, 10) : v);
export const truncate = (str: string, n: number): string => (str.length > n ? str.substr(0, n - 1) + '...' : str);
export const truncateReverse = (str: string, n: number) =>
  str.length > n
    ? '...' +
      str
        .split('')
        .reverse()
        .join('')
        .substr(0, n - 1)
        .split('')
        .reverse()
        .join('')
    : str;
// all regex
export const emailRegex = /^([a-z\d._+-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

export const isAuthenticate = () => (store.getState().userReducer.user ? true : false);

export const isVideoPlayable = (movie: IStandAloneMovie | any) => (movie && movie.hlsUrl ? true : false);

export function getAccessToken() {
  let accessToken: string | null = null;
  if (typeof window !== undefined && window.localStorage.getItem('typ-access-token')) {
    accessToken = window.localStorage.getItem('typ-access-token');
    return accessToken;
  } else {
    removeAccessToken();
  }
  return accessToken;
}

export const setAccessToken = (token: string) => {
  window.localStorage.setItem('typ-access-token', token);
};
export const removeAccessToken = () => {
  window.localStorage.removeItem('typ-access-token');
};

export const splitTitle = (title: string): string[] => {
  let result: string[] = [];

  const arr = title.split(' ');
  result.push(arr[0]);

  if (arr.length > 1) {
    result.push(arr.slice(1, arr.length).join(' '));
  }

  return result;
};

export const MovieRatingNames: {
  [key in ratingTypes]: string;
} = {
  G: 'General Audience',
  PG: 'Parental Guidance Suggested',
  'PG-13': 'Parents Strongly Cautioned',
  R: 'Restricted',
  'NC-17': 'No One 17 and Under Admitted',
};

export const LanguagesWithLocal = [
  {
    language: 'Abkhazian',
    locale: 'ab',
  },
  {
    language: 'Afar',
    locale: 'aa',
  },
  {
    language: 'Afrikaans',
    locale: 'af',
  },
  {
    language: 'Akan',
    locale: 'ak',
  },
  {
    language: 'Albanian',
    locale: 'sq',
  },
  {
    language: 'Amharic',
    locale: 'am',
  },
  {
    language: 'Arabic',
    locale: 'ar',
  },
  {
    language: 'Aragonese',
    locale: 'an',
  },
  {
    language: 'Armenian',
    locale: 'hy',
  },
  {
    language: 'Assamese',
    locale: 'as',
  },
  {
    language: 'Avaric',
    locale: 'av',
  },
  {
    language: 'Avestan',
    locale: 'ae',
  },
  {
    language: 'Aymara',
    locale: 'ay',
  },
  {
    language: 'Azerbaijani',
    locale: 'az',
  },
  {
    language: 'Bambara',
    locale: 'bm',
  },
  {
    language: 'Bashkir',
    locale: 'ba',
  },
  {
    language: 'Basque',
    locale: 'eu',
  },
  {
    language: 'Belarusian',
    locale: 'be',
  },
  {
    language: 'Bengali',
    locale: 'bn',
  },
  {
    language: 'Bislama',
    locale: 'bi',
  },
  {
    language: 'Bosnian',
    locale: 'bs',
  },
  {
    language: 'Breton',
    locale: 'br',
  },
  {
    language: 'Bulgarian',
    locale: 'bg',
  },
  {
    language: 'Burmese',
    locale: 'my',
  },
  {
    language: 'Catalan',
    locale: 'ca',
  },
  {
    language: 'Central Khmer',
    locale: 'km',
  },
  {
    language: 'Chamorro',
    locale: 'ch',
  },
  {
    language: 'Chechen',
    locale: 'ce',
  },
  {
    language: 'Chinese',
    locale: 'zh',
  },
  {
    language: 'Church Slavic',
    locale: 'cu',
  },
  {
    language: 'Chuvash',
    locale: 'cv',
  },
  {
    language: 'Cornish',
    locale: 'kw',
  },
  {
    language: 'Corsican',
    locale: 'co',
  },
  {
    language: 'Cree',
    locale: 'cr',
  },
  {
    language: 'Croatian',
    locale: 'hr',
  },
  {
    language: 'Czech',
    locale: 'cs',
  },
  {
    language: 'Danish',
    locale: 'da',
  },
  {
    language: 'Dhivehi',
    locale: 'dv',
  },
  {
    language: 'Dutch',
    locale: 'nl',
  },
  {
    language: 'Dzongkha',
    locale: 'dz',
  },
  {
    language: 'English',
    locale: 'en',
  },
  {
    language: 'Esperanto',
    locale: 'eo',
  },
  {
    language: 'Estonian',
    locale: 'et',
  },
  {
    language: 'Ewe',
    locale: 'ee',
  },
  {
    language: 'Faroese',
    locale: 'fo',
  },
  {
    language: 'Fijian',
    locale: 'fj',
  },
  {
    language: 'Finnish',
    locale: 'fi',
  },
  {
    language: 'French',
    locale: 'fr',
  },
  {
    language: 'Fulah',
    locale: 'ff',
  },
  {
    language: 'Galician',
    locale: 'gl',
  },
  {
    language: 'Ganda',
    locale: 'lg',
  },
  {
    language: 'Georgian',
    locale: 'ka',
  },
  {
    language: 'German',
    locale: 'de',
  },
  {
    language: 'Guarani',
    locale: 'gn',
  },
  {
    language: 'Gujarati',
    locale: 'gu',
  },
  {
    language: 'Haitian',
    locale: 'ht',
  },
  {
    language: 'Hausa',
    locale: 'ha',
  },
  {
    language: 'Hebrew',
    locale: 'he',
  },
  {
    language: 'Herero',
    locale: 'hz',
  },
  {
    language: 'Hindi',
    locale: 'hi',
  },
  {
    language: 'Hiri Motu',
    locale: 'ho',
  },
  {
    language: 'Hungarian',
    locale: 'hu',
  },
  {
    language: 'Icelandic',
    locale: 'is',
  },
  {
    language: 'Ido',
    locale: 'io',
  },
  {
    language: 'Igbo',
    locale: 'ig',
  },
  {
    language: 'Indonesian',
    locale: 'id',
  },
  {
    language: 'Interlingua (International Auxiliary Language Association)',
    locale: 'ia',
  },
  {
    language: 'Interlingue',
    locale: 'ie',
  },
  {
    language: 'Inuktitut',
    locale: 'iu',
  },
  {
    language: 'Inupiaq',
    locale: 'ik',
  },
  {
    language: 'Irish',
    locale: 'ga',
  },
  {
    language: 'Italian',
    locale: 'it',
  },
  {
    language: 'Japanese',
    locale: 'ja',
  },
  {
    language: 'Javanese',
    locale: 'jv',
  },
  {
    language: 'Kalaallisut',
    locale: 'kl',
  },
  {
    language: 'Kannada',
    locale: 'kn',
  },
  {
    language: 'Kanuri',
    locale: 'kr',
  },
  {
    language: 'Kashmiri',
    locale: 'ks',
  },
  {
    language: 'Kazakh',
    locale: 'kk',
  },
  {
    language: 'Kikuyu',
    locale: 'ki',
  },
  {
    language: 'Kinyarwanda',
    locale: 'rw',
  },
  {
    language: 'Kirghiz',
    locale: 'ky',
  },
  {
    language: 'Komi',
    locale: 'kv',
  },
  {
    language: 'Kongo',
    locale: 'kg',
  },
  {
    language: 'Korean',
    locale: 'ko',
  },
  {
    language: 'Kuanyama',
    locale: 'kj',
  },
  {
    language: 'Kurdish',
    locale: 'ku',
  },
  {
    language: 'Lao',
    locale: 'lo',
  },
  {
    language: 'Latin',
    locale: 'la',
  },
  {
    language: 'Latvian',
    locale: 'lv',
  },
  {
    language: 'Limburgan',
    locale: 'li',
  },
  {
    language: 'Lingala',
    locale: 'ln',
  },
  {
    language: 'Lithuanian',
    locale: 'lt',
  },
  {
    language: 'Luba-Katanga',
    locale: 'lu',
  },
  {
    language: 'Luxembourgish',
    locale: 'lb',
  },
  {
    language: 'Macedonian',
    locale: 'mk',
  },
  {
    language: 'Malagasy',
    locale: 'mg',
  },
  {
    language: 'Malay (macrolanguage)',
    locale: 'ms',
  },
  {
    language: 'Malayalam',
    locale: 'ml',
  },
  {
    language: 'Maltese',
    locale: 'mt',
  },
  {
    language: 'Manx',
    locale: 'gv',
  },
  {
    language: 'Maori',
    locale: 'mi',
  },
  {
    language: 'Marathi',
    locale: 'mr',
  },
  {
    language: 'Marshallese',
    locale: 'mh',
  },
  {
    language: 'Modern Greek (1453-)',
    locale: 'el',
  },
  {
    language: 'Mongolian',
    locale: 'mn',
  },
  {
    language: 'Nauru',
    locale: 'na',
  },
  {
    language: 'Navajo',
    locale: 'nv',
  },
  {
    language: 'Ndonga',
    locale: 'ng',
  },
  {
    language: 'Nepali (macrolanguage)',
    locale: 'ne',
  },
  {
    language: 'North Ndebele',
    locale: 'nd',
  },
  {
    language: 'Northern Sami',
    locale: 'se',
  },
  {
    language: 'Norwegian',
    locale: 'no',
  },
  {
    language: 'Norwegian Bokmål',
    locale: 'nb',
  },
  {
    language: 'Norwegian Nynorsk',
    locale: 'nn',
  },
  {
    language: 'Nyanja',
    locale: 'ny',
  },
  {
    language: 'Occitan (post 1500)',
    locale: 'oc',
  },
  {
    language: 'Ojibwa',
    locale: 'oj',
  },
  {
    language: 'Oriya (macrolanguage)',
    locale: 'or',
  },
  {
    language: 'Oromo',
    locale: 'om',
  },
  {
    language: 'Ossetian',
    locale: 'os',
  },
  {
    language: 'Pali',
    locale: 'pi',
  },
  {
    language: 'Panjabi',
    locale: 'pa',
  },
  {
    language: 'Persian',
    locale: 'fa',
  },
  {
    language: 'Polish',
    locale: 'pl',
  },
  {
    language: 'Portuguese',
    locale: 'pt',
  },
  {
    language: 'Pushto',
    locale: 'ps',
  },
  {
    language: 'Quechua',
    locale: 'qu',
  },
  {
    language: 'Romanian',
    locale: 'ro',
  },
  {
    language: 'Romansh',
    locale: 'rm',
  },
  {
    language: 'Rundi',
    locale: 'rn',
  },
  {
    language: 'Russian',
    locale: 'ru',
  },
  {
    language: 'Samoan',
    locale: 'sm',
  },
  {
    language: 'Sango',
    locale: 'sg',
  },
  {
    language: 'Sanskrit',
    locale: 'sa',
  },
  {
    language: 'Sardinian',
    locale: 'sc',
  },
  {
    language: 'Scottish Gaelic',
    locale: 'gd',
  },
  {
    language: 'Serbian',
    locale: 'sr',
  },
  {
    language: 'Serbo-Croatian',
    locale: 'sh',
  },
  {
    language: 'Shona',
    locale: 'sn',
  },
  {
    language: 'Sichuan Yi',
    locale: 'ii',
  },
  {
    language: 'Sindhi',
    locale: 'sd',
  },
  {
    language: 'Sinhala',
    locale: 'si',
  },
  {
    language: 'Slovak',
    locale: 'sk',
  },
  {
    language: 'Slovenian',
    locale: 'sl',
  },
  {
    language: 'Somali',
    locale: 'so',
  },
  {
    language: 'South Ndebele',
    locale: 'nr',
  },
  {
    language: 'Southern Sotho',
    locale: 'st',
  },
  {
    language: 'Spanish',
    locale: 'es',
  },
  {
    language: 'Sundanese',
    locale: 'su',
  },
  {
    language: 'Swahili (macrolanguage)',
    locale: 'sw',
  },
  {
    language: 'Swati',
    locale: 'ss',
  },
  {
    language: 'Swedish',
    locale: 'sv',
  },
  {
    language: 'Tagalog',
    locale: 'tl',
  },
  {
    language: 'Tahitian',
    locale: 'ty',
  },
  {
    language: 'Tajik',
    locale: 'tg',
  },
  {
    language: 'Tamil',
    locale: 'ta',
  },
  {
    language: 'Tatar',
    locale: 'tt',
  },
  {
    language: 'Telugu',
    locale: 'te',
  },
  {
    language: 'Thai',
    locale: 'th',
  },
  {
    language: 'Tibetan',
    locale: 'bo',
  },
  {
    language: 'Tigrinya',
    locale: 'ti',
  },
  {
    language: 'Tonga (Tonga Islands)',
    locale: 'to',
  },
  {
    language: 'Tsonga',
    locale: 'ts',
  },
  {
    language: 'Tswana',
    locale: 'tn',
  },
  {
    language: 'Turkish',
    locale: 'tr',
  },
  {
    language: 'Turkmen',
    locale: 'tk',
  },
  {
    language: 'Twi',
    locale: 'tw',
  },
  {
    language: 'Uighur',
    locale: 'ug',
  },
  {
    language: 'Ukrainian',
    locale: 'uk',
  },
  {
    language: 'Urdu',
    locale: 'ur',
  },
  {
    language: 'Uzbek',
    locale: 'uz',
  },
  {
    language: 'Venda',
    locale: 've',
  },
  {
    language: 'Vietnamese',
    locale: 'vi',
  },
  {
    language: 'Volapük',
    locale: 'vo',
  },
  {
    language: 'Walloon',
    locale: 'wa',
  },
  {
    language: 'Welsh',
    locale: 'cy',
  },
  {
    language: 'Western Frisian',
    locale: 'fy',
  },
  {
    language: 'Wolof',
    locale: 'wo',
  },
  {
    language: 'Xhosa',
    locale: 'xh',
  },
  {
    language: 'Yiddish',
    locale: 'yi',
  },
  {
    language: 'Yoruba',
    locale: 'yo',
  },
  {
    language: 'Zhuang',
    locale: 'za',
  },
  {
    language: 'Zulu',
    locale: 'zu',
  },
];
//picture
export const pictureMimetypeValues = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];
export const pictureSizeLimit = 5;
// pagination calculation
export const calculatePaginationFromTo = (
  data: IPaginationInfo,
): {
  from: number;
  to: number;
} => {
  let to = 0,
    from = 0;

  if (data.numberOfElements < data.pageSize) {
    from = data.numberOfElements === 0 ? 0 : data.pageSize * data.pageNumber - data.pageSize + 1;
    to = data.totalElements;
  } else {
    from = data.pageSize * data.pageNumber - data.pageSize + 1;
    to = data.pageSize * data.pageNumber;
  }

  return {
    from,
    to,
  };
};

export const getQueryParams = (data: IQueryParams): string => {
  const { pageNumber, pageSize } = data;
  return `pageNumber=${pageNumber}&pageSize=${pageSize}`;
};

export const generateQueryString = <T>(data: T) => {
  let queryString = '';
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key as keyof T];
      if (value || typeof value === 'number') {
        if (queryString.length === 0) {
          queryString += `${key}=${value}`;
        } else {
          queryString += `&${key}=${value}`;
        }
      }
    }
  }
  return queryString;
};

export const generateSearchParams = (data: any) => new URLSearchParams(data);

//? Nuevo plugin default options
export const nuevoDefaultOptions = {
  logo: `${
    process.env.NODE_ENV === 'production' ? 'https://www.typhoonondemand.com' : 'http://localhost:3000'
  }/icons/favicon.ico`,
  logourl: `${
    process.env.NODE_ENV === 'production' ? 'https://www.typhoonondemand.com' : 'http://localhost:3000'
  }/filmmaker/subscription`,
};

//? vast.vpaid.min.js path
export const path_to_vast_vpaid = `${
  process.env.NODE_ENV === 'production' ? 'https://www.typhoonondemand.com' : 'http://localhost:3000'
}/js/vast.vpaid.min.js`;

//? vast.vpaid.min.js path
export const path_to_vast_pro = `${
  process.env.NODE_ENV === 'production' ? 'https://www.typhoonondemand.com' : 'http://localhost:3000'
}/js/vastpro.js`;
