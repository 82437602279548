import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M23.95 4.57a10 10 0 01-2.82.77 4.96 4.96 0 002.16-2.72c-.95.56-2 .96-3.12 1.19a4.92 4.92 0 00-8.38 4.48A13.93 13.93 0 011.63 3.16a4.92 4.92 0 001.52 6.57 4.9 4.9 0 01-2.23-.61v.06c0 2.38 1.7 4.37 3.95 4.82a5 5 0 01-2.21.09 4.94 4.94 0 004.6 3.42A9.87 9.87 0 010 19.54a14 14 0 007.56 2.21c9.05 0 14-7.5 14-13.98 0-.21 0-.42-.02-.63A9.94 9.94 0 0024 4.59l-.05-.02z" />
  </svg>
);

export default SVG;
