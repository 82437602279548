import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistor, store } from './stores';
import App from './App';
import { config } from './config';
// import AppSyncConfig from './config/aws-exports';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { init as heapInit } from 'src/heap/heap-analytics';
import { Color } from './utils';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';
import './styles/index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff1a',
      contrastText: '#fff',
    },
    secondary: {
      main: Color.Primary,
      contrastText: '#000',
    },
  },
});

// Amplify.configure(AppSyncConfig);

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    identityPoolRegion: config.cognito.IDENTITY_POOL_REGION,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: config.S3.file.bucket,
      region: config.S3.file.region,
    },
  },
});

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: config.S3.video.bucket,
      region: config.S3.video.region,
    },
  },
});

// AWS.config.update({
//   region: 'us-west-1',
//   accessKeyId: config.IAM.ADMIN.ACCESS,
//   secretAccessKey: config.IAM.ADMIN.SECRET,
// });

// const client = new AWSAppSyncClient({
//   url: AppSyncConfig.aws_appsync_graphqlEndpoint,
//   region: AppSyncConfig.aws_appsync_region,
//   auth: {
//     type: 'AMAZON_COGNITO_USER_POOLS',
//     jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
//   },
// });

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        {/* <ApolloProvider client={client}> */}
        <QueryClientProvider client={queryClient}>
          <App />
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
        {/* </ApolloProvider> */}
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

heapInit();
