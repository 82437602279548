import Axios from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { ICategory } from 'src/types';
import { getAccessToken } from 'src/utils';

const fetchCatagories = async () => {
  const response = await Axios.get(`${API_ENDPOINT_LAMBDA}/categories`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data.data;
};

export const useCategories = () => {
  return useQuery<ICategory[], Error>(
    ['Active-Category'],
    async () => {
      return await fetchCatagories();
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
};
