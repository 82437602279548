import React from 'react';
import MinuteWise from './MinuteWise';
import ViewCountWise from './ViewCountWise';

const MovieAnalytics = () => {
  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-medium text-typGreen">My Top 5</h1>
      <div className="flex-wrap w-full space-y-8 lg:space-x-24 lg:space-y-0 lg:flex">
        <ViewCountWise />
        <MinuteWise />
      </div>
    </div>
  );
};

export default MovieAnalytics;
