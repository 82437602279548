import React, { useState } from 'react';
import { IStandAloneMovie } from '../../types';
import { API_ENDPOINT_LAMBDA, config } from '../../config';
import { getAccessToken, truncateReverse } from '../../utils';
import TyphoonIcon from '../icons';
import { LinearProgress } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import Axios, { AxiosResponse } from 'axios';

const fileExtType = ['mp4', 'mov', 'h264', 'mt2', 'mpg', 'm2t', 'm2ts', 'ts'];

interface props {
  data: IStandAloneMovie;
  onClose: () => void;
}

export const EditMovieStep4: React.FC<props> = ({ data, onClose }) => {
  // loading
  const [isMovieLoading, setIsMovieLoading] = useState(false);

  // progress
  const [chunkCount, setChunkCount] = useState(1);
  const [completedChunkCount, setCompletedChunkCount] = useState(0);

  // hover on drop
  const [isMovieHoverOnDrop, setMovieHoverOnDrop] = useState(false);

  // file
  const [movie, setMovie] = useState<File>();

  // errors
  const [movieError, setMovieError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const queryClient = useQueryClient();

  const handleChangeMovieFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setMovieError(undefined);
    if (event.target.files) {
      const file = event.target.files[0];
      const extType = file.name.split('.').pop();
      if (file && extType) {
        if (!fileExtType.includes(extType.toLocaleLowerCase())) {
          setMovieError('Only mp4, mov, mt2, mpg, m2t, m2ts, ts and h264 are allowed.');
          return;
        } else {
          setMovie(file);
          setMovieError(undefined);
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (movie) {
      setCompletedChunkCount(0);

      try {
        setIsMovieLoading(true);
        const movieType = movie.name.split('.').pop();
        const filename = `${data.id}.${movieType}`;

        //? part 1 :  get uploadId
        const multipartUploadResponse = await Axios.get(
          `${API_ENDPOINT_LAMBDA}/movies/upload?bucket=${config.S3.video.bucket}&key=${filename}`,
        );
        const uploadId = multipartUploadResponse.data;

        //? part 2 : get pre-signed URL and make an array of parts
        const CHUNK_SIZE = 100000000; // 100MB
        const fileSize = movie.size;
        const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
        setChunkCount(CHUNKS_COUNT);
        let promisesArray: Promise<AxiosResponse<any>>[] = [];
        let start: number, end: number, blob: Blob;

        let uploadPartsArray: { ETag: string; PartNumber: number }[] = [];

        for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
          start = (index - 1) * CHUNK_SIZE;
          end = index * CHUNK_SIZE;
          blob = index < CHUNKS_COUNT ? movie.slice(start, end) : movie.slice(start);

          // Get presigned URL for each part
          const getUploadUrlResponse = await Axios.post(
            `${API_ENDPOINT_LAMBDA}/movies/upload`,
            {
              bucket: config.S3.video.bucket,
              key: filename,
              uploadId,
              partNumber: index,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                'Content-Type': movie.type,
              },
            },
          );
          let presignedUrl = getUploadUrlResponse.data;

          // Send part aws server
          let uploadResp = Axios.put(presignedUrl, blob, {
            headers: {
              'Content-Type': movie.type,
            },
            onUploadProgress: (progress) => {
              if (progress.loaded === progress.total) {
                setCompletedChunkCount((p) => {
                  return p + 1;
                });
              }
            },
          });

          promisesArray.push(uploadResp);
        }

        let resolvedArray = await Promise.all(promisesArray);

        resolvedArray.forEach((resolvedPromise, index) => {
          uploadPartsArray.push({
            ETag: resolvedPromise.headers.etag,
            PartNumber: index + 1,
          });
        });

        //? part 3 : complete multipart upload
        await Axios.put(
          `${API_ENDPOINT_LAMBDA}/movies/upload`,
          {
            bucket: config.S3.video.bucket,
            key: filename,
            uploadId,
            parts: uploadPartsArray,
          },
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
              'Content-Type': movie.type,
            },
          },
        );

        queryClient.invalidateQueries(['portal-stand-alone', data.id.toString()]);
        TyphoonToaster('Video uploaded.', 'success');
        onClose();
      } catch (error: any) {
        console.log(error.response);
        setSubmitError('Unable to upload!!');
      }

      setIsMovieLoading(false);
      setChunkCount(0);
      setCompletedChunkCount(0);
    } else {
      setMovieError('Upload at least one new content!!');
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-semibold text-center mb-6">
        <span className="text-typGreen">Edit</span> Content STEP - 4
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
        <div className="mb-5">
          <div
            className={`border ${isMovieHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
              movieError ? 'border-red-500' : ''
            } bg-typBodyBlack rounded-md`}
            onDragEnter={() => {
              setMovieHoverOnDrop(true);
            }}
            onDragLeave={() => {
              setMovieHoverOnDrop(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setMovieHoverOnDrop(true);
            }}
            onDrop={(e) => {
              e.preventDefault();
              setMovieHoverOnDrop(false);
              setMovie(undefined);
              const files = Array.from(e.dataTransfer.files);
              const extType = files[0].name.split('.').pop();
              if (files.length > 1) {
                setMovieError('Drop only One file.');
              } else if (files.length === 1 && extType) {
                if (!fileExtType.includes(extType.toLocaleLowerCase())) {
                  setMovieError('Only mp4, mov, mt2, mpg, m2t, m2ts, ts and h264 are allowed.');
                  return;
                } else {
                  setMovie(files[0]);
                  setMovieError(undefined);
                  return;
                }
              }
            }}
          >
            <input
              accept="video/*"
              className="hidden"
              id="browse-movie-file-button"
              type="file"
              onChange={handleChangeMovieFile}
            />
            <label htmlFor="browse-movie-file-button">
              {movie ? (
                <div className="flex justify-start items-center p-6 cursor-pointer">
                  <div>{truncateReverse(movie.name, 30)}</div>
                </div>
              ) : (
                <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                  <TyphoonIcon name="upload" className="fill-current text-typGreen h-10" />
                  <p className="text-typGreen text-sm">
                    Click here to upload <span className="font-semibold text-base">Movie</span>
                  </p>
                </div>
              )}
            </label>
          </div>
          {isMovieLoading && (
            <div className="flex items-center mt-1">
              <div className="flex-grow">
                <LinearProgress variant="determinate" value={Math.round((completedChunkCount / chunkCount) * 100)} />
              </div>
              <p className="text-typGreen text-sm ml-2 font-semibold">{`${Math.round(
                (completedChunkCount / chunkCount) * 100,
              )}%`}</p>
            </div>
          )}
          {movieError && <p className="text-red-500 text-xs italic font-medium mt-1">{movieError}</p>}
        </div>

        {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
        <button
          onClick={handleSubmit}
          disabled={isMovieLoading}
          className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
            isMovieLoading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer opacity-100'
          }`}
        >
          Edit
        </button>
        <p className="tracking-wide text-gray-400 italic font-medium mt-2">
          Do not navigate away from this page during upload or you will lose your work!
        </p>
      </div>
    </div>
  );
};
