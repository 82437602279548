import React from 'react';
import { createStyles, FormControl, makeStyles, Select } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4b4b4b',
      },
      '& label.Mui-focused': {
        color: '#4b4b4b',
      },
      '& .MuiSelect-outlined': {
        backgroundColor: '#292929',
        color: '#ffffff',
        '&:hover': {
          borderColor: '#4b4b4b',
        },
        '& fieldset': {
          borderColor: '#4b4b4b',
        },
        '&:hover fieldset': {
          borderColor: '#4b4b4b',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#4b4b4b',
        },
      },
      '& .MuiInputBase-root': {
        backgroundColor: '#292929',
        color: '#ffffff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#4b4b4b',
        },
        '&:hover fieldset': {
          borderColor: '#4b4b4b',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#4b4b4b',
        },
      },
    },
  }),
);
// * Select Field
interface ISelectProps {
  name: string;
  children: React.ReactNode;
  control: Control<any>;
  error: boolean;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  readOnly?: boolean;
  defaultValue?: any;
}
export const ReactHookFormSelect: React.FC<ISelectProps> = ({
  name,
  control,
  defaultValue,
  children,
  error,
  readOnly,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { ref, ...fieldProps } }) => (
          <Select
            {...fieldProps}
            {...props}
            innerRef={ref}
            fullWidth
            displayEmpty
            className={classes.input}
            inputProps={{ readOnly, 'aria-label': 'Without label' }}
            error={error}
          >
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
