import React, { useState } from 'react';
import TextField from 'src/components/TextField';
import { Link, useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as Yup from 'yup';
import { emailRegex, lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { IUser } from '../../stores/user/Reducer';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../config';

const validationSchema = Yup.object().shape({
  family_name: Yup.string()
    .trim()
    .required('Required')
    .min(1, 'Should be at least 1 character long')
    .max(32, 'Should be less than 32 characters'),
  given_name: Yup.string()
    .trim()
    .required('Required')
    .min(1, 'Should be at least 1 character long')
    .max(32, 'Should be less than 32 characters'),
  email: Yup.string().trim().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .trim()
    .required('Required')
    .matches(lowercaseRegex, 'One lowercase required!')
    .matches(uppercaseRegex, 'One uppercase required!')
    .matches(numericRegex, 'One number required!')
    .matches(specialCharRegex, 'One special character required! Like: _ # = @ * ! -')
    .min(8, 'Minimum 8 characters required!'),
  confirmPassword: Yup.string()
    .trim()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password must be the same!'),
});

interface IData extends Pick<IUser, 'email' | 'family_name' | 'given_name'> {
  password: string;
  confirmPassword: string;
}

const RegisterPage = () => {
  const history = useHistory();
  const [cognitoError, setCognitoError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          given_name: data.family_name,
          family_name: data.given_name,
        },
      });
      history.push('/user/verification');
    } catch (error: any) {
      console.log('error', error);
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className="p-6 lg:px-10 xl:px-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Registration</title>
      </Helmet>
      <TyphoonBackdrop open={isSubmitting} />
      <div className="max-w-md mx-auto">
        <h1 className="mb-6 text-2xl font-semibold">
          <span className="text-typGreen">Typhoon</span> Registration
        </h1>
        <div className="border-2 rounded-md bg-typHeaderBlack border-typGrey10">
          <div className="p-6 border-b-2 border-typGrey10 sm:p-10">
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type="text"
                  name="family_name"
                  id="family_name"
                  fullWidth
                  variant="outlined"
                  placeholder="First name"
                  error={errors.family_name ? true : false}
                />
                {errors.family_name && (
                  <p className="mt-1 text-xs italic font-medium text-red-500">{errors.family_name.message}</p>
                )}
              </div>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type="text"
                  name="given_name"
                  id="given_name"
                  fullWidth
                  variant="outlined"
                  placeholder="Last name"
                  error={errors.given_name ? true : false}
                />{' '}
                {errors.given_name && (
                  <p className="mt-1 text-xs italic font-medium text-red-500">{errors.given_name.message}</p>
                )}
              </div>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type="text"
                  name="email"
                  id="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  error={errors.email ? true : false}
                />
                {errors.email && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.email.message}</p>}
              </div>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  fullWidth
                  variant="outlined"
                  placeholder="Password"
                  error={errors.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password ? (
                  <p className="mt-1 text-xs italic font-medium text-red-500">{errors.password.message}</p>
                ) : (
                  <p className="mt-1 text-xs italic font-medium text-gray-400">
                    Password should contain lowercase, uppercase, number & special characters.
                  </p>
                )}
              </div>
              <div className="mb-5">
                <TextField
                  control={control}
                  defaultValue=""
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  fullWidth
                  variant="outlined"
                  placeholder="Confirm password"
                  error={errors.confirmPassword ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-xs italic font-medium text-red-500">{errors.confirmPassword.message}</p>
                )}
              </div>
              {cognitoError && <p className="mt-1 text-xs italic font-medium text-red-500">{cognitoError}</p>}
              <button
                type="submit"
                className="w-full py-3 mt-5 font-medium text-black border rounded-md border-typGreen bg-typGreen"
              >
                REGISTER
              </button>
            </form>

            <p className="mt-5 text-xs text-center text-white">
              By registering, you agree to our{' '}
              <Link to="/terms-and-conditions" className="underline">
                Terms of Use
              </Link>
              {' and '}
              <Link to="/privacy-policy" className="underline">
                Privacy Policy
              </Link>
            </p>
          </div>
          <div className="p-6 text-center">
            <p className="text-sm text-white">
              Already have an account? Click here to{' '}
              <Link to="/login" className="cursor-pointer text-typGreen">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
