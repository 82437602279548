import React, { useState } from 'react';
import { MenuItem, LinearProgress } from '@material-ui/core';
import TextField from 'src/components/TextField';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISeason, ISeasonStep1 } from '../../types';
import { getAccessToken, MovieRatingNames, truncateReverse } from '../../utils';
import { TyphoonBackdrop } from '../Backdrop';
import ReactHookFormSelect from '../ReactHookFormSelect';
import { useQueryClient, useMutation } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import Axios from 'axios';
import { API_ENDPOINT_LAMBDA, config, s3FileUrl } from '../../config';
import { Storage } from 'aws-amplify';
import TyphoonIcon from '../icons';
import { Link } from 'react-router-dom';
import { useCategories } from '../hooks/useCategory';

const imageMimetype = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];
const fileExtType = ['mp4'];

const validationSchema = (categoryData: any) =>
  Yup.object().shape({
  title: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
  description: Yup.string().trim().required('Required').min(2, 'Should be at least 2 characters long'),
  categoryId: Yup.string()
    .required('Required')
    .oneOf(categoryData ? categoryData.map((categoryTuple: any) => categoryTuple.id) : []),
  ratings: Yup.string().required('Required').oneOf(['G', 'PG', 'PG-13', 'R', 'NC-17']),
  castAndCrew: Yup.string()
    .trim()
    .required('Required')
    .min(2, 'Should be at least 2 characters long')
    .max(300, 'Should be less then 300 characters'),
});

const updateSeasonMutation = async (data: { body: ISeasonStep1; id: ISeason['id'] }) => {
  await Axios.put(`${API_ENDPOINT_LAMBDA}/seasons/${data.id}`, data.body, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

interface props {
  data: ISeason;
  onClose: () => void;
}

export const EditSeasonStep1: React.FC<props> = ({ data, onClose }) => {
  // loading
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);
  const [isTrailerLoading, setIsTrailerLoading] = useState(false);

  // progress
  const [bannerProgress, setBannerProgress] = useState(0);
  const [thumbnailProgress, setThumbnailProgress] = useState(0);
  const [trailerProgress, setTrailerProgress] = useState(0);

  // hover on drop
  const [isBannerHoverOnDrop, setBannerHoverOnDrop] = useState(false);
  const [isThumbnailHoverOnDrop, setThumbnailHoverOnDrop] = useState(false);
  const [isTrailerHoverOnDrop, setTrailerHoverOnDrop] = useState(false);

  // files
  const [bannerImage, setBannerImage] = useState<File>();
  const [thumbnailImage, setThumbnailImage] = useState<File>();
  const [trailerFile, setTrailerFile] = useState<File>();

  // errors
  const [bannerImageError, setBannerImageError] = useState<string>();
  const [trailerError, setTrailerError] = useState<string>();
  const [thumbnailImageError, setThumbnailImageError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const queryClient = useQueryClient();
  const categories = useCategories();

  const movieMutation = useMutation(updateSeasonMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portal-season', data.id.toString()]);
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ISeasonStep1>({
    mode: 'onChange',
    defaultValues: {
      title: data.title,
      description: data.description,
      categoryId: data.categoryId,
      ratings: data.ratings,
      castAndCrew: data.castAndCrew,
      banner: data.banner,
      thumbnail: data.thumbnail,
      trailer: data.trailer,
      searchableTitle: data.searchableTitle,
      searchableDescription: data.searchableDescription,
      searchableCastAndCrew: data.searchableCastAndCrew,
    },
    resolver: yupResolver(validationSchema(categories.data)),
  });

  const handleChangeBannerImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setBannerImage(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!imageMimetype.includes(image.type)) {
          setBannerImageError('Only image/png ,image/jpeg and image/jpg are allowed.');
          return;
        } else {
          setBannerImage(image);
          setBannerImageError(undefined);
          return;
        }
      }
    }
  };
  const handleChangeThumbnailImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setThumbnailImage(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!imageMimetype.includes(image.type)) {
          setThumbnailImageError('Only image/png ,image/jpeg and image/jpg are allowed.');
          return;
        } else {
          setThumbnailImage(image);
          setThumbnailImageError(undefined);
          return;
        }
      }
    }
  };
  const handleChangeTrailerFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrailerFile(undefined);
    if (event.target.files) {
      const file = event.target.files[0];
      const extType = file.name.split('.').pop();
      if (file && extType) {
        if (!fileExtType.includes(extType.toLocaleLowerCase())) {
          setTrailerError('Only mp4 is allowed.');
          return;
        } else {
          setTrailerFile(file);
          setTrailerError(undefined);
          return;
        }
      }
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    values.searchableTitle = values.title.toLowerCase();
    values.searchableDescription = values.description.toLowerCase();
    values.searchableCastAndCrew = values.castAndCrew.toLowerCase();
    try {
      setBannerProgress(0);
      setThumbnailProgress(0);

      if (bannerImage) {
        const bannerImageType = bannerImage.name.split('.').pop();
        setIsBannerLoading(true);
        const banner = (await Storage.put(`seasons/banner-${Date.now()}.${bannerImageType}`, bannerImage, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setBannerProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        setIsBannerLoading(false);
        values.banner = banner.key;
      }

      if (thumbnailImage) {
        const thumbnailImageType = thumbnailImage.name.split('.').pop();
        setIsThumbnailLoading(true);
        const thumbnail = (await Storage.put(`seasons/thumbnail-${Date.now()}.${thumbnailImageType}`, thumbnailImage, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setThumbnailProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        setIsThumbnailLoading(false);
        values.thumbnail = thumbnail.key;
      }

      if (trailerFile) {
        const trailerImageType = trailerFile.name.split('.').pop();
        setIsTrailerLoading(true);
        const trailer = (await Storage.put(`seasons/trailer-${Date.now()}.${trailerImageType}`, trailerFile, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setTrailerProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        setIsTrailerLoading(false);
        values.trailer = trailer.key;
      }

      await movieMutation.mutateAsync({ body: values, id: data.id });
      TyphoonToaster('Information updated.', 'success');
      onClose();
    } catch (error: any) {
      console.log(error.response);
      setSubmitError('Unable to Edit!!');
    }
  });

  return (
    <div className="max-w-md mx-auto">
      <h1 className="mb-6 text-2xl font-semibold text-center">
        <span className="text-typGreen">Edit</span> Season
      </h1>
      <div className="p-6 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
        <form onSubmit={onSubmit}>
          <TyphoonBackdrop open={isSubmitting} />
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={errors.title ? true : false}
            />
            {errors.title && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.title.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="description"
              fullWidth
              variant="outlined"
              placeholder="Description"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.description ? true : false}
            />
            {errors.description && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.description.message}</p>
            )}
          </div>
          <div className="mb-5">
            <ReactHookFormSelect
              name="categoryId"
              variant="outlined"
              error={errors.categoryId ? true : false}
              control={control}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {categories.data?.map((category, i) => (
                <MenuItem key={i} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.categoryId && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.categoryId.message}</p>
            )}
          </div>
          <div className="mb-5">
            <ReactHookFormSelect
              name="ratings"
              variant="outlined"
              error={errors.ratings ? true : false}
              control={control}
            >
              <MenuItem value="" disabled>
                Select Ratings
              </MenuItem>
              {Object.entries(MovieRatingNames).map((rating, i) => (
                <MenuItem key={i} value={rating[0]}>
                  {rating[1]}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {errors.ratings && <p className="mt-1 text-xs italic font-medium text-red-500">{errors.ratings.message}</p>}
          </div>
          <div className="mb-5">
            <TextField
              control={control}
              defaultValue=""
              type="text"
              name="castAndCrew"
              fullWidth
              variant="outlined"
              placeholder="Filmmaker/Cast/Crew"
              multiline
              minRows={4}
              maxRows={10}
              error={errors.castAndCrew ? true : false}
            />
            {errors.castAndCrew && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{errors.castAndCrew.message}</p>
            )}
          </div>
          <div className="mb-5">
            <div
              className={`border ${isBannerHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
                bannerImageError ? 'border-red-500' : ''
              } bg-typBodyBlack rounded-md p-1 overflow-hidden`}
              onDragEnter={() => {
                setBannerHoverOnDrop(true);
              }}
              onDragLeave={() => {
                setBannerHoverOnDrop(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setBannerHoverOnDrop(true);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setBannerHoverOnDrop(false);
                setBannerImage(undefined);
                const files = Array.from(e.dataTransfer.files);
                if (files.length > 1) {
                  setBannerImageError('Drop only One file.');
                } else if (files.length === 1) {
                  if (!imageMimetype.includes(files[0].type)) {
                    setBannerImageError('Only image/png ,image/jpeg and image/jpg are allowed.');
                    return;
                  } else {
                    setBannerImage(files[0]);
                    setBannerImageError(undefined);
                    return;
                  }
                }
              }}
            >
              <input
                accept="image/*"
                className="hidden"
                id="browse-banner-file-button"
                type="file"
                onChange={handleChangeBannerImage}
              />
              <label htmlFor="browse-banner-file-button">
                {bannerImage ? (
                  <div className="flex items-center justify-start p-6 cursor-pointer">
                    <div>{truncateReverse(bannerImage.name, 30)}</div>
                  </div>
                ) : (
                  <div className="cursor-pointer aspect-ratio--16x9">
                    <div className="overflow-hidden aspect-ratio__inner-wrapper">
                      <img
                        className="object-cover w-full h-full"
                        src={`${s3FileUrl}${data.banner}`}
                        alt={data.banner}
                      />
                    </div>
                  </div>
                )}
              </label>
            </div>
            {isBannerLoading && (
              <div className="flex items-center mt-1">
                <div className="flex-grow">
                  <LinearProgress variant="determinate" value={bannerProgress} />
                </div>
                <p className="ml-2 text-sm font-semibold text-typGreen">{`${bannerProgress}%`}</p>
              </div>
            )}
            {bannerImageError && <p className="mt-1 text-xs italic font-medium text-red-500">{bannerImageError}</p>}
            <p className="mt-1 text-xs italic font-medium text-gray-400">
              All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
              credits. Please check the{' '}
              <Link
                to={'/filmmaker/news-and-weather'}
                target="_blank"
                className="inline-block font-semibold underline text-typGreen"
              >
                Typhoon User Guide
              </Link>{' '}
              .
            </p>
          </div>
          <div className="mb-5">
            <div
              className={`border ${isThumbnailHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
                thumbnailImageError ? 'border-red-500' : ''
              } bg-typBodyBlack rounded-md p-1 overflow-hidden`}
              onDragEnter={() => {
                setThumbnailHoverOnDrop(true);
              }}
              onDragLeave={() => {
                setThumbnailHoverOnDrop(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setThumbnailHoverOnDrop(true);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setThumbnailHoverOnDrop(false);
                setThumbnailImage(undefined);
                const files = Array.from(e.dataTransfer.files);
                if (files.length > 1) {
                  setThumbnailImageError('Drop only One file.');
                } else if (files.length === 1) {
                  if (!imageMimetype.includes(files[0].type)) {
                    setThumbnailImageError('Only image/png ,image/jpeg and image/jpg are allowed.');
                    return;
                  } else {
                    setThumbnailImage(files[0]);
                    setThumbnailImageError(undefined);
                    return;
                  }
                }
              }}
            >
              <input
                accept="image/*"
                className="hidden"
                id="browse-thumbnail-file-button"
                type="file"
                onChange={handleChangeThumbnailImage}
              />
              <label htmlFor="browse-thumbnail-file-button">
                {thumbnailImage ? (
                  <div className="flex items-center justify-start p-6 cursor-pointer">
                    <div>{truncateReverse(thumbnailImage.name, 30)}</div>
                  </div>
                ) : (
                  <div className="cursor-pointer aspect-ratio--16x9">
                    <div className="overflow-hidden aspect-ratio__inner-wrapper">
                      <img
                        className="object-cover w-full h-full"
                        src={`${s3FileUrl}${data.thumbnail}`}
                        alt={data.thumbnail}
                      />
                    </div>
                  </div>
                )}
              </label>
            </div>
            {isThumbnailLoading && (
              <div className="flex items-center mt-1">
                <div className="flex-grow">
                  <LinearProgress variant="determinate" value={thumbnailProgress} />
                </div>
                <p className="ml-2 text-sm font-semibold text-typGreen">{`${thumbnailProgress}%`}</p>
              </div>
            )}
            {thumbnailImageError && (
              <p className="mt-1 text-xs italic font-medium text-red-500">{thumbnailImageError}</p>
            )}
            <p className="mt-1 text-xs italic font-medium text-gray-400">
              All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
              credits. Please check the{' '}
              <Link
                to={'/filmmaker/news-and-weather'}
                target="_blank"
                className="inline-block font-semibold underline text-typGreen"
              >
                Typhoon User Guide
              </Link>{' '}
              .
            </p>
          </div>
          <div className="mb-5">
            <div
              className={`border ${isTrailerHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
                trailerError ? 'border-red-500' : ''
              } bg-typBodyBlack rounded-md`}
              onDragEnter={() => {
                setTrailerHoverOnDrop(true);
              }}
              onDragLeave={() => {
                setTrailerHoverOnDrop(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setTrailerHoverOnDrop(true);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setTrailerHoverOnDrop(false);
                setTrailerFile(undefined);
                const files = Array.from(e.dataTransfer.files);
                if (files.length > 1) {
                  setTrailerError('Drop only One file.');
                } else if (files.length === 1) {
                  if (!fileExtType.includes(files[0].type)) {
                    setTrailerError('Only image/png ,image/jpeg and image/jpg are allowed.');
                    return;
                  } else {
                    setTrailerFile(files[0]);
                    setTrailerError(undefined);
                    return;
                  }
                }
              }}
            >
              <input
                accept="video/*"
                className="hidden"
                id="browse-trailer-file-button"
                type="file"
                onChange={handleChangeTrailerFile}
              />
              <label htmlFor="browse-trailer-file-button">
                {trailerFile ? (
                  <div className="flex items-center justify-start p-6 cursor-pointer">
                    <div>{truncateReverse(trailerFile.name, 30)}</div>
                  </div>
                ) : data.trailer ? (
                  <div className="flex items-center justify-start p-6 cursor-pointer">
                    <div>{truncateReverse(data.trailer, 30)}</div>
                  </div>
                ) : (
                  <div className="flex items-center justify-start p-6 space-x-6 cursor-pointer">
                    <TyphoonIcon name="upload" className="h-10 fill-current text-typGreen" />
                    <p className="text-sm text-typGreen">
                      Drop or Click here to upload <span className="text-base font-semibold">Trailer</span>
                    </p>
                  </div>
                )}
              </label>
            </div>
            {isTrailerLoading && (
              <div className="flex items-center mt-1">
                <div className="flex-grow">
                  <LinearProgress variant="determinate" value={trailerProgress} />
                </div>
                <p className="ml-2 text-sm font-semibold text-typGreen">{`${trailerProgress}%`}</p>
              </div>
            )}
            {trailerError && <p className="mt-1 text-xs italic font-medium text-red-500">{trailerError}</p>}
          </div>
          {submitError && <p className="mt-1 text-xs italic font-medium text-red-500">{submitError}</p>}

          <button
            type="submit"
            disabled={isSubmitting || isBannerLoading || isThumbnailLoading || isTrailerLoading}
            className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
              isSubmitting || isBannerLoading || isThumbnailLoading || isTrailerLoading
                ? 'cursor-not-allowed opacity-75'
                : 'cursor-pointer opacity-100'
            }`}
          >
            Edit
          </button>
        </form>
      </div>
    </div>
  );
};
