import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserRole } from 'src/stores/user/Selectors';
import { AppTitle } from '../../../config';
import { isAdmin } from '../../../utils/acl';
import { FilmmakerContentList } from '../Contents/ContentList';
import { FilmmakerFeaturedList } from './Featured';

export const FilmmakerPortalPage = () => {
  const userRole = useSelector(getUserRole);

  return (
    <div className="space-y-12">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Portal</title>
      </Helmet>
      <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between">
        <h1 className="m-1 text-2xl font-medium text-center text-typGreen">
          <span>Welcome</span> <span className="text-white">{isAdmin(userRole) ? 'Admin' : 'Subscriber'}</span>
        </h1>
        <Link
          to="/filmmaker/statistics"
          className="inline-block px-5 py-2 m-1 font-bold text-center text-black rounded-md bg-typGreen"
        >
          Statistics
        </Link>
        <Link
          to="/filmmaker/news-and-weather"
          className="inline-block px-5 py-2 m-1 font-bold text-center text-black rounded-md bg-typGreen"
        >
          News and Weather
        </Link>
        <Link
          to="/filmmaker/partners-and-affiliates"
          className="inline-block px-5 py-2 m-1 font-bold text-center text-black rounded-md bg-typGreen"
        >
          Partners and Affiliates
        </Link>
        <Link
          to="/filmmaker/pay-me"
          className="inline-block px-5 py-2 m-1 font-bold text-center text-black rounded-md bg-typGreen"
        >
          Pay Me
        </Link>
      </div>

      {isAdmin(userRole) && <FilmmakerFeaturedList />}
      <FilmmakerContentList />
    </div>
  );
};
