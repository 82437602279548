import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Switch } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Axios from 'axios';

import useDebounce from '../../components/hooks/useDebounce';
import TyphoonIcon from '../../components/icons';
import { SkeletonSearchContent } from '../../components/skeletons/SearchContent';
import { API_ENDPOINT_LAMBDA, AppTitle, s3FileUrl } from '../../config';
import { ISeason, IStandAloneMovie } from '../../types';

const fetchData = async (querystring: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/search?${querystring}`);
  return res.data;
};

type searchMoviePayload = Pick<
  IStandAloneMovie,
  'id' | 'title' | 'thumbnail' | 'isEpisodic' | 'createdAt' | 'updatedAt'
>;
type searchSeasonPayload = Pick<ISeason, 'id' | 'title' | 'thumbnail' | 'createdAt' | 'updatedAt'>;

interface ISearchData {
  movies?: searchMoviePayload[];
  seasons?: searchSeasonPayload[];
}

const SearchPage = () => {
  const location = useLocation();
  const history = useHistory();

  const [showSeason, setShowSeason] = useState(false);

  const queryObject = new URLSearchParams(location.search);
  const queryText = queryObject.get('query') || '';
  const debouncedQuery = useDebounce<string>(queryText);

  const ref = useRef<HTMLInputElement>(null);
  ref.current?.focus();

  const [key, setKey] = useState<'searchableTitle' | 'searchableCastAndCrew' | 'searchableDescription'>(
    'searchableTitle',
  );

  const search = useQuery<ISearchData, Error>(
    ['search', `${key}=${debouncedQuery.toLowerCase()}&showSeries=${showSeason}`],
    () => fetchData(`${key}=${debouncedQuery.toLowerCase()}&showSeries=${showSeason}`),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: debouncedQuery.length > 0,
    },
  );

  const handleSeasonTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSeason(event.target.checked);
  };

  return (
    <div className="px-6 lg:px-10 xl:px-16 my-6 sm:my-12">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Search</title>
      </Helmet>
      <div className="max-w-xl mx-auto mb-6">
        <div className="relative border border-typGrey10 bg-typHeaderBlack rounded-md overflow-hidden px-5 py-2 mb-1">
          <input
            ref={ref}
            type="text"
            placeholder="Search film"
            className="w-full text-typGrey80 placeholder-typGrey30 font-medium bg-transparent py-2 pr-10 focus:outline-none"
            value={queryText}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                history.push(`/search?query=${e.target.value}`);
              } else {
                history.push('/search');
              }
            }}
          />
          <div
            className="cursor-pointer transition ease-in-out duration-200 p-2 rounded border border-transparent hover:border-typGrey80 absolute inset-y-0 right-0 flex items-center"
            onClick={(e) => history.push('/search')}
          >
            <TyphoonIcon name="navbar-open" className="fill-current text-typGrey80 h-6" />
          </div>
        </div>
        {debouncedQuery.length > 0 ? (
          <p className="text-xs font-semibold text-typGrey80">Hit `Enter` to search!</p>
        ) : (
          <p className="text-xs font-semibold text-red-500">Required</p>
        )}
      </div>
      <div className="flex flex-wrap justify-center space-x-10 mb-6">
        <div className="flex flex-wrap items-center space-x-1">
          <h1 className="font-medium">Match with :</h1>
          <div className="flex flex-wrap -m-2">
            <button
              className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 m-1 ${
                key === 'searchableTitle' ? 'border-typGreen' : 'border-typGrey10'
              }`}
              onClick={() => setKey('searchableTitle')}
            >
              Title
            </button>
            <button
              className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 m-1 ${
                key === 'searchableDescription' ? 'border-typGreen' : 'border-typGrey10'
              }`}
              onClick={() => setKey('searchableDescription')}
            >
              Description
            </button>
            <button
              className={`bg-typHeaderBlack border text-white rounded-md px-2 py-1 m-1 ${
                key === 'searchableCastAndCrew' ? 'border-typGreen' : 'border-typGrey10'
              }`}
              onClick={() => setKey('searchableCastAndCrew')}
            >
              Cast & Crew
            </button>
          </div>
        </div>
        <div className="flex items-center">
          <span className={`font-semibold ${showSeason ? 'text-white' : 'text-typGreen'}`}>Movies</span>
          <Switch
            checked={showSeason}
            onChange={handleSeasonTypeChange}
            color="secondary"
            name="libraryView"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <span className={`font-semibold ${showSeason ? 'text-typGreen' : 'text-white'}`}>Series</span>
        </div>
      </div>
      <h1 className="text-2xl font-medium mb-12">
        {debouncedQuery ? (
          <>
            Search result of '<span className="text-typGreen">{debouncedQuery}</span>'
          </>
        ) : (
          <>Search again ...</>
        )}
      </h1>
      <div className="space-y-10">
        {!showSeason && (
          <div>
            <h2 className="text-xl font-medium text-typGreen mb-2">Movies</h2>
            {search.data && search.data.movies && search.data.movies.length > 0 ? (
              <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mb-6">
                {search.data.movies.map((movie) => (
                  <div key={movie.id} className="space-y-1">
                    <Link key={movie.id} to={`/movies/${movie.id}`}>
                      <div className="aspect-ratio--16x9">
                        <div className="aspect-ratio__inner-wrapper overflow-hidden rounded-2xl border border-typGrey10 transition duration-500 ease-in-out transform hover:scale-105">
                          <img
                            className="h-full w-full object-cover"
                            src={`${s3FileUrl}${movie.thumbnail}`}
                            alt={movie.title}
                          />
                        </div>
                      </div>
                    </Link>
                    <h6 className="font-medium mt-3">{movie.title}</h6>
                    <div className="flex flex-wrap -m-1">
                      <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                        {movie.isEpisodic ? 'EPISODE' : 'MOVIE'}
                      </div>
                      {/* <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                        {movie.isPaid ? 'PAID' : 'FREE'}
                      </div>
                      {movie.isPaid && (
                        <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                          {`One-Time : $${(movie.price / 100).toFixed(2)}`}
                        </div>
                      )}
                      {movie.isRentable && (
                        <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                          {`Rent : $${(movie.rentalPrice / 100).toFixed(2)} / ${
                            parseInt(movie.rentalDays.toString()) > 0
                              ? `${movie.rentalDays} days`
                              : `${movie.rentalDays} day`
                          }`}
                        </div>
                      )} */}
                    </div>
                  </div>
                ))}
              </div>
            ) : search.isLoading ? (
              <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mb-6">
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              </div>
            ) : (
              <p>No movies found.</p>
            )}
          </div>
        )}
        {showSeason && (
          <div>
            <h2 className="text-xl font-medium text-typGreen mb-2">Series</h2>
            {search.data && search.data.seasons && search.data.seasons.length > 0 ? (
              <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mb-6">
                {search.data.seasons.map((season) => (
                  <div key={season.id} className="space-y-1">
                    <Link to={`/seasons/${season.id}`} key={season.id}>
                      <div className="aspect-ratio--16x9">
                        <div className="aspect-ratio__inner-wrapper overflow-hidden rounded-2xl border border-typGrey10 transition duration-500 ease-in-out transform hover:scale-105">
                          <img
                            className="h-full w-full object-cover"
                            src={`${s3FileUrl}${season.thumbnail}`}
                            alt={season.title}
                          />
                        </div>
                      </div>
                    </Link>
                    <h6 className="font-medium mt-3">{season.title}</h6>
                    {/* <div className="flex flex-wrap -m-1">
                      <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                        {season.isPaid ? 'PAID' : 'FREE'}
                      </div>
                      {season.isPaid && (
                        <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                          {`One-Time : $${(season.price / 100).toFixed(2)}`}
                        </div>
                      )}
                      {season.isRentable && (
                        <div className="bg-typGreen/25 rounded-full font-medium tracking-wider text-sm text-white py-1 px-2 m-1">
                          {`Rent : $${(season.rentalPrice / 100).toFixed(2)} / ${
                            parseInt(season.rentalDays.toString()) > 0
                              ? `${season.rentalDays} days`
                              : `${season.rentalDays} day`
                          }`}
                        </div>
                      )}
                    </div> */}
                  </div>
                ))}
              </div>
            ) : search.isLoading ? (
              <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mb-6">
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
                <SkeletonSearchContent className="animate-pulse" />
                <SkeletonSearchContent className="animate-pulse" isSmall={true} />
              </div>
            ) : (
              <p>No series found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
