import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { emailRegex, lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../../utils';
import TextField from 'src/components/TextField';
import { Auth } from 'aws-amplify';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TyphoonToaster } from '../../components/Toastify';
import { Helmet } from 'react-helmet';
import { AppTitle } from '../../config';

interface IData {
  code: string;
  email: string;
  newPassword: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  newPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

export const ForgotPasswordVerificationPage = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [cognitoError, setCognitoError] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword);
      TyphoonToaster('Reset password successful.', 'success');
      history.push('/login');
    } catch (error: any) {
      console.log('error', error);
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className="max-w-md mx-auto">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Forgot Password Verification</title>
      </Helmet>
      <TyphoonBackdrop open={isSubmitting} />
      <h1 className="text-2xl font-semibold mb-6">
        <span className="text-typGreen">Typhoon</span> New Password
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md">
        <div className="p-6 sm:p-10">
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="code"
                id="code"
                fullWidth
                variant="outlined"
                placeholder="Enter your code here!"
                error={errors.code ? true : false}
              />
              {errors.code && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.code.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="email"
                id="email"
                fullWidth
                variant="outlined"
                placeholder="Enter your email here!"
                error={errors.email ? true : false}
              />
              {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                id="newPassword"
                fullWidth
                variant="outlined"
                placeholder="Password"
                error={errors.newPassword ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle newPassword visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newPassword ? (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.newPassword.message}</p>
              ) : (
                <p className="text-gray-400 text-xs italic font-medium mt-1">
                  Password should contain at least one of each: lowercase, uppercase, number & special character.
                </p>
              )}
            </div>

            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}
            <button
              type="submit"
              className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
