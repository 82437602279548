import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import TyphoonIcon from '../../../components/icons';
import { AppTitle, s3ResourceUrl } from '../../../config';

const FilmmakerOnBoardPage = () => {
  return (
    <div className="max-w-3xl mx-auto mb-32">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>News and Weather</title>
      </Helmet>
      <h1 className="text-center text-3xl font-semibold mb-1">Welcome Typhoon Filmmaker!</h1>
      <p className="text-typGreen font-semibold text-center text-xl mb-5">
        Time to batten down the hatches, there’s a Typhoon coming!
      </p>
      <div className="bg-typHeaderBlack text-lg border-2 border-typGrey10 rounded-md p-6 sm:p-10">
        <p className=" mb-6">
          Before you begin uploading content on Typhoon, please be sure to download and review these very important
          documents. If you have any questions regarding legal feel free to contact us at{' '}
          <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
            support@typhoonondemand.com
          </a>
          ​ with an email heading of "​Legal Question​."
        </p>
        <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-3 mb-6">
          <NavLink
            to={{ pathname: `${s3ResourceUrl}/filmmaker_docs/Typhoon-User-Guide.pdf` }}
            target="_blank"
            rel="noopener"
            className="flex justify-start items-center space-x-6 text-base cursor-pointer border-2 border-typGreen rounded-lg p-6 focus:outline-none focus:ring-2 focus:ring-typGreen"
          >
            <TyphoonIcon name="download" className="fill-current text-typGreen h-10 flex-shrink-0" />
            <p className="text-typGreen font-semibold">Typhoon User Guide</p>
          </NavLink>
          <NavLink
            to={{ pathname: `${s3ResourceUrl}/filmmaker_docs/Typhoon-License-Agreement.pdf` }}
            target="_blank"
            rel="noopener"
            className="flex justify-start items-center space-x-6 text-base cursor-pointer border-2 border-typGreen rounded-lg p-6 focus:outline-none focus:ring-2 focus:ring-typGreen"
          >
            <TyphoonIcon name="download" className="fill-current text-typGreen h-10 flex-shrink-0" />
            <p className="text-typGreen font-semibold">Typhoon License Agreement</p>
          </NavLink>
          <NavLink
            to={{
              pathname: `${s3ResourceUrl}/filmmaker_docs/Typhoon-Content-Policy-Guidelines.pdf`,
            }}
            target="_blank"
            rel="noopener"
            className="flex justify-start items-center space-x-6 text-base cursor-pointer border-2 border-typGreen rounded-lg p-6 focus:outline-none focus:ring-2 focus:ring-typGreen"
          >
            <TyphoonIcon name="download" className="fill-current text-typGreen h-10 flex-shrink-0" />
            <p className="text-typGreen font-semibold">Typhoon Content Policy Guidelines</p>
          </NavLink>
        </div>
        <p className="mb-6">
          To make sure your uploaded content is approved by our team of human reviewers quickly, easily and without
          issue, be sure to read and understand the ​Typhoon User Guide.​​ Don’t worry, it’s an easy read! We have
          standards covered in the guide, all to make sure that Typhoon is kept clean and consistent in its look and
          feel, and that the upload process is as easy and painless as possible for all! If you find yourself having
          problems uploading content or have questions in regards to the Typhoon User Guide, f​eel free to contact us at{' '}
          <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
            support@typhoonondemand.com
          </a>
          ​ with an email heading of ​"User Guide".
        </p>
        <h2>
          <span className="text-typGreen font-semibold">Typhoon</span> payout is scheduled to go out quarterly - for the
          preceding quarter and will go directly to your PayPal account.
        </h2>
        <p className="">
          Please make sure the sign-up email that you use is the one connected to your PayPal account. If you sign up
          with us midway through a month then your payout will begin the following quarter.{' '}
        </p>
        <p className="mb-6">
          If you’d prefer a different payout method other than PayPal, feel free to contact us at{' '}
          <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
            support@typhoonondemand.com
          </a>
          ​ with an email heading of "Payout Preference​."
        </p>
        <p className="">
          Don’t forget to join us on our Facebook group, Typhoon Inner Circle. Simply search Facebook for "Typhoon On
          Demand," hit the "Groups" tab and request to join.
        </p>
        <p className="mb-6">Community is important to us. No filmmaker should feel left out in the storm alone!</p>
        <p className="mb-6">
          For all other questions, concerns or feedback, please reach out to us at{' '}
          <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
            support@typhoonondemand.com.
          </a>
        </p>

        <p className="text-typGreen font-semibold  mb-6">Featured Slider</p>
        <div className="mb-6">
          <p>
            Make an impact and *have your key-art placed on the Typhoon landing page Feature Slider spot! To request
            Feature Slider placement for your key-art with the included "Subscribe" button, contact us at{' '}
            <span>
              <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
                support@typhoonondemand.com.
              </a>
            </span>{' '}
            Write "Featured Slider" in the heading. In the email body tell us the name of your film and how long you'd
            like to have it placed. Soon you'll be able to order placement on this page!
          </p>
          <p className="text-sm">*Additional cost of $30 per month. One month minimum.</p>
        </div>
        <br></br>
        <p>Stay tuned for updates and be sure to check the Weather Report often before heading out to sea.</p>
        <br></br>
        <p className="mb-6 text-elecBlue">See you in the Inner Circle!</p>
      </div>
    </div>
  );
};

export default FilmmakerOnBoardPage;
