import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../App';
import { ForgotPasswordPage } from './ForgotPassword';
import { ForgotPasswordVerificationPage } from './ForgotPasswordVerification';
import { UserMyListPage } from './MyList';
import { UserSettingsPage } from './settings/index';
import { UserVerificationPage } from './Verification';

const UserPage = () => {
  let { path } = useRouteMatch();

  return (
    <div className="px-6 lg:px-10 xl:px-16 my-6 sm:my-12">
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${path}/settings`} />}></Route>
        <PrivateRoute exact path={`${path}/settings`}>
          <UserSettingsPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/list`}>
          <UserMyListPage />
        </PrivateRoute>
        <Route exact path={`${path}/verification`}>
          <UserVerificationPage />
        </Route>
        <Route exact path={`${path}/forgot-password`}>
          <ForgotPasswordPage />
        </Route>
        <Route exact path={`${path}/forgot-password/verification`}>
          <ForgotPasswordVerificationPage />
        </Route>
        <Route path="*" render={() => <Redirect to={`${path}/settings`} />}></Route>
      </Switch>
    </div>
  );
};

export default UserPage;
