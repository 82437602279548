import React from 'react';
import { IPaginationInfo } from 'src/types';
import { calculatePaginationFromTo } from 'src/utils';
import TyphoonIcon from '../icons';

interface IProps {
  paginationInfo: IPaginationInfo;
  pageNumber: number;
  handlePageChange: (page: number) => void;
}

const Pagination = ({ paginationInfo, pageNumber, handlePageChange }: IProps) => {
  return (
    <div>
      {paginationInfo && (
        <div className="flex items-center space-x-4">
          <p className="text-sm text-gray-400">
            Showing {calculatePaginationFromTo(paginationInfo).to} of {paginationInfo.totalElements}
          </p>
          <div className="flex">
            <button
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={!paginationInfo.previous}
              className={`focus:outline-none bg-primary bg-opacity-20 py-1 px-4 rounded-l ${
                paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-700'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <TyphoonIcon name="arrow-left" className="h-6 fill-current" />
            </button>
            <button
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={!paginationInfo.next}
              className={`focus:outline-none bg-primary bg-opacity-20 py-1 px-4 rounded-r ${
                paginationInfo.next ? 'opacity-100 cursor-pointer hover:bg-gray-700' : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <TyphoonIcon name="arrow-right" className="h-6 fill-current" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
