import Axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { API_ENDPOINT_LAMBDA, AppTitle, s3BlogUrl } from 'src/config';
import { getAccessToken } from 'src/utils';
import { CircularProgress } from '@material-ui/core';
import { TyphoonToaster } from 'src/components/Toastify';
import { Helmet } from 'react-helmet';
import { useCallback, useState } from 'react';
import { IBlogData } from 'src/types';
import { TyphoonModal } from 'src/components/Modal';
import moment from 'moment';
import BlogForm from 'src/components/Blog/BlogForm';
import BlogLayout from 'src/components/Blog/BlogLayout';
import { useSelector } from 'react-redux';
import { isAdmin } from 'src/utils/acl';
import { Storage } from 'aws-amplify';
import { getUserRole } from 'src/stores/user/Selectors';

interface params {
  id: string;
}

const getBlogDescription = async (uri: string) => {
  const response = await Axios.get(`${s3BlogUrl}/public/${uri}`);
  return response.data;
};

const fetchSingleBlog = async (id: string) => {
  const response = await Axios.get(`${API_ENDPOINT_LAMBDA}/blog/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  response.data.data.descriptionInString = await getBlogDescription(response.data.data.description);

  return response.data.data;
};

const SingleBlog = () => {
  const params = useParams<params>();
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const [removeBlog, setRemoveBlog] = useState<IBlogData>();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const queryClient = useQueryClient();

  const userRole = useSelector(getUserRole);

  const singleBlog = useQuery<IBlogData, Error>(['single-blog', params.id], () => fetchSingleBlog(params.id), {
    refetchOnWindowFocus: false,
  });

  const handleDeleteBlog = async (id: string) => {
    try {
      await Axios.delete(`${API_ENDPOINT_LAMBDA}/blog/${id}`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      if (singleBlog.data) {
        await Storage.remove(`${singleBlog?.data.description}.txt`);
      }
      queryClient.invalidateQueries(['blog-List']);
      history.push('/blog');
      TyphoonToaster('Blog deleted.', 'success');
    } catch (error) {}
  };

  const handleRemoveModalOnClose = useCallback(() => {
    setRemoveBlog(undefined);
    setShowRemoveModal(false);
  }, []);

  const handleBlogEditFormVisibility = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>{singleBlog.data?.title}</title>
      </Helmet>
      <BlogLayout>
        <div className="p-6">
          <div>
            {showForm ? (
              <BlogForm blogData={singleBlog.data} handleBlogEditFormVisibility={handleBlogEditFormVisibility} />
            ) : singleBlog.data ? (
              <div className="px-4 mb-6 space-y-2 border-2 rounded-md bg-typHeaderBlack border-typGrey10">
                <div className="flex justify-center w-full">
                  {singleBlog.data.imgUrl && (
                    <img src={singleBlog.data.imgUrl} alt="blog banner" className="object-center" />
                  )}
                </div>

                <div className="flex flex-wrap items-center justify-between">
                  <h1 className="py-4 mr-2 text-2xl font-semibold uppercase">{singleBlog.data.title}</h1>

                  <p className="my-1 text-xs text-gray-400 whitespace-nowrap">
                    {singleBlog.data.publishedAt &&
                      moment(new Date(singleBlog.data.publishedAt)).format('Do MMM, YYYY')}
                  </p>

                  {isAdmin(userRole) && (
                    <div>
                      <button
                        onClick={() => setShowForm(true)}
                        className="mr-4 text-sm transition focus:outline-none text-typGreen/60 hover:text-typGreen"
                      >
                        Update
                      </button>

                      <button
                        className="text-sm transition focus:outline-none text-typGreen/60 hover:text-typGreen"
                        onClick={() => {
                          setRemoveBlog(singleBlog.data);
                          setShowRemoveModal(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                <p className="text-sm text-gray-400">{singleBlog.data.shortDescription}</p>

                <div dangerouslySetInnerHTML={{ __html: singleBlog.data.descriptionInString || '' }} />

                <div className="flex flex-wrap justify-end pb-6">
                  <FacebookShareButton
                    url={'https://www.facebook.com/'}
                    className="mr-2 transition filter grayscale hover:filter-none hover:scale-110"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <br />
                  <TwitterShareButton
                    title={'test'}
                    url={'https://twitter.com/'}
                    className="mr-2 transition filter grayscale hover:filter-none hover:scale-110"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </div>
              </div>
            ) : (
              <div>
                {singleBlog.isLoading ? (
                  <div className="flex items-center justify-center p-6">
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <div className="p-6">No blog Found.</div>
                )}
              </div>
            )}
          </div>
        </div>
        {removeBlog && (
          <TyphoonModal open={showRemoveModal} onClose={handleRemoveModalOnClose}>
            <div className="p-6 w-96">
              <h1 className="text-2xl font-semibold text-center text-typGreen">Remove Blog</h1>
              <div className="relative mt-4 mb-2">
                <h1 className="pb-4 text-lg font-medium text-center text-white">
                  Are you sure you want to remove
                  <span className="ml-2 font-semibold text-typGreen">{removeBlog.title}</span>?
                </h1>
                <div className="flex justify-end pt-4">
                  <button
                    onClick={handleRemoveModalOnClose}
                    className="px-6 py-2 mr-4 text-red-500 border-2 border-red-500 rounded-md"
                  >
                    No
                  </button>

                  <button
                    onClick={async () => {
                      handleDeleteBlog(removeBlog.id!);
                      handleRemoveModalOnClose();
                    }}
                    className="px-6 py-2 mr-4 border-2 border-green-500 rounded-md text-typGreen"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </TyphoonModal>
        )}
      </BlogLayout>
    </>
  );
};

export default SingleBlog;
