import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import TyphoonIcon from '../icons';
import { s3FileUrl } from 'src/config';

interface IFileInputProps {
  name: string;
  accept: string | string[];
  multiple?: boolean;
  label?: string;
  preview?: boolean;
  errorMessage?: string;
}

const SingleFileUploadDropzone = ({
  name,
  accept,
  multiple = false,
  label,
  preview = true,
  errorMessage,
}: IFileInputProps) => {
  const {
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const files: File[] | string = watch(name);

  const onDrop = useCallback(
    (acceptedFiles, fileRejection, event) => {
      setValue(name, acceptedFiles, { shouldValidate: true, shouldDirty: true });
    },
    [setValue, name],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept,
    onDrop,
    multiple,
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  return (
    <React.Fragment>
      {label && (
        <label className="block text-typGreen text-sm font-bold mb-2 capitalize" htmlFor={name}>
          {label}
        </label>
      )}

      <div {...getRootProps()}>
        <input id={name} {...getInputProps()} />
        <div
          className={`border hover:border-typGreen ${
            isDragAccept ? 'border-dashed border-typGreen' : 'border-typGrey10'
          } ${errorMessage || errors[name] || isDragReject ? 'border-red-500' : ''} bg-typBodyBlack rounded-md`}
        >
          <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
            <TyphoonIcon name="upload" className="fill-current text-typGreen h-10" />
            <p className="text-typGreen text-sm">Drop or Click here to upload</p>
          </div>
        </div>
      </div>
      {files && typeof files === 'string' && (
        <div className="flex items-center justify-center">
          {preview ? (
            <div className="h-36 w-52 border overflow-hidden">
              <img className="w-full h-full object-cover" src={`${s3FileUrl}${files}`} alt={files} />
            </div>
          ) : (
            <div className="line-clamp-1 border border-typGreen text-typGreen rounded-md px-2 py-1">{files}</div>
          )}
        </div>
      )}
      {files && typeof files === 'object' && (
        <div className="flex items-center justify-center">
          {files.map((file, i) =>
            preview ? (
              <div key={i} className="h-36 w-52 border overflow-hidden">
                <img className="w-full h-full object-cover" src={URL.createObjectURL(file)} alt={file.name} />
              </div>
            ) : (
              <div key={i} className="line-clamp-1 border border-typGreen text-typGreen rounded-md px-2 py-1">
                {file.name}
              </div>
            ),
          )}
        </div>
      )}
      {(errorMessage || errors[name]) && (
        <p className="text-red-500 text-xs italic font-medium">{errorMessage || errors[name].message}</p>
      )}
    </React.Fragment>
  );
};

export default SingleFileUploadDropzone;
