import Axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import useDebounce from 'src/components/hooks/useDebounce';
import TyphoonIcon from 'src/components/icons';
import ContentPagination from 'src/components/shared/ContentPagination';
import { API_ENDPOINT_LAMBDA, s3FileUrl } from 'src/config';
import { IStandAloneMovie, IPaginationInfo, ISeason } from 'src/types';
import { getQueryParams } from 'src/utils';

interface IMovie {
  data: IStandAloneMovie[];
  paginationInfo: IPaginationInfo;
}
interface ISeasonType {
  data: ISeason[];
  paginationInfo: IPaginationInfo;
}
interface IQueryData {
  pageSize: number;
  pageNumber: number;
  categoryName: string;
}

const fetchMovies = async (queryData: IQueryData) => {
  const res = await Axios.get(
    `${API_ENDPOINT_LAMBDA}/movies?${getQueryParams(queryData)}&category=${
      queryData.categoryName
    }&isActive=true&isEpisodic=false`,

    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
};

const fetchSeasons = async (queryData: IQueryData) => {
  const res = await Axios.get(
    `${API_ENDPOINT_LAMBDA}/seasons?${getQueryParams(queryData)}&category=${queryData.categoryName}&isActive=true`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
};

interface IParams {
  categoryName: string;
}

const CategoryWiseContents = () => {
  const path = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(8);
  const params = useParams<IParams>();

  const res = path.pathname.split('/')[2];
  const debouncedQuery = useDebounce<IQueryData>({ pageSize, pageNumber, categoryName: params.categoryName });

  const movies = useQuery<IMovie, Error>(
    [`Movie Category`, debouncedQuery],
    async (context) => {
      return await fetchMovies((context.queryKey[1] as unknown) as IQueryData);
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: res === 'movies',
    },
  );

  const seasons = useQuery<ISeasonType, Error>(
    [`Season Category`, debouncedQuery],
    async (context) => {
      return await fetchSeasons((context.queryKey[1] as unknown) as IQueryData);
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: res === 'series',
    },
  );
  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const formatCategoryTitle = (title: string) => {
    const result = title.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <div className="p-8">
      <div className="space-y-1">
        <div className="flex items-center mb-4 -ml-2 space-x-5">
          {res === 'movies' && (
            <>
              <h1 className="z-10 text-2xl font-medium text-typGreen">{formatCategoryTitle(params.categoryName)}</h1>
              <div className="p-4 md:p-2">
                {movies.data && movies.data.paginationInfo && (
                  <ContentPagination
                    paginationInfo={movies.data.paginationInfo}
                    handlePageChange={handlePageChange}
                    pageNumber={pageNumber}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {res === 'movies' && (
          <div className="grid grid-cols-1 gap-5 mb-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {!movies.isFetching && (!movies.data || movies.data.data.length < 1) && <p>No featured movies.</p>}
            {movies.data &&
              movies.data.data.length > 0 &&
              movies.data.data.map((movie) => (
                <Link to={`/movies/${movie.id}`} key={movie.id}>
                  <div className="aspect-ratio--16x9">
                    <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                      <img
                        className="object-cover w-full h-full"
                        src={`${s3FileUrl}${movie.thumbnail}`}
                        alt={movie.title}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mt-3">
                    <h6 className="font-medium">{movie.title}</h6>
                    <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                  </div>
                </Link>
              ))}
          </div>
        )}

        <div className="flex items-center -ml-2 space-x-5">
          {res === 'series' && (
            <>
              <h1 className="z-10 text-2xl font-medium text-typGreen">
                {formatCategoryTitle(params.categoryName)} Series
              </h1>

              <div className="p-4 md:p-2">
                {seasons.data && seasons.data.paginationInfo && (
                  <ContentPagination
                    paginationInfo={seasons.data.paginationInfo}
                    handlePageChange={handlePageChange}
                    pageNumber={pageNumber}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {res === 'series' && (
          <div className="grid grid-cols-1 gap-5 my-4 mb-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {!seasons.isFetching && (!seasons.data || seasons.data.data.length < 1) && <p>No Series.</p>}
            {seasons.data &&
              seasons.data.data.length > 0 &&
              seasons.data.data.map((season) => (
                <Link to={`/seasons/${season.id}`} key={season.id}>
                  <div className="aspect-ratio--16x9">
                    <div className="overflow-hidden transition duration-500 ease-in-out transform border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10 hover:scale-105">
                      <img
                        className="object-cover w-full h-full"
                        src={`${s3FileUrl}${season.thumbnail}`}
                        alt={season.title}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mt-3">
                    <h6 className="font-medium">{season.title}</h6>
                    <TyphoonIcon name="star-fill" className="h-6 fill-current text-typGreen" />
                  </div>
                </Link>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryWiseContents;
