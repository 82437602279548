import React, { useMemo } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { MoviesBannerSlider } from '../../components/MoviesBannerSlider';
import { MoviesRowSlider } from '../../components/MoviesRowSlider';
import { API_ENDPOINT_LAMBDA, AppTitle } from '../../config';
import { categoryTypes, IFeaturedContents, ISeason, IStandAloneMovie, ICategory } from '../../types';
import { Helmet } from 'react-helmet';
import { useCategories } from 'src/components/hooks/useCategory';

const fetchFeaturedMovies = async (key: any) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies?isEpisodic=false&isFeatured=true&isActive=true`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data.data;
};

const fetchFeaturedSeasons = async (key: any) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/seasons?isFeatured=true&isActive=true`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data.data;
};

let filterCategory = (categoryData: ICategory[]) => {
  let newRelease = categoryData?.find((item: any) => item.name === 'newReleases');
  let trending = categoryData?.find((item: any) => item.name === 'trending');

  let filtered = categoryData?.filter((item: any) => item.name !== 'newReleases' && item.name !== 'trending');
  filtered?.unshift(trending!);
  filtered?.unshift(newRelease!);

  return filtered;
};

const Browse = () => {
  const movieCategories = useCategories();
  const movies = useQuery<IStandAloneMovie[], Error>('all-active-featured-movies', fetchFeaturedMovies);
  const seasons = useQuery<ISeason[], Error>('all-active-featured-seasons', fetchFeaturedSeasons);

  const categoryData = movieCategories ? movieCategories.data : [];

  const filteredList = filterCategory(categoryData!);

  const featuredContents = useMemo(() => {
    let contents: IFeaturedContents[] = [];
    if (movies.data && seasons.data) {
      movies.data.forEach((movie) => {
        contents.push({
          id: movie.id,
          type: 'movie',
          title: movie.title,
          description: movie.description,
          banner: movie.banner || '',
          isPaid: movie.isPaid,
          price: movie.price,
          isEpisodic: movie.isEpisodic,
          isRentable: movie.isRentable,
          rentalPrice: movie.rentalPrice,
          rentalDays: movie.rentalDays,
        });
      });

      seasons.data.forEach((season) => {
        contents.push({
          id: season.id,
          type: 'season',
          title: season.title,
          description: season.description,
          banner: season.banner,
          isPaid: season.isPaid,
          price: season.price,
          isRentable: season.isRentable,
          rentalPrice: season.rentalPrice,
          rentalDays: season.rentalDays,
        });
      });
    }
    return contents;
  }, [movies, seasons]);

  return (
    <div className="">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Browse</title>
      </Helmet>
      <TyphoonBackdrop open={movies.isLoading || seasons.isLoading} />
      <div className="z-0">
        {featuredContents.length > 0 ? (
          <MoviesBannerSlider contents={featuredContents} />
        ) : (
          !movies.isLoading && !seasons.isLoading && <h2 className="font-semibold text-red-600">No movies found.</h2>
        )}
      </div>
      <div className="px-6 mb-8 -mt-16 space-y-1 md:px-10 md:-mt-20 lg:-mt-40 xl:-mt-64 2xl:-mt-80 3xl:-mt-100">
        {filteredList?.map((category, i) => (
          <React.Fragment key={i}>
            <MoviesRowSlider title={category.label} categoryName={category.name as categoryTypes} />
            <MoviesRowSlider
              title={`${category.label} Series`}
              categoryName={category.name as categoryTypes}
              showSeason={true}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Browse;
