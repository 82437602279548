import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../App';
import SubscriptionPage from './SubscriptionPage';
import SubscriptionSuccess from './SubscriptionSuccess';

const FilmmakerSubscriptionPage = () => {
  let { path } = useRouteMatch();

  return (
    <div className="bg-typDarkBlue p-6 lg:px-10 xl:px-16 sm:py-12">
      <Switch>
        <Route exact path={path}>
          <SubscriptionPage />
        </Route>
        <PrivateRoute exact path={`${path}/success`}>
          <SubscriptionSuccess />
        </PrivateRoute>
        <Route path="*" render={() => <Redirect to={`${path}`} />}></Route>
      </Switch>
    </div>
  );
};

export default FilmmakerSubscriptionPage;
