import { isSubscriber, isAdmin, isFreeUser } from 'src/utils/acl';
import { IReducer } from '../IndexReducer';

export const getUserLoginStatus = (state: IReducer) => !!state.userReducer.user;

export const getUserId = (state: IReducer) => state.userReducer.user?.sub;

export const getUserRole = (state: IReducer) => (state.userReducer.user ? state.userReducer.user.role : []);

export const getUser = (state: IReducer) => state.userReducer.user;

export const getUserWatchPermission = (state: IReducer) =>
  state.userReducer.user
    ? isSubscriber(state.userReducer.user.role) ||
      isAdmin(state.userReducer.user.role) ||
      isFreeUser(state.userReducer.user.role)
    : false;
