import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDeleteMovie } from '../hooks/useDeleteMovie';
import { useDeleteSeason } from '../hooks/useDeleteSeason';
import { TyphoonModal } from '../Modal';

const DeleteContent = () => {
  const [showModal, setShowModal] = useState(false);
  const locationSplits = useLocation().pathname.split('/');

  const id = locationSplits.pop();

  const { mutate: deleteMovie } = useDeleteMovie();
  const { mutate: deleteSeason } = useDeleteSeason();

  const handleModalOnClose = () => {
    setShowModal(false);
  };

  const handleDeleteContent = () => {
    if (id) {
      if (locationSplits.includes('seasons')) {
        deleteSeason(id);
      } else {
        deleteMovie(id);
      }
      setShowModal(false);
    }
  };

  return (
    <div>
      <button
        onClick={() => setShowModal(true)}
        className="px-5 py-1 m-2 font-medium text-black rounded-md bg-typGreen"
      >
        Delete
      </button>
      <TyphoonModal open={showModal} onClose={handleModalOnClose}>
        <div className="p-6">
          <h1 className="pb-4 text-lg font-medium text-white">Are you sure you want to delete this content?</h1>
          <div className="flex flex-row-reverse">
            <button
              onClick={handleDeleteContent}
              className="px-5 py-1 m-2 font-medium text-white rounded-md bg-typGreen"
            >
              Yes
            </button>
          </div>
        </div>
      </TyphoonModal>
    </div>
  );
};
export default DeleteContent;
