import { useHistory } from 'react-router-dom';
import { TyphoonToaster } from 'src/components/Toastify';
import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINT_LAMBDA } from 'src/config';
import { getAccessToken } from 'src/utils';

const deleteSeason = async (id: string) => {
  await Axios.delete(`${API_ENDPOINT_LAMBDA}/seasons/${id}?deleteEpisodes=true`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const useDeleteSeason = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteSeason(id), {
    mutationKey: 'DELETE_SEASON',
    retry: false,
    onSuccess: () => {
      TyphoonToaster('Season deleted', 'success');
      queryClient.invalidateQueries('portal-season-episodes');
      history.push('/filmmaker/portal');
    },
    onError: () => {
      TyphoonToaster('Error deleting Season', 'danger');
    },
  });
};
