import { IUser } from './Reducer';
import { USER_TYPES } from './Types';

export const loginAction = (payload: IUser) => {
  return { type: USER_TYPES.LOGIN, payload };
};

export const logoutAction = () => {
  return { type: USER_TYPES.LOGOUT };
};

export const updateAuthCodeAction = (authCode: string) => {
  return { type: USER_TYPES.AUTH_CODE_UPDATED, payload: authCode };
};
