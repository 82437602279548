import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 6a9.77 9.77 0 018.82 5.5 9.82 9.82 0 01-17.64 0A9.77 9.77 0 0112 6m0-2a11.83 11.83 0 00-11 7.5 11.82 11.82 0 0022 0A11.83 11.83 0 0012 4z" />
    <path d="M12 9a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0112 9m0-2a4.5 4.5 0 104.5 4.5A4.5 4.5 0 0012 7z" />
  </svg>
);

export default SVG;
