import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path d="M12 0C8.74 0 8.33.01 7.05.07a8.85 8.85 0 00-2.91.56c-.79.3-1.46.72-2.13 1.38S.94 3.35.63 4.14c-.3.77-.5 1.64-.56 2.91C.01 8.33 0 8.74 0 12s.01 3.67.07 4.95c.06 1.27.26 2.15.56 2.91.3.79.72 1.46 1.38 2.13a5.87 5.87 0 002.13 1.38c.77.3 1.64.5 2.91.56 1.28.06 1.69.07 4.95.07s3.67-.01 4.95-.07a8.88 8.88 0 002.91-.56 5.9 5.9 0 002.13-1.38 5.86 5.86 0 001.38-2.13c.3-.77.5-1.64.56-2.91.06-1.28.07-1.69.07-4.95s-.01-3.67-.07-4.95a8.87 8.87 0 00-.56-2.91 5.89 5.89 0 00-1.38-2.13A5.85 5.85 0 0019.86.63c-.77-.3-1.64-.5-2.91-.56A83.63 83.63 0 0012 0zm0 2.16c3.2 0 3.58.02 4.85.07 1.17.06 1.8.25 2.23.42.56.21.96.47 1.38.9.42.41.68.81.9 1.37.16.43.36 1.06.4 2.23.07 1.27.08 1.65.08 4.85s-.02 3.58-.08 4.85a6.75 6.75 0 01-.42 2.23c-.22.56-.48.96-.9 1.38-.41.42-.82.68-1.38.9-.42.16-1.06.36-2.23.4-1.27.07-1.65.08-4.86.08-3.21 0-3.59-.02-4.86-.08a6.8 6.8 0 01-2.23-.42 3.72 3.72 0 01-1.38-.9 3.64 3.64 0 01-.9-1.38 6.81 6.81 0 01-.42-2.23c-.05-1.26-.06-1.65-.06-4.84 0-3.2.01-3.6.06-4.87A6.8 6.8 0 012.6 4.9c.2-.57.48-.96.9-1.38.42-.42.8-.69 1.38-.9A6.64 6.64 0 017.1 2.2c1.27-.04 1.65-.06 4.86-.06l.04.03zm0 3.68a6.16 6.16 0 100 12.32 6.16 6.16 0 000-12.32zM12 16a4 4 0 110-8 4 4 0 010 8zm7.85-10.4a1.44 1.44 0 11-2.89 0 1.44 1.44 0 012.89 0z" />
  </svg>
);

export default SVG;
