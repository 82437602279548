import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import Axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { TyphoonToaster } from '../../components/Toastify';
import { API_ENDPOINT_LAMBDA } from '../../config';
import { ICreateTicket, IEvent, IStripeBillingDetails } from '../../types';
import { Color, emailRegex, getAccessToken, parseINT } from '../../utils';
import { isAdmin } from '../../utils/acl';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUser } from 'src/stores/user/Selectors';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  phone: Yup.string().trim(),
  address: Yup.object({
    city: Yup.string().trim(),
    country: Yup.string().trim(),
    line1: Yup.string().trim(),
    line2: Yup.string().trim(),
    postal_code: Yup.string().trim(),
    state: Yup.string().trim(),
  }),
});

interface props {
  event: IEvent;
  onClose: () => void;
}

export const BuyEventTicketModalView = ({ event, onClose }: props) => {
  const [stripeError, setStripeError] = useState<StripeError>();
  const [submitError, setSubmitError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);

  const stripe = useStripe();
  const elements = useElements();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IStripeBillingDetails>({
    resolver: yupResolver(validationSchema),
  });

  const queryClient = useQueryClient();

  const onSubmit = handleSubmit(async (data) => {
    // Block native form submission.
    // e.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;

    try {
      //? Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: data.name,
          email: data.email,
          phone: data.phone ? data.phone : undefined,
          address: data.address
            ? {
                city: data.address.city ? data.address.city : undefined,
                country: data.address.country ? data.address.country : undefined,
                line1: data.address.line1 ? data.address.line1 : undefined,
                line2: data.address.line2 ? data.address.line2 : undefined,
                postal_code: data.address.postal_code ? data.address.postal_code : undefined,
                state: data.address.state ? data.address.state : undefined,
              }
            : undefined,
        },
      });

      if (error) {
        console.log('[paymentMethod-error]', error);
        setStripeError(error);
        setIsLoading(false);
        return;
      }

      // TODO: Move this portion to the backend
      // ? confirm payment
      if (paymentMethod) {
        //? get client-secret
        const {
          data: { clientSecret, transaction_id },
        } = await Axios.post(`${API_ENDPOINT_LAMBDA}/stripe/standalone/ticket`, {
          eventId: event.id,
          userId: user?.sub,
        });

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });
        if (error) {
          console.log('[confirmCardPayment-error]', error);
          setStripeError(error);
          setIsLoading(false);
          return;
        }

        if (paymentIntent) {
          const values: ICreateTicket = {
            eventId: event.id,
            userId: user!.sub,
            paymentId: paymentMethod.id,
            paymentIntentId: paymentIntent.id,
          };

          // TODO: remove this line
          values.eventId = typeof values.eventId === 'string' ? values.eventId : values.eventId;

          await Axios.post(`${API_ENDPOINT_LAMBDA}/live/tickets`, values, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          await Axios.put(
            `${API_ENDPOINT_LAMBDA}/transaction-log/confirm`,
            {
              transactionId: transaction_id,
            },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                'Content-Type': 'application/json',
              },
            },
          );

          TyphoonToaster('Ticket purchased.', 'success');
          setIsLoading(false);
          queryClient.invalidateQueries(['user-event-tickets']);
          onClose();
        }
      }
    } catch (error: any) {
      console.log('error', error);
      setSubmitError(error?.response?.data?.message || 'Something went wrong!');
    }
    setIsLoading(false);
  });

  return (
    <div className="sm:max-w-md md:max-w-xl p-8">
      <TyphoonBackdrop open={isLoading} />
      <div className="relative mt-4 mb-2">
        <div className="font-medium text-white text-lg pb-4">Buy Event Ticket</div>
        <p className="text-xs italic font-semibold text-typGreen mb-5">
          * We do not store or keep your personal credit card information.
        </p>
        <div className="flex flex-col space-y-3 overflow-auto pb-1" style={{ maxHeight: '70vh' }}>
          <div className="text-white mb-5">
            <h2 className="text-typGreen font-semibold text-lg mb-3">{event.title}</h2>
            <p>
              <span className="font-semibold mb-1">Description: </span>
              <span className="text-gray-300">{event.description}</span>
            </p>
            <p>
              <span className="font-semibold mb-1">Price: </span>
              <span className="text-gray-300">${(event.price / 100).toFixed(2)}</span>
            </p>
            <p>
              <span className="font-semibold mb-1">Schedule: </span>
              <span className="text-gray-300">
                {moment(typeof event.schedule === 'string' ? event.schedule : event.schedule).format(
                  'hh:mm a, Do MMM, YYYY',
                )}
              </span>
            </p>
          </div>
          {isAdmin(user!.role) ? (
            <p className="text-typGreen text-center font-semibold">All events are free for you.</p>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="text-white space-y-6 mb-8">
                <div className="space-y-1">
                  <h2 className="font-semibold">Name *</h2>
                  <input
                    {...register('name')}
                    type="text"
                    name="name"
                    autoComplete="name"
                    placeholder="Name"
                    className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                  />
                  {errors.name ? (
                    <p className="text-red-500 text-sm">{errors.name.message}</p>
                  ) : (
                    <p className="text-sm text-gray-400">Full name</p>
                  )}
                </div>
                <div className="flex justify-between space-x-4">
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">Email *</h2>
                    <input
                      {...register('email')}
                      type="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Email"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    {errors.email ? (
                      <p className="text-red-500 text-sm">{errors.email.message}</p>
                    ) : (
                      <p className="text-sm text-gray-400">Email address</p>
                    )}
                  </div>
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">Phone</h2>
                    <input
                      {...register('phone')}
                      type="tel"
                      name="phone"
                      autoComplete="tel"
                      placeholder="+x-xxx-xxx-xxxx"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    <p className="text-sm text-gray-400">Billing phone number (including extension)</p>
                  </div>
                </div>
                <div className="space-y-1">
                  <h2 className="font-semibold">Address Line 1</h2>
                  <input
                    {...register('address.line1')}
                    type="text"
                    name="address.line1"
                    autoComplete="address-line1"
                    placeholder="185 Berry St"
                    className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                  />
                  <p className="text-sm text-gray-400">Address line 1 (e.g., street, PO Box, or company name)</p>
                </div>
                <div className="space-y-1">
                  <h2 className="font-semibold">Address Line 2</h2>
                  <input
                    {...register('address.line2')}
                    type="text"
                    name="address.line2"
                    autoComplete="address-line2"
                    placeholder=". . ."
                    className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                  />
                  <p className="text-sm text-gray-400">Address line 2 (e.g., apartment, suite, unit, or building)</p>
                </div>
                <div className="flex justify-between space-x-4">
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">Country</h2>
                    <input
                      {...register('address.country')}
                      type="text"
                      name="address.country"
                      autoComplete="address-line1"
                      placeholder="US"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    <p className="text-sm text-gray-400">2-letter country code</p>
                  </div>
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">City</h2>
                    <input
                      {...register('address.city')}
                      type="text"
                      name="address.city"
                      autoComplete="address-level2"
                      placeholder="San Francisco"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    <p className="text-sm text-gray-400">City, district, suburb, town, or village</p>
                  </div>
                </div>
                <div className="flex justify-between space-x-4">
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">State</h2>
                    <input
                      {...register('address.state')}
                      type="text"
                      name="address.state"
                      autoComplete="address-level1"
                      placeholder="CA"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    <p className="text-sm text-gray-400">State, county, province, or region</p>
                  </div>
                  <div className="w-full space-y-1">
                    <h2 className="font-semibold">ZIP</h2>
                    <input
                      {...register('address.postal_code')}
                      type="text"
                      name="address.postal_code"
                      autoComplete="postal-code"
                      placeholder="94107"
                      className="w-full text-gray-800 font-medium placeholder-gray-500 rounded-md px-3 py-5"
                    />
                    <p className="text-sm text-gray-400">ZIP or postal code</p>
                  </div>
                </div>
                <div className="space-y-1">
                  <h2 className="font-semibold">Card Information *</h2>
                  <div className="rounded-md border border-gray-300 bg-white py-5 px-3">
                    <CardElement
                      options={{
                        iconStyle: 'solid',
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#2d3748',
                            // iconColor: "#fff",
                            '::placeholder': {
                              color: '#a0aec0',
                            },
                            ':-webkit-autofill': {
                              color: Color.Primary,
                            },
                          },
                          invalid: {
                            iconColor: '#f56565',
                            color: '#f56565',
                          },
                          complete: {
                            iconColor: Color.Primary,
                          },
                        },
                        hidePostalCode: true,
                      }}
                    />
                  </div>
                </div>
              </div>
              {(stripeError || submitError) && (
                <p className="text-red-500 text-xs italic font-medium mb-2">{submitError || stripeError?.message}</p>
              )}
              {user && (
                <button
                  className="w-full bg-typGreen text-xl font-semibold text-center py-2 rounded-md focus:outline-none"
                  type="submit"
                  disabled={!stripe || isLoading}
                >
                  Pay ${(parseINT(event.price) / 100).toFixed(2)}
                </button>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
