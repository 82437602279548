import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NoMatch from '../NoMatch';
import MoviePurchaseForm from './BuyForm';
import SingleMoviePage from './SingleMoviePage';

const MoviePage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:movieId`}>
        <SingleMoviePage />
      </Route>
      <Route exact path={`${path}/:movieId/buy`}>
        <MoviePurchaseForm />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default MoviePage;
