import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TyphoonIcon from './icons';

interface props {
  show?: boolean;
  onClose: () => void;
}

export const Search: React.FC<props> = ({ onClose }) => {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string>();
  const ref = useRef<HTMLInputElement>(null);
  ref.current?.focus();

  const handleSearch = () => {
    if (query.length > 0) {
      history.push(`/search?query=${query}`);
      onClose();
    } else {
      setError('Required');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      handleSearch();
    }
  };

  return (
    <div className="border-2 border-typGrey10 bg-typBodyBlack rounded-lg mb-2 lg:mb-0 p-2 pt-0 overflow-hidden">
      <div className="relative mt-2">
        <input
          ref={ref}
          type="text"
          placeholder="Search film"
          className="w-full text-typGrey80 placeholder-typGrey10 font-medium bg-transparent py-2 pl-2 pr-10 focus:outline-none"
          onKeyPress={handleKeyPress}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          className="cursor-pointer transition ease-in-out duration-200 p-2 rounded border border-transparent hover:border-typGrey80 absolute inset-y-0 right-0 flex items-center"
          onClick={onClose}
        >
          <TyphoonIcon name="navbar-open" className="fill-current text-typGrey80 h-6" />
        </div>
      </div>
      {error && <p className="text-red-500 text-xs italic font-medium mb-3">{error}</p>}
      {/* <div className="font-medium text-typGrey30">
        <p>
          <span className="text-typGreen">Tita</span>nic
        </p>
        <p>
          Clash of <span className="text-typGreen">Tita</span>ns
        </p>
      </div>
      <hr className="border-typGrey10 my-6" />
      <div className="flex flex-wrap font-medium text-typGrey80 -mx-4">
        <p className="mx-4">Documentaries</p>
        <p className="mx-4">Drama</p>
        <p className="mx-4">Reality</p>
        <p className="mx-4">Episodic</p>
        <p className="mx-4">Reality</p>
        <p className="mx-4">Free Content</p>
        <p className="mx-4">Student Films</p>
        <p className="mx-4">Music Videos</p>
        <p className="mx-4">Shorts</p>
      </div>
      <hr className="border-typGrey10 my-6" />
      <div className="">
        <h6 className="text-typGrey30">Recent Searches</h6>
        <div className="font-medium text-typGrey80">
          <p>Titanic</p>
          <p>Indiana Jones</p>
          <p>James bond</p>
        </div>
      </div> */}
    </div>
  );
};
