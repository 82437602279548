import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Storage } from 'aws-amplify';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { ICreateEvent } from '../../types';
import { getAccessToken, truncateReverse } from '../../utils';
import TyphoonIcon from '../../components/icons';
import { API_ENDPOINT_LAMBDA, config } from '../../config';
import Axios from 'axios';
import { TyphoonToaster } from '../../components/Toastify';
import moment from 'moment';
import TextField from 'src/components/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required').min(2, 'Should be at least 2 characters long'),
  schedule: Yup.string().required('Required'),
  price: Yup.number().min(50, 'At least 50 cents'),
});

const imageMimetype = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];

interface props {
  onClose: () => void;
}

function currentDateFormat() {
  let cd = moment().format().split(':');
  cd.pop();
  cd.pop();
  return cd.join(':');
}

export const CreateEventModalView = ({ onClose }: props) => {
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [bannerProgress, setBannerProgress] = useState(0);
  const [isBannerHoverOnDrop, setBannerHoverOnDrop] = useState(false);
  const [bannerImage, setBannerImage] = useState<File>();
  const [bannerImageError, setBannerImageError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ICreateEvent>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      description: '',
      schedule: currentDateFormat(),
      price: 0,
    },
  });

  const handleChangeBannerImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setBannerImage(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!imageMimetype.includes(image.type)) {
          setBannerImageError('Only image/png, image/jpeg and image/jpg are allowed.');
          return;
        } else {
          setBannerImage(image);
          setBannerImageError(undefined);
          return;
        }
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    // ? schedule date formatting for timezone+iso
    data.schedule = moment(new Date(data.schedule)).format();

    if (!bannerImage) {
      setBannerImageError('Required');
    } else if (moment(new Date(data.schedule)).isBefore(moment(new Date()))) {
      // ? check schedule_date is greater than today
      setError('schedule', {
        type: 'manual',
        message: 'Schedule date should be greater than current DATE & TIME.',
      });
    } else {
      try {
        setBannerProgress(0);
        const bannerImageType = bannerImage.name.split('.').pop();
        let bannerKey = '';

        setIsBannerLoading(true);
        const banner = (await Storage.put(`theater_events/banner-${Date.now()}.${bannerImageType}`, bannerImage, {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
          progressCallback(progress: any) {
            setBannerProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        })) as { key: string };
        bannerKey = banner.key;
        setIsBannerLoading(false);

        const values = { ...data, schedule: new Date(data.schedule).getTime(), banner: bannerKey };

        await Axios.post(`${API_ENDPOINT_LAMBDA}/live/events`, values, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        });
        TyphoonToaster('New Theater-Event created.', 'success');
        onClose();
      } catch (error: any) {
        console.log(error.response);
        setSubmitError('Banner image required');
      }
    }
  });

  return (
    <div className="sm:max-w-md md:max-w-xl p-8">
      <TyphoonBackdrop open={isSubmitting} />
      <div className="relative mt-4 mb-2">
        <div className="font-medium text-white text-lg pb-8">
          <p>Create Event</p>
        </div>
        <div className="flex flex-col overflow-auto pb-1" style={{ maxHeight: '70vh' }}>
          <form onSubmit={onSubmit}>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="title"
                id="title"
                fullWidth
                variant="outlined"
                placeholder="Title"
                error={errors.title ? true : false}
              />
              {errors.title && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.title.message}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="text"
                name="description"
                id="description"
                fullWidth
                variant="outlined"
                placeholder="Description"
                multiline
                minRows={4}
                maxRows={10}
                error={errors.description ? true : false}
              />
              {errors.description && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.description.message}</p>
              )}
            </div>
            <div className="mb-5">
              <div
                className={`border ${isBannerHoverOnDrop ? 'border-dashed border-typGreen' : 'border-typGrey10'} ${
                  bannerImageError ? 'border-red-500' : ''
                } bg-typBodyBlack rounded-md`}
                onDragEnter={() => {
                  setBannerHoverOnDrop(true);
                }}
                onDragLeave={() => {
                  setBannerHoverOnDrop(false);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setBannerHoverOnDrop(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setBannerHoverOnDrop(false);
                  setBannerImage(undefined);
                  const files = Array.from(e.dataTransfer.files);
                  if (files.length > 1) {
                    setBannerImageError('Drop only one file.');
                  } else if (files.length === 1) {
                    if (!imageMimetype.includes(files[0].type)) {
                      setBannerImageError('Only image/png, image/jpeg and image/jpg are allowed.');
                      return;
                    } else {
                      setBannerImage(files[0]);
                      setBannerImageError(undefined);
                      return;
                    }
                  }
                }}
              >
                <input
                  accept="image/*"
                  className="hidden"
                  id="browse-banner-file-button"
                  type="file"
                  onChange={handleChangeBannerImage}
                />
                <label htmlFor="browse-banner-file-button">
                  {bannerImage ? (
                    <div className="flex justify-start items-center p-6 cursor-pointer">
                      <p className="text-white">{truncateReverse(bannerImage.name, 30)}</p>
                    </div>
                  ) : (
                    <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                      <TyphoonIcon name="upload" className="fill-current text-typGreen h-10" />
                      <p className="text-typGreen text-sm">
                        Drop or Click here to upload <span className="font-semibold text-base">Banner Image</span>
                      </p>
                    </div>
                  )}
                </label>
              </div>
              {isBannerLoading && (
                <div className="flex items-center mt-1">
                  <div className="flex-grow">
                    <LinearProgress variant="determinate" value={bannerProgress} />
                  </div>
                  <p className="text-typGreen text-sm ml-2 font-semibold">{`${bannerProgress}%`}</p>
                </div>
              )}
              {bannerImageError && <p className="text-red-500 text-xs italic font-medium mt-1">{bannerImageError}</p>}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="number"
                name="price"
                id="price"
                fullWidth
                variant="outlined"
                placeholder="Price"
                error={errors.price ? true : false}
              />
              {errors.price ? (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.price.message}</p>
              ) : (
                <p className="text-gray-500 text-xs italic font-medium mt-1">Here, unit is 'cents'</p>
              )}
            </div>
            <div className="mb-5">
              <TextField
                control={control}
                defaultValue=""
                type="datetime-local"
                name="schedule"
                id="schedule"
                fullWidth
                variant="outlined"
                placeholder="Schedule"
              />
              {errors.schedule && (
                <p className="text-red-500 text-xs italic font-medium mt-1">{errors.schedule.message}</p>
              )}
            </div>

            {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
            <button
              type="submit"
              className="w-full border font-medium text-black border-typGreen bg-typGreen rounded-md py-3 mt-5"
            >
              CREATE
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
