import React, { useState } from 'react';
import { IStandAloneMovie, IStandAloneMovieStep2 } from '../../types';
import { TyphoonBackdrop } from '../Backdrop';
import { API_ENDPOINT_LAMBDA, config } from '../../config';
import { IconButton, MenuItem } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { useQueryClient } from 'react-query';
import { TyphoonToaster } from '../Toastify';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getAccessToken, LanguagesWithLocal } from 'src/utils';
import SingleFileUploadDropzone from './SingleFileUploadDropzone';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReactHookFormSelect from '../ReactHookFormSelect';

export function checkImageType(files: any) {
  if (typeof files === 'string') {
    return true;
  }

  let valid = false;

  const _files = Array.from(files) as File[];
  if (_files && _files.length > 0) {
    valid = _files.every((file) => {
      const pictureType = file.name.toLowerCase().split('.').pop();
      if (pictureType && ['jpeg', 'jpg', 'png'].includes(pictureType)) {
        return true;
      } else {
        return false;
      }
    });
  }
  return valid;
}
export function checkSrtType(files: any) {
  if (typeof files === 'string') {
    return true;
  }

  let valid = false;
  const _files = Array.from(files) as File[];
  if (_files && _files.length > 0) {
    valid = _files.every((file) => {
      const pictureType = file.name.toLowerCase().split('.').pop();
      if (pictureType && ['srt'].includes(pictureType)) {
        return true;
      } else {
        return false;
      }
    });
  }
  return valid;
}

const validationSchema = Yup.object().shape({
  banner: Yup.mixed().test('type', 'We only support JPEG, JPG or PNG', checkImageType),
  thumbnail: Yup.mixed().test('type', 'We only support JPEG, JPG or PNG', checkImageType),
  subtitle: Yup.array().of(
    Yup.object({
      label: Yup.string().oneOf(
        LanguagesWithLocal.map((languageObject) => languageObject.language),
        'Please select language type',
      ),
      file: Yup.mixed().test('type', 'We only support SRT', checkSrtType),
    }),
  ),
});

interface IData {
  banner: File[] | string;
  thumbnail: File[] | string;
  subtitle: {
    label: string;
    file: File[] | string;
  }[];
}

interface props {
  data: IStandAloneMovie;
  onClose: () => void;
}

export const EditMovieStep2: React.FC<props> = ({ data, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const queryClient = useQueryClient();

  const methods = useForm<IData>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      banner: data.banner,
      thumbnail: data.thumbnail,
      subtitle: data.subtitle || [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'subtitle',
  });
  console.log('methods.watch()', methods.watch());
  console.log('methods.formState.errors', methods.formState.errors);

  const handleSubmit = methods.handleSubmit(async (formData) => {
    console.log('🚀 ~ file: EditMovieStep2.tsx ~ line 108 ~ handleSubmit ~ formData', formData);
    setIsSubmitting(true);
    try {
      let values: IStandAloneMovieStep2 = {
        banner: '',
        thumbnail: '',
        subtitle: [],
      };

      if (typeof formData.banner === 'string') {
        values.banner = formData.banner;
      } else if (formData.banner.length > 0) {
        // ? Upload banner image
        const bannerImageType = formData.banner[0].name.split('.').pop();
        const banner = (await Storage.put(`${data.id}/banner-${Date.now()}.${bannerImageType}`, formData.banner[0], {
          bucket: config.S3.file.bucket,
          customPrefix: {
            public: '',
          },
        })) as { key: string };
        values.banner = banner.key;
      }

      if (typeof formData.thumbnail === 'string') {
        values.thumbnail = formData.thumbnail;
      } else if (formData.thumbnail.length > 0) {
        // ? Upload thumbnail image
        const thumbnailImageType = formData.thumbnail[0].name.split('.').pop();
        const thumbnail = (await Storage.put(
          `${data.id}/thumbnail-${Date.now()}.${thumbnailImageType}`,
          formData.thumbnail[0],
          {
            bucket: config.S3.file.bucket,
            customPrefix: {
              public: '',
            },
          },
        )) as { key: string };
        values.thumbnail = thumbnail.key;
      }

      if (formData.subtitle.length > 0) {
        for await (const subtitleObject of formData.subtitle) {
          let newSubtitleObject: {
            file: string;
            label: string;
          } = {
            label: subtitleObject.label,
            file: '',
          };

          if (typeof subtitleObject.file === 'string') {
            newSubtitleObject.file = subtitleObject.file;
          } else if (subtitleObject.file.length > 0) {
            // ? Upload subtitle image
            const subtitleImageType = subtitleObject.file[0].name.split('.').pop();
            const subtitleFile = (await Storage.put(
              `${data.id}/subtitle-${Date.now()}.${subtitleImageType}`,
              subtitleObject.file[0],
              {
                bucket: config.S3.file.bucket,
                customPrefix: {
                  public: '',
                },
              },
            )) as { key: string };
            newSubtitleObject.file = subtitleFile.key;
          }

          values.subtitle?.push(newSubtitleObject);
        }
      }

      // ? update step-2 values
      await Axios.put(`${API_ENDPOINT_LAMBDA}/movies/${data.id}`, values, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      });
      queryClient.invalidateQueries(['portal-stand-alone', data.id.toString()]);
      setIsSubmitting(false);
      TyphoonToaster('Information updated.', 'success');
      onClose();
    } catch (error) {
      console.log('error', error);
      setSubmitError('Something went wrong');
    }
    setIsSubmitting(false);
  });

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-semibold text-center mb-6">
        <span className="text-typGreen">Edit</span> Content STEP - 2
      </h1>
      <div className="bg-typHeaderBlack border-2 border-typGrey10 rounded-md p-6 sm:p-10">
        <TyphoonBackdrop open={isSubmitting} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div className="space-y-1">
                <SingleFileUploadDropzone name="banner" label="banner image" accept="image/jpeg, image/png" />
                <p className="text-gray-400 text-xs italic font-medium">
                  All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
                  credits. Please check the{' '}
                  <Link
                    to={'/filmmaker/news-and-weather'}
                    target="_blank"
                    className="inline-block underline font-semibold text-typGreen"
                  >
                    Typhoon User Guide
                  </Link>{' '}
                  .
                </p>
              </div>
              <div className="space-y-1">
                <SingleFileUploadDropzone name="thumbnail" label="thumbnail image" accept="image/jpeg, image/png" />
                <p className="text-gray-400 text-xs italic font-medium">
                  All artwork must be 16:9 aspect ratio and a resolution of at least 1920 x 1080. No laurels, logos or
                  credits. Please check the{' '}
                  <Link
                    to={'/filmmaker/news-and-weather'}
                    target="_blank"
                    className="inline-block underline font-semibold text-typGreen"
                  >
                    Typhoon User Guide
                  </Link>{' '}
                  .
                </p>
              </div>
              <div>
                <div className="flex justify-between items-center mb-2">
                  <p className="text-typGreen text-sm font-bold capitalize">Subtitles</p>{' '}
                  <IconButton
                    color="secondary"
                    aria-label="add another subtitle"
                    onClick={() => append({ label: '', file: [] })}
                  >
                    <AddCircleOutlineTwoToneIcon />
                  </IconButton>
                </div>
                <div className="space-y-6">
                  {fields.map((item, index) => {
                    let fileError: any = methods.formState.errors['subtitle']?.[index]?.file;
                    return (
                      <div key={index}>
                        <div className="flex justify-between items-center">
                          <p className="text-typGreen text-sm font-semibold capitalize"># {index + 1}</p>
                          <IconButton
                            style={{ color: '#ef4444' }}
                            aria-label="remove subtitle"
                            onClick={() => remove(index)}
                          >
                            <HighlightOffIcon fontSize="small" style={{ color: '#ef4444' }} />
                          </IconButton>
                        </div>
                        <div key={item.id} className="space-y-1">
                          <div className="space-y-1">
                            <p className="text-typGreen text-xs font-semibold capitalize">language type</p>
                            <ReactHookFormSelect
                              name={`subtitle.${index}.label`}
                              control={methods.control}
                              error={!!methods.formState.errors['subtitle']?.[index]?.label}
                              variant="outlined"
                            >
                              <MenuItem value="" disabled>
                                Select Language Type
                              </MenuItem>
                              {LanguagesWithLocal.map((languageObject, i) => (
                                <MenuItem key={i} value={languageObject.language}>
                                  {languageObject.language}
                                </MenuItem>
                              ))}
                            </ReactHookFormSelect>
                            {methods.formState.errors['subtitle']?.[index]?.label && (
                              <p className="text-red-500 text-xs italic font-medium">
                                {methods.formState.errors.subtitle[index].label?.message}
                              </p>
                            )}
                          </div>
                          <div className="space-y-1">
                            <p className="text-typGreen text-xs font-semibold capitalize">SRT File</p>
                            <SingleFileUploadDropzone
                              name={`subtitle.${index}.file`}
                              accept=".srt"
                              preview={false}
                              errorMessage={fileError?.message}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {submitError && <p className="text-red-500 text-xs italic font-medium mt-1">{submitError}</p>}
            <button
              type="submit"
              disabled={isSubmitting || !methods.formState.isDirty}
              className={`w-full uppercase border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3 mt-5 ${
                isSubmitting || !methods.formState.isDirty
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer opacity-100'
              }`}
            >
              Edit
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
