import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppTitle } from '../../../config';
import { FilmmakerContentList } from './ContentList';
import { FilmmakerSingleMovie } from './SingleMovie';
import { FilmmakerSingleSeason } from './SingleSeason';

export const FilmmakerContentsPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>My Contents</title>
        </Helmet>
        <FilmmakerContentList />
      </Route>
      <Route exact path={`${path}/:movieId`}>
        <FilmmakerSingleMovie />
      </Route>
      <Route exact path={`${path}/seasons/:seasonID`}>
        <FilmmakerSingleSeason />
      </Route>
      <Route path="*" render={() => <Redirect to={path} />}></Route>
    </Switch>
  );
};
