import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';

import { TyphoonModal } from 'src/components/Modal';
import TyphoonIcon from '../../../components/icons';
import { AppTitle, s3ResourceUrl } from '../../../config';
import { UploadEpisodicMovie } from './episodic';
import { UploadStandAloneMovie } from './stand-alone';

import RightsModalView from './RightsModalView';

type movieType = 'STAND-ALONE' | 'EPISODIC';

const FilmmakerUploadPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <UploadPage />
      </Route>
      <Route path={`${path}/stand-alone`}>
        <UploadStandAloneMovie />
      </Route>
      <Route path={`${path}/episodic`}>
        <UploadEpisodicMovie />
      </Route>
      <Route path="*" render={() => <Redirect to={path} />}></Route>
    </Switch>
  );
};

const UploadPage = () => {
  const [movieType, setMovieType] = useState<movieType>('STAND-ALONE');
  const [showModal, setShowModal] = useState(false);

  const handleModalOnClose = useCallback(() => setShowModal(false), []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div className="max-w-md mx-auto">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Select Content</title>
      </Helmet>
      <h1 className="mb-6 text-2xl text-center font-semibold">
        <span className="text-typGreen">Select</span> Content Type
      </h1>
      <div className="p-6 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
        <div className="flex flex-col mb-12 space-y-5">
          <button
            type="button"
            className={`${
              movieType === 'STAND-ALONE' ? 'ring-2 ring-inset ring-typGreen' : ''
            } text-typGreen w-full border border-typGrey10 bg-typBodyBlack font-semibold py-3 px-6 rounded-md`}
            onClick={() => setMovieType('STAND-ALONE')}
          >
            Stand-alone
          </button>
          <button
            type="button"
            className={`${
              movieType === 'EPISODIC' ? 'ring-2 ring-inset ring-typGreen' : ''
            } text-typGreen w-full border border-typGrey10 bg-typBodyBlack font-semibold py-3 px-6 rounded-md`}
            onClick={() => setMovieType('EPISODIC')}
          >
            Episodic
          </button>
        </div>
        <button
          type="button"
          onClick={handleOpenModal}
          className="w-full py-3 font-medium text-black uppercase border rounded-md border-typGreen bg-typGreen"
        >
          Confirm
        </button>
        <p className="p-4 text-xs text-center italic text-paleYellow">
          Having problems uploading? <br></br>Don't freak out. If you need help or have a large catalog of films-
          contact support and we'll gladly step in and assist!
        </p>
      </div>
      <div className="p-10">
        <NavLink
          to={{ pathname: `${s3ResourceUrl}/filmmaker_docs/Typhoon-User-Guide.pdf` }}
          target="_blank"
          rel="noopener"
          className="flex justify-center items-center space-x-6 text-base cursor-pointer border-2 border-typGreen rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-typGreen"
        >
          <TyphoonIcon name="download" className="fill-current text-typGreen h-10 flex-shrink-0" />
          <p className="text-typGreen font-semibold">Typhoon User Guide</p>
        </NavLink>
      </div>
      <TyphoonModal open={showModal} onClose={handleModalOnClose}>
        <RightsModalView movieType={movieType} onClose={handleModalOnClose} />
      </TyphoonModal>
    </div>
  );
};

export default FilmmakerUploadPage;
