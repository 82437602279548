import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { AppTitle, s3ResourceUrl } from '../../../config';

const PartnersAndAffiliates = () => {
  const handleOnClick = (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => {
    window.scrollTo({ behavior: 'smooth', top: event.currentTarget.offsetTop - 128 });
  };

  return (
    <div className="px-6 my-6 lg:px-10 xl:px-16 sm:my-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Partners and Affiliates</title>
      </Helmet>
      <div className="max-w-3xl mx-auto">
        <div className="space-y-6">
          <h1 className="text-3xl font-semibold cursor-pointer hover:underline" onClick={handleOnClick}>
            REV: Convert Audio & Video To Text
          </h1>
          <NavLink
            to={{ pathname: 'https://www.rev.com/' }}
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <img className="w-full" src={`${s3ResourceUrl}/images/rev.png`} alt="rev blog cover" />
          </NavLink>
          <div className="p-6 space-y-5 border-2 rounded-md bg-typHeaderBlack border-typGrey10 sm:p-10">
            <p>Rev for closed captioning and more.</p>
            <p>Rev is a great service with fast and accurate turnaround.</p>
            <p>
              <NavLink
                to={{ pathname: 'http://try.rev.com/3qnMCp' }}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-typGreen"
              >
                Click here to get $10 off your first order.
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersAndAffiliates;
