import React from 'react';
import { IconProps } from '.';

const SVG = ({ className, style }: IconProps) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
  >
    <path d="M374.24 285.82l14.1-91.96h-88.22V134.2c0-25.16 12.32-49.69 51.84-49.69h40.12V6.21S355.67 0 320.86 0C248.2 0 200.7 44.04 200.7 123.77v70.1h-80.78v91.95h80.78v222.31a320.57 320.57 0 0099.42 0v-222.3h74.12z" />
  </svg>
);

export default SVG;
