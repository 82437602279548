import React from 'react';

import TyphoonLogo from './TyphoonLogo';
import NavbarOpen from './NavbarOpen';
import NavbarClose from './NavbarClose';
import Search from './Search';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Instagram from './Instagram';
import YouTube from './YouTube';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import VideoCam from './VideoCam';
import Visibility from './Visibility';
import Calender from './Calender';
import Upload from './Upload';
import Play from './Play';
import Pause from './Pause';
import Mute from './Mute';
import Unmute from './Unmute';
import StarFill from './StarFill';
import StarOutline from './StarOutline';
import Add from './Add';
import Subtract from './Subtract';
import Delete from './Delete';
import Download from './Download';
import List from './List';
import Cross from './Cross';

export interface IconProps {
  name: IconNames;
  style?: Object;
  fill?: string;
  className?: string;
}

export type IconNames =
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'typhoon-logo'
  | 'navbar-close'
  | 'navbar-open'
  | 'search'
  | 'delete'
  | 'add'
  | 'subtract'
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'video-cam'
  | 'visibility'
  | 'calender'
  | 'upload'
  | 'youtube'
  | 'play'
  | 'pause'
  | 'star-fill'
  | 'star-outline'
  | 'mute'
  | 'download'
  | 'list'
  | 'cross'
  | 'unmute';

const TyphoonIcon = (props: IconProps) => {
  switch (props.name) {
    case 'play':
      return <Play {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'add':
      return <Add {...props} />;
    case 'subtract':
      return <Subtract {...props} />;
    case 'pause':
      return <Pause {...props} />;
    case 'mute':
      return <Mute {...props} />;
    case 'unmute':
      return <Unmute {...props} />;
    case 'typhoon-logo':
      return <TyphoonLogo {...props} />;
    case 'navbar-open':
      return <NavbarOpen {...props} />;
    case 'navbar-close':
      return <NavbarClose {...props} />;
    case 'upload':
      return <Upload {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'facebook':
      return <Facebook {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    case 'instagram':
      return <Instagram {...props} />;
    case 'youtube':
      return <YouTube {...props} />;
    case 'arrow-up':
      return <ArrowUp {...props} />;
    case 'arrow-down':
      return <ArrowDown {...props} />;
    case 'arrow-left':
      return <ArrowLeft {...props} />;
    case 'arrow-right':
      return <ArrowRight {...props} />;
    case 'video-cam':
      return <VideoCam {...props} />;
    case 'visibility':
      return <Visibility {...props} />;
    case 'calender':
      return <Calender {...props} />;
    case 'star-fill':
      return <StarFill {...props} />;
    case 'list':
      return <List {...props} />;
    case 'star-outline':
      return <StarOutline {...props} />;
    case 'cross':
      return <Cross {...props} />;
    default:
      return <span>no icon found with name: {props.name}</span>;
  }
};

export default TyphoonIcon;
