import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IBlogData } from 'src/types';
import Pagination from './Pagination';

interface IProps {
  // TODO Figure out type
  blogData: any;
  pageNumber: number;
  handlePageChange: (page: number) => void;
}

const BlogsRss = ({ blogData, pageNumber, handlePageChange }: IProps) => {
  return (
    <div className="pt-4">
      {blogData.data && blogData.data.blogs && blogData.data.blogs.length > 0 ? (
        blogData.data.blogs.map((v: IBlogData, i: number) => (
          <div
            key={i}
            className="flex flex-col items-center justify-center p-4 m-2 mb-4 overflow-hidden border-2 rounded-md lg:max-h-48 bg-typHeaderBlack border-typGrey10"
          >
            <div className="justify-between w-full md:flex">
              <div className="w-full mr-4">
                <div className="flex flex-wrap items-start w-full mb-2">
                  <Link to={`/blog/${v.id}`}>
                    <h1 className="h-8 mr-2 overflow-hidden text-2xl font-semibold uppercase hover:underline">
                      {v.title}
                    </h1>
                  </Link>
                  <p className="my-1 text-xs text-gray-400 whitespace-nowrap">
                    {v.publishedAt && moment(new Date(v.publishedAt)).format('Do MMM, YYYY')}
                  </p>
                </div>
                <p className="text-sm text-gray-400">{v.shortDescription}</p>
              </div>
              <div className="md:w-48">
                {v.imgUrl && (
                  <div>
                    <img src={v.imgUrl} alt="blog banner" className="object-center rounded md:object-contain " />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          {blogData.isLoading ? (
            <div className="flex items-center justify-center p-6">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <p className="text-sm text-gray-200">No blog found.</p>
          )}
        </div>
      )}
      <div className="p-4 md:p-2">
        {blogData.data && blogData.data.paginationInfo && (
          <Pagination
            paginationInfo={blogData.data.paginationInfo}
            handlePageChange={handlePageChange}
            pageNumber={pageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default BlogsRss;
