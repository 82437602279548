import React from 'react';
import Slider, { Settings } from 'react-slick';
import { IFeaturedContents } from '../types';
import { MovieBanner } from './MovieBanner';

interface props {
  contents: IFeaturedContents[];
}

export const MoviesBannerSlider: React.FC<props> = ({ contents }) => {
  const settings: Settings = {
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 1500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'slider-moviesBanner',
    dotsClass: 'slick-dots-moviesBanner',
    appendDots: (dots) => {
      return (
        <div>
          <ul> {dots} </ul>
        </div>
      );
    },
    customPaging: (i) => (
      <div className="h-1 w-4 rounded-lg bg-typGrey30 hover:bg-typGrey80 transition ease-in-out duration-200"></div>
    ),
  };

  return (
    <Slider {...settings}>
      {contents.map((content) => (
        <MovieBanner key={content.id} content={content} />
      ))}
    </Slider>
  );
};
