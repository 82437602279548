import React from 'react';
import Fade from '@material-ui/core/Fade/Fade';
import Modal from '@material-ui/core/Modal/Modal';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import TyphoonIcon from './icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.5rem',
    },
    hoverModal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.5rem',
      width: '50%',
      marginLeft: '25%',
    },
  }),
);

interface props {
  page?: string | null;
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
}

export const TyphoonModal: React.FC<props> = ({ page, open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Modal
      className={page === 'slider' ? classes.hoverModal : classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="relative border rounded-md bg-typBodyBlack border-typGrey10 focus:outline-none">
          <div
            className="absolute z-10 inline-block p-1 transition duration-300 ease-in-out border-2 rounded-full cursor-pointer bg-typHeaderBlack border-typGrey80 hover:border-white group"
            style={{
              right: '-18px',
              top: '-18px',
            }}
            onClick={onClose}
          >
            <TyphoonIcon name="navbar-open" className="h-6 fill-current text-typGrey80 group-hover:text-white" />
          </div>
          <div className="overflow-hidden rounded-md">{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};
