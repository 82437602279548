import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { TyphoonBackdrop } from '../../components/Backdrop';
import { TyphoonModal } from '../../components/Modal';
import { API_ENDPOINT_LAMBDA, AppTitle, config, s3FileUrl } from '../../config';
import { IEvent, IStreamEventRequest, ITicket } from '../../types';
import { BuyEventTicketModalView } from './BuyEventTicketModalView';
import { CreateEventModalView } from './CreateEventModalView';
import { EventMoreInfoModalView } from './EventMoreInfoModalView';
import { UpdateEventModalView } from './UpdateEventModalView';
import { UpcomingEventsModalView } from './UpcomingEventsModalView';
import querystring from 'querystring';
import { isAdmin } from '../../utils/acl';
import { Helmet } from 'react-helmet';
import '../../videojs/skins/shaka/videojs.css';
import videojs from 'video.js';
import '../../videojs/components/nuevo.js';
import { getAccessToken, nuevoDefaultOptions } from 'src/utils';
import { getUserId, getUserLoginStatus, getUserRole } from 'src/stores/user/Selectors';
import { Link } from 'react-router-dom';

// const fetchNextEvent = async (key: any) => {
//   const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/live/events/next`, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
//   return res.data;
// };

const fetchUpcomingEvents = async (key: any) => {
  const querystring = `isFinished=false`;
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/live/events?${querystring}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

const updateStreamEventMutation = async ({ id, ...data }: IStreamEventRequest) => {
  await Axios.put(`${API_ENDPOINT_LAMBDA}/live/events/${id}`, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

const fetchUserEventTickets = async (data: { userId: ITicket['userId'] }) => {
  const q = querystring.stringify(data);
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/live/tickets?${q}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.data;
};

const LiveStreamPage = () => {
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);
  const isLogin = useSelector(getUserLoginStatus);

  const videoNode = useRef<HTMLVideoElement>();
  const player = useRef<any>();

  const [showStreamRequestModal, setShowStreamRequestModal] = useState(false);
  const [isArchiveRequest, setIsArchiveRequest] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();

  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [showUpcomingEventsModal, setShowUpcomingEventsModal] = useState(false);
  const [showEventMoreInfoModal, setShowEventMoreInfoModal] = useState(false);
  const [showBuyEventTicketModal, setShowBuyEventTicketModal] = useState(false);
  const [showUpdateEventModal, setShowUpdateEventModal] = useState(false);
  const [isStreamPlaying, setIsStreamPlaying] = useState(false);

  const handleStreamRequestOnClose = React.useCallback(() => setShowStreamRequestModal(false), []);
  const handleCreateEventOnClose = React.useCallback(() => setShowCreateEventModal(false), []);
  const handleUpcomingEventsOnClose = React.useCallback(() => setShowUpcomingEventsModal(false), []);
  const handleEventMoreInfoOnClose = React.useCallback(() => setShowEventMoreInfoModal(false), []);
  const handleBuyEventTicketOnClose = React.useCallback(() => {
    setShowBuyEventTicketModal(false);
    setSelectedEvent(undefined);
  }, []);
  const handleUpdateEventOnClose = React.useCallback(() => {
    setShowUpdateEventModal(false);
    setSelectedEvent(undefined);
  }, []);

  const handleSetBuyEventTicket = (event: IEvent) => {
    setShowUpdateEventModal(false);
    setShowCreateEventModal(false);
    setShowEventMoreInfoModal(false);
    setShowUpcomingEventsModal(false);

    setSelectedEvent(event);

    setShowBuyEventTicketModal(true);
  };

  const handleSetUpdateEvent = (event: IEvent) => {
    setShowBuyEventTicketModal(false);
    setShowCreateEventModal(false);
    setShowEventMoreInfoModal(false);
    setShowUpcomingEventsModal(false);

    setSelectedEvent(event);

    setShowUpdateEventModal(true);
  };

  const handleSetArchiveEventModal = (event: IEvent) => {
    setShowBuyEventTicketModal(false);
    setShowCreateEventModal(false);
    setShowEventMoreInfoModal(false);
    setShowUpcomingEventsModal(false);
    setShowUpdateEventModal(false);

    setSelectedEvent(event);

    setIsArchiveRequest(true);
    setShowStreamRequestModal(true);
  };

  const upcomingEventsResponse = useQuery<IEvent[], Error>('theatre-upcoming-events', fetchUpcomingEvents, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const queryClient = useQueryClient();

  const streamEventMutation = useMutation(updateStreamEventMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('theatre-upcoming-events');
    },
  });

  const upcomingEvents = useMemo(() => {
    if (upcomingEventsResponse.data) {
      return upcomingEventsResponse.data.sort((e1, e2) => {
        const a = typeof e1.schedule === 'string' ? parseInt(e1.schedule, 10) : e1.schedule;
        const b = typeof e2.schedule === 'string' ? parseInt(e2.schedule, 10) : e2.schedule;
        return new Date(a) > new Date(b) ? 1 : -1;
      });
    }
    return [];
  }, [upcomingEventsResponse.data]);

  const nextEvent = useMemo(() => {
    if (upcomingEvents.length > 0) {
      return upcomingEvents[0];
    }
  }, [upcomingEvents]);

  const userEventTickets = useQuery<ITicket[], Error>(
    [
      'user-event-tickets',
      {
        userId: userId!,
      },
    ],
    () =>
      fetchUserEventTickets({
        userId: userId!,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !isAdmin(userRole),
    },
  );

  const isTicketPurchased = React.useCallback(
    (eventId: IEvent['id']) =>
      userEventTickets.data && userEventTickets.data.some((ticket) => ticket.eventId.toString() === eventId.toString())
        ? true
        : false,
    [userEventTickets.data],
  );

  useEffect(() => {
    if (isStreamPlaying && nextEvent && nextEvent.isActive && videoNode?.current) {
      //? video properties
      const videoJsOptions: videojs.PlayerOptions = {
        controls: true,
        preload: 'auto',
        sources: [config.theater.sourceObject],
        poster: `${s3FileUrl}${nextEvent.banner}`,
      };

      //? initialize Video.js
      player.current = videojs(videoNode.current, videoJsOptions);

      //? Initialize Nuevo plugin
      player.current.nuevo(nuevoDefaultOptions);
    }
    return () => {
      if (isStreamPlaying && nextEvent && nextEvent.isActive && videoNode?.current && player?.current) {
        player.current.dispose();
      }
    };
  }, [nextEvent, isStreamPlaying]);

  return (
    <>
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>Theater</title>
      </Helmet>
      <TyphoonBackdrop open={upcomingEventsResponse.isLoading || streamEventMutation.isLoading} />
      <div className="px-6 container mx-auto my-6 sm:my-6">
        <div className="flex flex-wrap justify-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-6 -mx-2">
          <div className="mx-2">
            <span className="theater_bg_gradient">T</span>
            <span className="theater_bg_gradient">y</span>
            <span className="theater_bg_gradient">p</span>
            <span className="theater_bg_gradient">h</span>
            <span className="theater_bg_gradient">o</span>
            <span className="theater_bg_gradient">o</span>
            <span className="theater_bg_gradient">n</span>
          </div>
          <div className="mx-2">
            <span className="theater_bg_gradient">T</span>
            <span className="theater_bg_gradient">h</span>
            <span className="theater_bg_gradient">e</span>
            <span className="theater_bg_gradient">a</span>
            <span className="theater_bg_gradient">t</span>
            <span className="theater_bg_gradient">e</span>
            <span className="theater_bg_gradient">r</span>
          </div>
        </div>
        <div className="aspect-ratio--16x9">
          <div className="aspect-ratio__inner-wrapper overflow-hidden">
            <div
              className="relative h-full w-full flex items-center p-5 sm:space-x-10 sm:p-10"
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(/images/theater-bg.png)`,
              }}
            >
              {nextEvent && (
                <>
                  <div className="hidden sm:block sm:w-1/3 overflow-hidden">
                    <img src={`${s3FileUrl}${nextEvent.banner}`} alt={nextEvent.title} />
                  </div>
                  {isStreamPlaying ? (
                    <div data-vjs-player>
                      <video
                        ref={(node: HTMLVideoElement) => (videoNode.current = node)}
                        className="video-js vjs-fluid"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="hidden sm:block bg-typBodyBlack px-5 py-3 rounded-md">
                        <h2 className="text-typGreen font-semibold text-lg mb-3">{nextEvent.title}</h2>
                        <p>
                          <span className="font-semibold mb-1">Description: </span>
                          <span className="text-gray-300">{nextEvent.description}</span>
                        </p>
                        {/* <p>
                          <span className="font-semibold mb-1">Price: </span>
                          <span className="text-gray-300">${(nextEvent.price / 100).toFixed(2)}</span>
                        </p> */}
                        <p>
                          <span className="font-semibold mb-1">Schedule: </span>
                          <span className="text-gray-300">
                            {moment(
                              typeof nextEvent.schedule === 'string'
                                ? parseInt(nextEvent.schedule, 10)
                                : nextEvent.schedule,
                            ).format('hh:mm a, Do MMM, YYYY')}
                          </span>
                        </p>
                      </div>
                      <h2 className="sm:hidden bg-typHeaderBlack px-1 rounded text-typGreen font-semibold">
                        Stream will start at{' '}
                        {moment(
                          typeof nextEvent.schedule === 'string'
                            ? parseInt(nextEvent.schedule, 10)
                            : nextEvent.schedule,
                        ).format('hh:mm a, Do MMM, YYYY')}
                      </h2>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-3 mt-5">
          {isLogin ? (
            <>
              <p className="text-sm text-white font-semibold tracking-widest">No streaming? Hit refresh!!</p>
              {upcomingEventsResponse.isFetched && !nextEvent && (
                <p className="text-red-500">No event currently available.</p>
              )}
            </>
          ) : (
            <>
              <p className="font-semibold text-lg">The Typhoon Theater is available to subscribers only.</p>
              <Link to="/filmmaker/subscription" className="text-typGreen font-semibold hover:underline">
                Click here to Subscribe
              </Link>
            </>
          )}
        </div>
        <div className="flex flex-col space-y-5 items-center my-10">
          <div className="-m-1">
            {isAdmin(userRole) && (
              <button
                type="button"
                onClick={() => setShowCreateEventModal(true)}
                className="border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
              >
                Create Event
              </button>
            )}
            {isLogin && nextEvent && nextEvent.isActive && !isStreamPlaying && (
              <button
                type="button"
                onClick={() => setIsStreamPlaying(true)}
                className="border-2 border-typGrey80 rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
              >
                Play Stream
              </button>
            )}
            {/* {nextEvent && (
              <button
                onClick={() => setShowEventMoreInfoModal(true)}
                className="border-2 border-typGrey80 rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
              >
                More Info
              </button>
            )} */}
            {/* {nextEvent && !isTicketPurchased(nextEvent.id) && userEventTickets.isFetched && (
              <button
                onClick={() => {
                  if (nextEvent) {
                    handleSetBuyEventTicket(nextEvent);
                  }
                }}
                className="border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
              >
                Buy Ticket
              </button>
            )} */}
          </div>
          {isAdmin(userRole) && nextEvent && (
            <div className="-m-1">
              {nextEvent.isActive ? (
                <button
                  onClick={() => {
                    setSelectedEvent(nextEvent);
                    setShowStreamRequestModal(true);
                  }}
                  className="border-2 border-orange-600 bg-orange-600 rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
                >
                  Pause Stream
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSelectedEvent(nextEvent);
                    setShowStreamRequestModal(true);
                  }}
                  className="border-2 border-typGreen bg-typGreen text-black rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
                >
                  Start Stream
                </button>
              )}
              <button
                onClick={() => {
                  setIsArchiveRequest(true);
                  setSelectedEvent(nextEvent);
                  setShowStreamRequestModal(true);
                }}
                className="border-2 border-red-600 bg-red-600 rounded-md font-semibold py-2 px-5 focus:outline-none m-1"
              >
                Archive
              </button>
            </div>
          )}
        </div>
        <div>
          <iframe
            title="Typhoon Theater"
            src="https://minnit.chat/TyphoonTheater?embed&&nickname="
            className="mx-auto"
            style={{ border: 'none', width: '90%', height: '500px' }}
          />
        </div>
      </div>
      {/* Start Start/Pause Modal */}
      {selectedEvent && (
        <TyphoonModal page={null} open={showStreamRequestModal} onClose={handleStreamRequestOnClose}>
          <div>
            <div className="p-8">
              <div className="relative mt-4 mb-2">
                <div className="font-medium text-white text-lg pb-8">
                  <p>
                    Are you sure you want to{' '}
                    <span className="text-typGreen uppercase">
                      {isArchiveRequest ? 'Archive' : selectedEvent.isActive ? 'pause' : 'start'}
                    </span>{' '}
                    <span className="text-typGreen capitalize underline">{selectedEvent.title}</span> event?
                  </p>
                </div>
                <div className="flex justify-end space-x-5">
                  <button
                    onClick={handleStreamRequestOnClose}
                    className="w-32 border font-medium text-typGreen black border-typGreen rounded-md focus:outline-none py-3"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      if (isArchiveRequest) {
                        streamEventMutation.mutateAsync({ id: selectedEvent.id, isActive: false, isFinished: true });
                        setIsArchiveRequest(false);
                      } else {
                        streamEventMutation.mutateAsync({ id: selectedEvent.id, isActive: !selectedEvent.isActive });
                      }
                      handleStreamRequestOnClose();
                    }}
                    className="w-32 border font-medium text-black border-typGreen bg-typGreen rounded-md focus:outline-none py-3"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TyphoonModal>
      )}
      {/* End Start/Pause Modal */}

      {/* Start CreateEvent Modal  */}
      <TyphoonModal page={null} open={showCreateEventModal} onClose={handleCreateEventOnClose}>
        <CreateEventModalView onClose={handleCreateEventOnClose} />
      </TyphoonModal>
      {/* End CreateEvent Modal  */}

      {/* Start UpcomingEvents Modal  */}
      <TyphoonModal page={null} open={showUpcomingEventsModal} onClose={handleUpcomingEventsOnClose}>
        <UpcomingEventsModalView
          events={upcomingEvents}
          handleSetBuyEventTicket={handleSetBuyEventTicket}
          isTicketPurchased={isTicketPurchased}
          isAdmin={isAdmin(userRole)}
          handleSetUpdateEvent={handleSetUpdateEvent}
          handleSetArchiveEventModal={handleSetArchiveEventModal}
        />
      </TyphoonModal>
      {/* End UpcomingEvents Modal  */}

      {/* Start EventMoreInfo Modal  */}
      {nextEvent && (
        <TyphoonModal page={null} open={showEventMoreInfoModal} onClose={handleEventMoreInfoOnClose}>
          <EventMoreInfoModalView
            isAdmin={isAdmin(userRole)}
            event={nextEvent}
            handleSetUpdateEvent={handleSetUpdateEvent}
          />
        </TyphoonModal>
      )}
      {/* End EventMoreInfo Modal  */}

      {/* Start BuyEventTicket Modal  */}
      {selectedEvent && (
        <TyphoonModal page={null} open={showBuyEventTicketModal} onClose={handleBuyEventTicketOnClose}>
          <BuyEventTicketModalView event={selectedEvent} onClose={handleBuyEventTicketOnClose} />
        </TyphoonModal>
      )}
      {/* End BuyEventTicket Modal  */}

      {/* Start UpdateEvent Modal  */}
      {selectedEvent && (
        <TyphoonModal page={null} open={showUpdateEventModal} onClose={handleUpdateEventOnClose}>
          <UpdateEventModalView event={selectedEvent} onClose={handleUpdateEventOnClose} />
        </TyphoonModal>
      )}
      {/* End UpdateEvent Modal  */}
    </>
  );
};

export default LiveStreamPage;
