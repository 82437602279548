import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { UploadMovieStep1 } from '../../../../components/uploadForms/UploadMovieStep1';
import { UploadMovieStep2 } from '../../../../components/uploadForms/UploadMovieStep2';
import { UploadMovieStep3 } from '../../../../components/uploadForms/UploadMovieStep3';
import { UploadMovieStep4 } from '../../../../components/uploadForms/UploadMovieStep4';
import { UploadSeasonStep1 } from '../../../../components/uploadForms/UploadSeasonStep1';
import { AppTitle } from '../../../../config';

export const UploadEpisodicMovie = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${path}/step1`} />}></Route>
      <Route exact path={`${path}/step1`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Season Step - 1</title>
        </Helmet>
        <UploadSeasonStep1 stepsLength={5} currentStep={1} title="Upload Season" />
      </Route>
      <Route exact path={`${path}/step2`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Episode Step - 2</title>
        </Helmet>
        <UploadMovieStep1 subpath="episodic" stepsLength={5} currentStep={2} title="Upload Episode" />
      </Route>
      <Route exact path={`${path}/step3`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Episode Step - 3</title>
        </Helmet>
        <UploadMovieStep2 subpath="episodic" stepsLength={5} currentStep={3} title="Upload Episode" />
      </Route>
      <Route exact path={`${path}/step4`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Episode Step - 4</title>
        </Helmet>
        <UploadMovieStep3 subpath="episodic" stepsLength={5} currentStep={4} title="Upload Episode" />
      </Route>
      <Route exact path={`${path}/step5`}>
        <Helmet titleTemplate={`%s | ${AppTitle}`}>
          <title>Episode Step - 5</title>
        </Helmet>
        <UploadMovieStep4 subpath="episodic" stepsLength={5} currentStep={5} title="Upload Episode" />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}/step1`} />}></Route>
    </Switch>
  );
};
