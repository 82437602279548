import React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle } from 'src/config';

const us = [
  {
    name: 'Mike Bedik',
    position: 'Founder / CEO',
    img: '/images/mike.jpeg',
  },
  {
    name: 'Gerry Gillock',
    position: 'Founder / CFO',
    img: '/images/gerry.jpeg',
  },
  {
    name: 'Danielle Matalonis',
    position: 'COO',
    img: '/images/danielle.jpeg',
  },
  {
    name: 'Michael McHolm',
    position: 'CTO',
    img: '/images/michael.jpg',
  },
  {
    name: 'Lance Lane',
    position: 'Director of Acquisitions',
    img: '/images/lance.jpeg',
  },
];

const steps = [
  'With Typhoon, filmmakers can upload directly! <span class="text-typGreen">*</span> You have detailed streaming metrics along with an uncomplicated automated payout system. When your film has earned money, a payout is as easy as a click of a button. You receive payouts directly to your bank or Paypal account, right from your personal portal.',
  'The Typhoon approval process is straightforward. We look for technically-sound content designed to educate and entertain, content-rich in imagination, ingenuity, and originality.',
  'When a film or series passes QC, it\'s made "live" within 1-3 days on all current Typhoon platforms and apps. These include Typhoon online, Roku, and Amazon Firestick, with more on the way.',
];

const AboutPage = () => {
  return (
    <div className="bg-typDarkBlue p-6 lg:py-10 xl:py-16">
      <Helmet titleTemplate={`%s | ${AppTitle}`}>
        <title>About Us</title>
      </Helmet>
      <div className="container mx-auto">
        <section className="space-y-12 pb-20">
          <div className="space-y-24">
            <div className="space-y-8">
              <div className="space-y-4">
                <h1 className="text-4xl">About Us</h1>
                <hr className="border-2 rounded" />
              </div>
              <p className="text-3xl lg:text-5xl font-medium text-center text-typGreen">Typhoon On Demand</p>
              <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
                "Built by Filmmakers for Filmmakers!"
              </p>
              <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
                Typhoon on Demand is a video-on-demand platform designed with the independent filmmaker in mind. We make
                it easy for filmmakers to upload and present their films and series on an uncluttered, beautiful, and
                fast platform with a Netflix-like look and feel, WITHOUT the hassle of negotiating complex distribution
                deals.
              </p>
            </div>
            <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
              "We stream the imagination, ingenuity and originally of independent filmmakers."
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 align-items-center gap-6">
            <div className="aspect-w-9 aspect-h-6 rounded-md overflow-hidden">
              <img src="/images/about_1.jpeg" alt="stream" className="object-cover" />
            </div>
            <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
              With a payout that will always be timely and fair, filmmakers can upload and stream their films and
              series, including trailers, directly to a GLOBAL audience. Founded by filmmakers, we at Typhoon know that
              using one's imagination, ingenuity, and originality to make a living is becoming more difficult each day.
            </p>
          </div>
        </section>
        <section className="py-24">
          <h2 className="text-3xl italic font-semibold text-center text-typGreen">
            Our job is to make it easier for you.
          </h2>
          <div className="mt-16">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-20 sm:gap-8">
              {us.map(({ name, position, img }) => (
                <div className="space-y-6" key={name}>
                  <div className="aspect-w-6 aspect-h-8 rounded overflow-hidden">
                    <img src={img} alt={name} className="object-cover" />
                  </div>
                  <div className="text-center space-y-1">
                    <h3 className="text-typGreen text-xl font-semibold">{name}</h3>
                    <hr className="rounded" />
                    <h4 className="text-typGreen text-xl">{position}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="py-24">
          <div className="lg:grid lg:grid-cols-5 lg:gap-8 xl:gap-24 align-items-center">
            <div className="space-y-20 lg:col-span-3">
              {steps.map((msg, i) => (
                <div key={i} className="flex flex-col items-center gap-6 md:gap-0 xl:gap-6 md:flex-row">
                  <span className="text-8xl text-typGreen md:px-10">{i + 1}</span>
                  <div>
                    <p
                      className="text-lg md:text-xl font-semibold text-center"
                      dangerouslySetInnerHTML={{ __html: msg }}
                    />
                  </div>
                </div>
              ))}
              <p className="text-typGreen md:px-10">
                * <span className="text-xs">Coming soon features.</span>
              </p>
            </div>
            <div className="hidden rounded-md overflow-hidden lg:block lg:col-span-2">
              <img src="/images/cowboy.jpeg" alt="stream" className="object-cover" />
            </div>
          </div>
        </section>
        <section className="py-24">
          <div className="space-y-8">
            <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
              "TyphoonOnDemand is a mission-driven company."
            </p>
            <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
              Dedicated to providing a more sustainable, ethical alternative to the predatory streaming giants around
              which the independent film industry increasingly revolves.
            </p>
          </div>
        </section>
        <section className="py-24">
          <div className="space-y-8">
            <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
              "Typhoon is built with a vision towards the future."
            </p>
            <div className="space-y-4">
              <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
                As the Typhoon community grows, we hope to one day provide funding opportunities, training, and
                mentorship for the next generation of independent filmmakers and seasoned professionals.
              </p>
              <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
                We are committed to service, integrity, and customer care here at Typhoon. We genuinely appreciate the
                chance to work with you. We look forward to building a positive community of passionate filmmakers.
              </p>
            </div>
          </div>
        </section>
        <section className="py-24">
          <div className="space-y-8">
            <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
              "There’s a Typhoon coming!"
            </p>
            <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
              We're always looking for ways to make Typhoon better. We welcome your suggestions and ideas! Join the
              conversation on our Facebook group. Or feel free to drop us a line here at{' '}
              <a href="mailto:support@typhoonondemand.com" className="text-paleYellow font-semibold">
                support@typhoonondemand.com
              </a>
              .
            </p>
          </div>
        </section>
        <section className="py-24 space-y-8 xl:space-y-12">
          <div className="rounded-md overflow-hidden">
            <img src="/images/wave.jpeg" alt="stream" className="object-cover" />
          </div>
          <p className="text-lg md:text-xl lg:text-2xl font-semibold text-center">
            Here at Typhoon, we are committed to service, integrity, and customer care. We’re only happy if you're
            happy. Our team truly appreciates the chance to work with you and we look forward to welcoming your project
            to the platform, with the promise to give it the respect it deserves.
          </p>
          <p className="text-2xl lg:text-3xl italic font-semibold text-center text-typGreen">
            "We stream the imagination!"
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
