import React from 'react';

const Newsletter = () => {
  return (
    <iframe
      src="https://landing.mailerlite.com/webforms/landing/q1k8t3"
      frameBorder="0"
      title="newsletter"
      scrolling="no"
      className="p-4 h-[340px] bg-typBodyBlack"
    ></iframe>
  );
};

export default Newsletter;
