import React, { useEffect, useRef, useState } from 'react';
import { TyphoonBackdrop } from '../../../components/Backdrop';
import { useHistory, useParams } from 'react-router-dom';
import { s3FileUrl, API_ENDPOINT_LAMBDA, AppTitle } from '../../../config';
import TyphoonIcon from '../../../components/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Axios from 'axios';
import { TyphoonToaster } from '../../../components/Toastify';
import { CircularProgress } from '@material-ui/core';
import { EditMovieStep1 } from '../../../components/uploadForms/EditMovieStep1';
import { EditMovieStep2 } from '../../../components/uploadForms/EditMovieStep2';
import { EditMovieStep3 } from '../../../components/uploadForms/EditMovieStep3';
import { EditMovieStep4 } from '../../../components/uploadForms/EditMovieStep4';
import { getAccessToken, isVideoPlayable } from '../../../utils';
import { IStandAloneMovie } from '../../../types';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../../utils/acl';
import { TyphoonModal } from '../../../components/Modal';
import { Helmet } from 'react-helmet';
import '../../../videojs/skins/shaka/videojs.css';
import videojs from 'video.js';
import '../../../videojs/components/nuevo.js';
import DeleteContent from 'src/components/Contents/DeleteContent';
import { getUserId, getUserRole } from 'src/stores/user/Selectors';

const fetchMovie = async (movieId: string) => {
  const res = await Axios.get(`${API_ENDPOINT_LAMBDA}/movies/${movieId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

const liveToTyphoon = async (movieId: string) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/movies/${movieId}/active`,
    {
      status: true,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

const unLiveToTyphoon = async (movieId: string) => {
  await Axios.put(
    `${API_ENDPOINT_LAMBDA}/movies/${movieId}/active`,
    {
      status: false,
    },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

interface paramProps {
  movieId: string;
}

export const FilmmakerSingleMovie = () => {
  const params = useParams<paramProps>();
  const history = useHistory();

  const videoNode = useRef<HTMLVideoElement>();
  const player = useRef<videojs.Player>();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  const queryClient = useQueryClient();

  const movie = useQuery<IStandAloneMovie, Error>(
    ['portal-stand-alone', params.movieId],
    () => fetchMovie(params.movieId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  const liveToTyphoonMutation = useMutation(liveToTyphoon, {
    onSuccess: () => {
      TyphoonToaster('This movie is live on Typhoon.', 'success');
      queryClient.invalidateQueries(['portal-stand-alone', params.movieId]);
    },
  });

  const unliveToTyphoonMutation = useMutation(unLiveToTyphoon, {
    onSuccess: () => {
      TyphoonToaster('This movie is un-live in Typhoon.', 'success');
      queryClient.invalidateQueries(['portal-stand-alone', params.movieId]);
    },
  });

  const handleLiveToTyphoon = async () => {
    if (isAdmin(userRole)) {
      await liveToTyphoonMutation.mutateAsync(params.movieId);
    }
  };

  const handleUnLiveToTyphoon = async () => {
    if (isAdmin(userRole)) {
      await unliveToTyphoonMutation.mutateAsync(params.movieId);
    }
  };

  const handleInfoModalOnClose = React.useCallback(() => {
    setShowInfoModal(false);
  }, []);

  const handleInfoModalOpen = () => {
    setShowInfoModal(true);
  };

  const handleOnClose = React.useCallback(() => {
    setIsEditOpen(false);
  }, []);

  useEffect(() => {
    if (isVideoPlaying && movie.data && movie.data.hlsUrl && videoNode?.current) {
      // const tracks = movie.data.subtitle
      //   ? movie.data.subtitle.map((v) => {
      //       return {
      //         kind: 'captions',
      //         file: `${s3FileUrl}${v.file}`,
      //         label: v.label,
      //       };
      //     })
      //   : [];

      //? tracks
      const subTitle: any[] | undefined = movie ? (movie.data ? movie.data.subtitle : []) : [];
      const tracks = subTitle
        ? subTitle.map((item: any) => {
            let check = item.file.match('.vtt') ? true : false;
            if (check) {
              let trackObj = {
                src: `${s3FileUrl}${item.file}`,
                label: item.label,
              };
              return trackObj;
            } else return null;
          })
        : [];
      let filterTracks: any[] | undefined = tracks.filter(Boolean);

      //? video properties
      const videoJsOptions: videojs.PlayerOptions = {
        controls: true,
        preload: 'auto',
        sources: [
          {
            src: movie.data.hlsUrl,
            type: 'application/vnd.apple.mpegurl',
          },
        ],
        poster: `${s3FileUrl}${movie.data.banner}`,
        tracks: filterTracks,
      };

      //? initialize Video.js
      player.current = videojs(videoNode.current, videoJsOptions);
    }
    return () => {
      if (isVideoPlaying && movie.data && movie.data.hlsUrl && videoNode?.current && player.current) {
        player.current.dispose();
      }
    };
  }, [isVideoPlaying, movie]);

  if (!isAdmin(userRole) && userId && movie.data && movie.data.uploader.toString() !== userId.toString()) {
    TyphoonToaster('Unauthorized access.', 'danger');
    history.push('/filmmaker/portal');
  }

  return (
    <div className="container mx-auto mb-12">
      <TyphoonBackdrop
        open={movie.isFetching || liveToTyphoonMutation.isLoading || unliveToTyphoonMutation.isLoading}
      />
      {!movie.isFetching && !movie.data && (
        <>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>No Movie Found</title>
          </Helmet>
          <p>No movie found.</p>
        </>
      )}
      {movie.data && (
        <div>
          <Helmet titleTemplate={`%s | ${AppTitle}`}>
            <title>{movie.data.title}</title>
          </Helmet>
          <div className="mb-12">
            <div className="aspect-ratio--16x9">
              <div className="overflow-hidden border aspect-ratio__inner-wrapper rounded-2xl border-typGrey10">
                {isVideoPlayable(movie.data) && movie.data.hlsUrl && isVideoPlaying ? (
                  <div data-vjs-player>
                    <video
                      ref={(node: HTMLVideoElement) => (videoNode.current = node)}
                      className="video-js vjs-fluid"
                    />
                  </div>
                ) : (
                  <img
                    className="object-cover w-full h-full"
                    src={`${s3FileUrl}${movie.data.banner}`}
                    alt={movie.data.title}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-5 space-y-3 sm:flex-row sm:space-y-0 sm:justify-between">
            <div className="space-y-2">
              <h6 className="font-medium">{movie.data.title}</h6>
              <div className="flex space-x-10 font-medium">
                <div className="flex space-x-2">
                  <TyphoonIcon name="video-cam" className="h-6 fill-current" />
                  <span>{movie.data.videoDuration}</span>
                </div>
                <div className="flex space-x-2">
                  <TyphoonIcon name="visibility" className="h-6 fill-current" />
                  <span>{movie.data.isActive ? 'Active' : 'Inactive'}</span>
                </div>
              </div>
              {!isVideoPlayable(movie.data) && (
                <div className="flex space-x-2">
                  <span className="font-semibold">Status :</span>
                  {movie.data.srcVideo ? (
                    <>
                      <span className="text-gray-500">processing</span>
                      <CircularProgress />
                    </>
                  ) : (
                    <span className="text-red-500">Upload processes have not completed yet! </span>
                  )}
                </div>
              )}
              <div className="flex flex-wrap -m-2">
                {isVideoPlayable(movie.data) && (
                  <button
                    onClick={() => setIsVideoPlaying((prev) => !prev)}
                    className="px-5 py-1 m-2 font-medium text-black rounded-md bg-typGreen"
                  >
                    {isVideoPlaying ? 'Stop' : 'Play'}
                  </button>
                )}
                <button
                  onClick={() => setIsEditOpen(true)}
                  className="px-5 py-1 m-2 font-medium text-black rounded-md bg-typGreen"
                >
                  Edit
                </button>
                {isAdmin(userRole) && isVideoPlayable(movie.data) && !movie.data.isActive && (
                  <button
                    disabled={liveToTyphoonMutation.isLoading}
                    className={`cursor-pointer py-1 px-5 m-2 bg-typGreen text-black rounded-md font-medium ${
                      liveToTyphoonMutation.isLoading ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                    }`}
                    onClick={handleLiveToTyphoon}
                  >
                    Make Live
                  </button>
                )}
                {isAdmin(userRole) && isVideoPlayable(movie.data) && movie.data.isActive && (
                  <button
                    disabled={unliveToTyphoonMutation.isLoading}
                    className={`cursor-pointer py-1 px-5 m-2 bg-typGreen text-black rounded-md font-medium ${
                      unliveToTyphoonMutation.isLoading ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                    }`}
                    onClick={handleUnLiveToTyphoon}
                  >
                    Make Un-Live
                  </button>
                )}
                {movie.data.trailer && (
                  <button
                    onClick={handleInfoModalOpen}
                    className="px-5 py-1 m-2 font-medium text-black rounded-md bg-typGreen"
                  >
                    Trailer Preview
                  </button>
                )}
                {isAdmin(userRole) && <DeleteContent />}
              </div>
            </div>
            {movie.data.filmmaker && (
              <div className="space-y-2">
                <h6 className="font-semibold text-typGreen">Filmmaker Information</h6>
                <div className="inline-block p-3 bg-gray-900">
                  {movie.data.filmmaker?.sub && (
                    <div className="space-x-2">
                      <span>ID :</span>
                      <span>{movie.data.filmmaker.sub}</span>
                    </div>
                  )}
                  <div className="space-x-2">
                    <span>Name :</span>
                    <span>
                      {movie.data.uploader === 'filmhub'
                        ? 'Filmhub'
                        : `${movie.data.filmmaker.given_name} ${movie.data.filmmaker.family_name}`}
                    </span>
                  </div>
                  {movie.data.filmmaker?.email && (
                    <div className="space-x-2">
                      <span>Email :</span>
                      <span>{movie.data.filmmaker.email}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {isEditOpen && (
            <div className="relative px-3 py-10 mt-6 border border-typGrey10">
              <div
                className="absolute top-0 right-0 p-2 text-gray-500 transition duration-500 ease-in-out border border-transparent rounded cursor-pointer hover:text-white hover:border-white"
                onClick={() => setIsEditOpen(false)}
              >
                <TyphoonIcon name="navbar-open" className="h-6 fill-current" />
              </div>
              <div>
                <EditMovieStep1
                  subpath={movie.data.isEpisodic ? 'episodic' : 'stand-alone'}
                  data={movie.data}
                  onClose={handleOnClose}
                />
              </div>
              <div className="mt-10">
                <EditMovieStep2 data={movie.data} onClose={handleOnClose} />
              </div>
              <div className="mt-10">
                <EditMovieStep3 data={movie.data} onClose={handleOnClose} />
              </div>
              {!movie.data.srcVideo && (
                <div className="mt-10">
                  <EditMovieStep4 data={movie.data} onClose={handleOnClose} />
                </div>
              )}
            </div>
          )}
          <TyphoonModal page={null} open={showInfoModal} onClose={handleInfoModalOnClose}>
            <div className="max-w-3xl bg-typHeaderBlack">
              <div className="relative">
                {movie.data.trailer ? (
                  <video
                    autoPlay={true}
                    muted={true}
                    poster={`${s3FileUrl}${movie.data.banner}`}
                    className="w-full"
                    controls
                  >
                    <source src={`${s3FileUrl}${movie.data.trailer}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={`${s3FileUrl}${movie.data.banner}`} alt={movie.data.title} />
                )}
              </div>
            </div>
          </TyphoonModal>
        </div>
      )}
    </div>
  );
};
